let CONSTANTS = {
  // POINTTYPE: {
  //     VERTEX: "V",
  //     CREATED: "C",
  //     DRAWN: "D",
  //     MAPPED: "M",
  //     ETS: "E"
  // },

  ALERTS: {
    SUCCESS: "S",
    WARNING: "W",
    ERROR: "E",
  },

  // LINETYPE: {
  //     BOUNDARY: "B",
  //     CREATED: "C",
  //     MAPPED: "M"
  // },

  // POLYGONTYPE: {
  //     INITIAL: "I",
  //     CREATED: "C",
  //     MAPPED: "M"
  // },

  LAYERTYPE: {
    VECTOR: "VECTOR",
    BASE_MAP: "BASE_MAP",
    TEMP: "TEMP",
    IMAGE_LAYER: "IMAGE_LAYER" /*SEGMENT: "vectorLineSegments", */,
    CROP_LAYER: "CROP_LAYER",
    GEOREF_LAYER: "GEOREF_LAYER",
    // CUSTOM: "customLayer"
  },

  // LAYERGETTYPE: {
  //     ID: "id",
  //     NAME: "name",
  //     PARENTID: "parentid",
  //     PARENTNAME: "parentname"
  // },

  // OPERATION: {
  //     POINT: "point",
  //     LINE: "line",
  //     ETSPOINT: "etspoint",
  //     POLYGON: "polygon",
  //     SEGMENT: "segment",
  //     REPLACE: "replace",
  //     EDITPOINT: "editpoint"
  // },

  // ACTION: {
  //     CREATE: "create",
  //     DELETE: "delete",
  //     REPLACE: "replace",
  //     MAPPING: "mapping"
  // },

  REFERENCE_MAP_TYPE: {
    OSM: "OSM",
    ARCGIS: "ARCGIS",
    BHUVAN: "BHUVAN",
    BING: "BING",
    BHUNAKSHA_IMAGE: "BHUNAKSHA_IMAGE",
    BHUNAKSHA_VECTOR: "BHUNAKSHA_VECTOR",
    TILE_LAYER: "TILE_LAYER",
    TILE_WMS: "TILE_WMS",
    IMAGE_LAYER: "IMAGE_LAYER",
    VECTOR_TILE: "VECTOR_TILE",
    VECTOR_GML: "VECTOR_GML",
    VECTOR_GEOJSON: "VECTOR_GEOJSON",
    VECTOR_DATA: "VECTOR_DATA",
    BHUNAKSHA_FGB: "BHUNAKSHA_FGB",
  },

  UNDO_ACTIONS: {
    A: "ADD",
    D: "DELETE",
    E: "EDIT",
  },

  DATA_OBJECTS: {
    POINT: "points",
    LINE: "lines",
    POLYGON: "polygons",
    LAYER: "layers",
  },

  INTERACTIONS: {
    POINTER: "POINTER",
    PLACE_GRID: "PLACE_GRID",
    PLACE_POINT: "PLACE_POINT",
    PLACE_AND_MODIFY_POINT: "PLACE_AND_MODIFY_POINT",
    DRAW_LINE: "DRAW_LINE",
    CUT_SEGMENT: "CUT_SEGMENT",
    EDIT_POINT: "EDIT_POINT",
    DELETE_POINT: "DELETE_POINT",
    DELETE_SEGMENT: "DELETE_SEGMENT",
    DELETE_LINE: "DELETE_LINE",
    DELETE_POLYGON: "DELETE_POLYGON",
    SPLIT_POLYGON: "SPLIT_POLYGON",
    EDIT_POINT_FREEHAND: "EDIT_POINT_FREEHAND",
    DRAW_POLYGON: "DRAW_POLYGON",
    INSERT_POINT_ON_LINE: "INSERT_POINT_ON_LINE",
    CUT_SEGMENT_UI: "CUT_SEGMENT_UI",
    SELECT_SEGMENT: "SELECT_SEGMENT",
    SELECT_CURRENT_LAYER_FEATURE: "SELECT_CURRENT_LAYER_FEATURE",
    PICK_COORDINATE: "PICK_COORDINATE",
    PICK_COORDINATE_MULTI: "PICK_COORDINATE_MULTI",
  },

  LAYER_NAMES: {
    VECTOR_POINTS: "VECTOR_POINTS",
    VECTOR_LINES: "VECTOR_LINES",
    VECTOR_POLYGONS: "VECTOR_POLYGONS",
    CUSTOM_LAYER: "CUSTOM_LAYER",
    TEMP_LAYER: "TEMP_LAYER",
    CROP_LAYER: "CROP_LAYER",
    GEOREF_LAYER: "GEOREF_LAYER",
  },

  PREDEFINED_LAYERS: {
    DEFAULT_POINT: "_DEFAULT_POINT_",
    DEFAULT_LINE: "_DEFAULT_LINE_",
    DEFAULT_POLYGON: "_DEFAULT_POLYGON_",
    SPLIT_LINE: "_SPLIT_LINE_",
    LINE_MEASUREMENT: "_LINE_MEASUREMENT_",
    AREA_MEASUREMENT: "_AREA_MEASUREMENT_",
  },

  ACTION_BUTTON_ACTIONS: {
    PICK_COORDINATE: "PICK_COORDINATE",
    PICK_COORDINATE_MULTI: "PICK_COORDINATE_MULTI",
    PICK_FEATURE: "PICK_FEATURE",
    DRAW_LINE: "DRAW_LINE",
    DRAW_POLYGON: "DRAW_POLYGON",
  },

  LOCAL_LAYER_TYPES: {
    IMAGE: "IMAGE",
  },

  DEFAULT_VISIBLE_TOOLS: {
    // SAVE: "SAVE",
    MEASUREMENT: "MEASUREMENT",
    GRID: "GRID",
    BACKGROUND_IMAGE: "BACKGROUND_IMAGE",
    UNDO_REDO: "UNDO_REDO",
    IMPORT_ETS: "IMPORT_ETS",
    PLACE_POINT: "PLACE_POINT",
    ARC_POINT: "ARC_POINT",
    OFFSET_POINT: "OFFSET_POINT",
    CUT_POINT: "CUT_POINT",
    ANGLE_POINT: "ANGLE_POINT",
    EDIT_POINT: "EDIT_POINT",
    DELETE_POINT: "DELETE_POINT",
    DRAW_LINE: "DRAW_LINE",
    SNAP_POINT: "SNAP_POINT",
    TRIM_LINE: "TRIM_LINE",
    DELETE_SEGMENT: "DELETE_SEGMENT",
    DELETE_LINE: "DELETE_LINE",
    DRAW_POLYGON: "DRAW_POLYGON",
    POLYGONIZE_LINES: "POLYGONIZE_LINES",
    DELETE_POLYGON: "DELETE_POLYGON",
    DRAW_SPLIT_LINE: "DRAW_SPLIT_LINE",
    SPLIT_POLYGON: "SPLIT_POLYGON",
    MERGE_POLYGONS: "MERGE_POLYGONS",
    VIEW_ATTRIBUTES: "VIEW_ATTRIBUTES",
    ATTRIBUTES_LIST: "ATTRIBUTES_LIST",
    SELECT_POLYGON: "SELECT_POLYGON",
    PRINT_TOOLS: "PRINT_TOOLS",
    EXPORT_POINTS: "EXPORT_POINTS",
    AREA_DIVISION: "AREA_DIVISION",
  },

  GEOMETRY_TYPE: {
    POINT: "Point",
    LINE_STRING: "LineString",
    LINEAR_RING: "LinearRing",
    POLYGON: "Polygon",
    MULTI_POINT: "MultiPoint",
    MULTI_LINE_STRING: "MultiLineString",
    MULTI_POLYGON: "MultiPolygon",
    GEOMETRY_COLLECTION: "GeometryCollection",
    CIRCLE: "Circle",
  },

  // returnLayerForGeom: function (geomType) {
  //     if (geomType.toLowerCase().indexOf("point") !== -1) {
  //         return this.LAYERTYPE.POINTS;
  //     } else if (geomType.toLowerCase().indexOf("line") !== -1) {
  //         return this.LAYERTYPE.LINES;
  //     } else if (geomType.toLowerCase().indexOf("polygon") !== -1) {
  //         return this.LAYERTYPE.POLYGON;
  //     }
  // },

  // CheckGeomIsOfLayer: function (geomType, layerType) {
  //     let lType = "point";
  //     if (layerType === this.LAYERTYPE.LINES) {
  //         lType = "line";
  //     } else if (layerType === this.LAYERTYPE.POLYGON) {
  //         lType = "polygon";
  //     }
  //     return geomType.toLowerCase().indexOf(lType) !== -1
  // }
};

export { CONSTANTS };
