import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Draw, Snap, Modify, Select, } from 'ol/interaction';
import { createBox } from 'ol/interaction/Draw';
import { Fill, Stroke, Style, Circle, Text, Icon } from "ol/style";
import { altKeyOnly, click, pointerMove } from 'ol/events/condition';
import { LineString, Point, Circle as CircleGeom, Polygon } from "ol/geom";
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import ol_interaction_PointInsert from "./PointInsertInteraction";
import ol_interaction_SegmentSelect from "./SegmentSelectInteraction";
import { CONSTANTS } from "./Constants";
import { arrayEquals } from "./utils";
import TouchCursorSelect from './BhuTouchCursorSelect'
import TouchCursorDraw from './BhuTouchCursorDraw'
import TouchCursorModify from './BhuTouchCursorModify'

import TouchCursor from './BhuTouchCurosr'
class InteractionManager {


    constructor(digitizer) {

        this.digitizer = digitizer;

        this.currentInteraction = CONSTANTS.INTERACTIONS.POINTER;

        this.interactionLayer = this.tempLayer = new VectorLayer({
            name: 'tempLayerInteractions',
            source: new VectorSource(),

        });

        this.snapPointInteraction = null;
        this.snapLineInteraction = null;
        // this.defaultCursor = this.digitizer.getMap().getTargetElement().style.cursor;

    }

    enableInteraction(type, options) {

        if (!options) {
            options = {};
        }
        if (type === this.currentInteraction) {
            return;
        }
        this.removeCurrentInteraction();

        switch (type) {

            case CONSTANTS.INTERACTIONS.POINTER:

                break;

            case CONSTANTS.INTERACTIONS.PLACE_GRID:
                this.addPlaceGridInteraction(options);
                break;

            case CONSTANTS.INTERACTIONS.PLACE_POINT:
            case CONSTANTS.INTERACTIONS.PICK_COORDINATE:
                this.addPlacePointInteraction(type, options);
                break;
            case CONSTANTS.INTERACTIONS.PICK_COORDINATE_MULTI:
                this.addPlacePointInteraction(type, options, true);
                break;

            // case CONSTANTS.INTERACTIONS.PLACE_AND_MODIFY_POINT:
            //     this.addPlaceAndModifyPointInteraction(type, options);
            //     break;

            case CONSTANTS.INTERACTIONS.DRAW_LINE:
                this.addDrawLineInteraction(options);
                break;

            case CONSTANTS.INTERACTIONS.CUT_SEGMENT:
                this.addCutSegmentInteraction(CONSTANTS.INTERACTIONS.CUT_SEGMENT, options);
                break;

            case CONSTANTS.INTERACTIONS.CUT_SEGMENT_UI:
                this.addCutSegmentInteraction(CONSTANTS.INTERACTIONS.CUT_SEGMENT_UI, options);
                break;

            case CONSTANTS.INTERACTIONS.DELETE_SEGMENT:
                this.addDeleteSegmentInteraction(options);
                break;
            
            case CONSTANTS.INTERACTIONS.SELECT_SEGMENT:
                this.addSelectSegmentInteraction(options);
                break;
    

            case CONSTANTS.INTERACTIONS.DELETE_POINT:
                options.layer_name = CONSTANTS.LAYER_NAMES.VECTOR_POINTS;
                this.addSelectFeatureInteraction(options, CONSTANTS.INTERACTIONS.DELETE_POINT,);
                break;

            case CONSTANTS.INTERACTIONS.DELETE_LINE:
                options.layer_name = CONSTANTS.LAYER_NAMES.VECTOR_LINES;
                this.addSelectFeatureInteraction(options, CONSTANTS.INTERACTIONS.DELETE_LINE, options);
                break;

            case CONSTANTS.INTERACTIONS.DELETE_POLYGON:
                options.layer_name = CONSTANTS.LAYER_NAMES.VECTOR_POLYGONS;
                this.addSelectFeatureInteraction(options, CONSTANTS.INTERACTIONS.DELETE_POLYGON, options);
                break;

            case CONSTANTS.INTERACTIONS.SPLIT_POLYGON:
                options.layer_name = CONSTANTS.LAYER_NAMES.VECTOR_POLYGONS;
                this.addSelectFeatureInteraction(options, CONSTANTS.INTERACTIONS.SPLIT_POLYGON, options);
                break;

            case CONSTANTS.INTERACTIONS.EDIT_POINT:
                options.layer_name = CONSTANTS.LAYER_NAMES.VECTOR_POINTS;
                this.addSelectFeatureInteraction(options, CONSTANTS.INTERACTIONS.EDIT_POINT, options);
                break;

            case CONSTANTS.INTERACTIONS.EDIT_POINT_FREEHAND:
                this.addEditPointFreehandInteraction(options);
                break;

            case CONSTANTS.INTERACTIONS.DRAW_POLYGON:
                this.addDrawPolygonInteraction(options);
                break;

            case CONSTANTS.INTERACTIONS.INSERT_POINT_ON_LINE:
                options.layer_name = CONSTANTS.LAYER_NAMES.VECTOR_LINES;
                this.addSelectFeatureInteraction(options, CONSTANTS.INTERACTIONS.INSERT_POINT_ON_LINE, options);
                break;
            case CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE:
                this.addSelectFeatureWithLayerCodeInteraction(options, type);
                break;


        }

        //this.digitizer.dispatchEvent(new Event( "START_" + type));
        this.digitizer.getMap().dispatchEvent({ type: "START_" + type });

    }


    removeCurrentInteraction() {

        if (this.interactionLayer) {
            this.interactionLayer.getSource().clear();

            this.digitizer.getMap().removeLayer(this.interactionLayer);
        }

        switch (this.currentInteraction) {
            case CONSTANTS.INTERACTIONS.POINTER:
                //document.getElementById('infoPanel').style.display = "none";
                break;

            case CONSTANTS.INTERACTIONS.PLACE_GRID:
                this.removePlaceGridInteraction();
                break;

            case CONSTANTS.INTERACTIONS.PLACE_POINT:
            case CONSTANTS.INTERACTIONS.PICK_COORDINATE:
            case CONSTANTS.INTERACTIONS.PICK_COORDINATE_MULTI:
                this.removePlacePointInteraction();
                break;
            // case CONSTANTS.INTERACTIONS.PLACE_AND_MODIFY_POINT:
            //     this.removePlaceAndMovePointInteraction();
            //     break;
            case CONSTANTS.INTERACTIONS.DRAW_LINE:
                this.removeDrawLineInteraction();
                document.getElementById('infoPanel').style.display = "none";
                break;

            case CONSTANTS.INTERACTIONS.CUT_SEGMENT:
            case CONSTANTS.INTERACTIONS.CUT_SEGMENT_UI:
                this.removeCutSegmentInteraction();
                break;

            case CONSTANTS.INTERACTIONS.DELETE_SEGMENT:
                this.removeDeleteSegmentInteraction();
                break;

            case CONSTANTS.INTERACTIONS.SELECT_SEGMENT:
                this.removeSelectSegmentInteraction();
                break;

            case CONSTANTS.INTERACTIONS.DELETE_POINT:
            case CONSTANTS.INTERACTIONS.DELETE_LINE:
            case CONSTANTS.INTERACTIONS.DELETE_POLYGON:
            case CONSTANTS.INTERACTIONS.SPLIT_POLYGON:
            case CONSTANTS.INTERACTIONS.EDIT_POINT:
            case CONSTANTS.INTERACTIONS.INSERT_POINT_ON_LINE:
            case CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE:

                this.removeSelectFeatureInteraction();
                break;

            case CONSTANTS.INTERACTIONS.EDIT_POINT_FREEHAND:
                this.removeEditPointFreehandInteraction();
                break;

            case CONSTANTS.INTERACTIONS.DRAW_POLYGON:
                this.removeDrawPolygonInteraction();
                document.getElementById('infoPanel').style.display = "none";
                break;

        }

        this.currentInteraction = CONSTANTS.INTERACTIONS.POINTER;
        this.setCursor("default");

        this.digitizer.getMap().dispatchEvent({ type: "END_" + this.currentInteraction });

    }

    getCurrentInteraction() {
        return this.currentInteraction;
    }

    addPlaceGridInteraction(options) {
        let self = this;
        if (this.interactionLayer) {
            this.interactionLayer.getSource().clear();
        }

       
        if (!this.gridDrawInteraction) {

            let features = new Collection([]);

            features.on("add", function (event) {
                var feature = event.element;
                let bline = feature.getGeometry();
                var coords = bline.getCoordinates();
    
                self.digitizer.createGrid(coords[0], coords[1]);
    
            }
            );
    
            if (this.digitizer.getTouchMode() === true) {
                this.gridDrawInteraction = new TouchCursorDraw(
                    {
                        className: 'draw',
                        coordinate: this.digitizer.getMap().getView().getCenter(),
                        type: 'Point',
                        features: features
                    });
            }
            else {
                this.gridDrawInteraction = new Draw({
                    features: features,
                    type: "Point",
                    maxPoints: 1,
                    condition: function (event) {
                        return true;
                    },
    
                  
                });
    
            }

            // this.gridDrawInteraction = new Draw({
            //     source: this.interactionLayer.getSource(),
            //     type: "Point",
            //     maxPoints: 1,

            //     condition: function (event) {
            //         return true;
            //     },

            //     geometryFunction: function (coordinates, geometry, projection) {
            //         // var pt = new Point([coordinates[0], coordinates[1]]);
            //         self.digitizer.createGrid(coordinates[0], coordinates[1]);
            //         // this.finishDrawing();
            //         // self.removeCurrentInteraction();
            //     }

            // });


        }

        if (!this.snapPointInteraction) {
            this.snapPointInteraction = new Snap({
                source: self.digitizer.vectorPoints.getSource()
            });

        }


        this.digitizer.getMap().addLayer(this.interactionLayer);

        this.digitizer.getMap().addInteraction(this.gridDrawInteraction);
        this.digitizer.getMap().addInteraction(this.snapPointInteraction);
        this.currentInteraction = CONSTANTS.INTERACTIONS.PLACE_GRID;
        this.setCursor("crosshair");
    }

    addPlacePointInteraction(interactionCode, options, multi = false) {
        let self = this;

        if (this.interactionLayer) {
            this.interactionLayer.getSource().clear();
        }
        if (!this.pointDrawInteraction) {

            let features = new Collection([]);

            features.on("add", function (event) {
                var feature = event.element;
                let bline = feature.getGeometry();
                var coords = bline.getCoordinates();

                let data = { "geometry": bline, interaction_code: interactionCode, coordinate: coords, options: options };
                if (multi === true) {
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_progress', data: data });
                }
                else {
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });
                }

            }
            );

            if (this.digitizer.getTouchMode() === true) {
                this.pointDrawInteraction = new TouchCursorDraw(
                    {
                        className: 'draw',
                        coordinate: this.digitizer.getMap().getView().getCenter(),
                        type: 'Point',
                        features: features
                    });
            }
            else {
                this.pointDrawInteraction = new Draw({
                    features: features,
                    type: "Point",

                    condition: function (event) {
                        return true;
                    },

                    // geometryFunction: function (coordinate, geometry, projection) {
                    //     // var pt = new Point([coordinates[0], coordinates[1]]);
                    //     this.finishDrawing();
                    //     let data = {  "geometry":  geometry , interaction_code: CONSTANTS.INTERACTIONS.PLACE_POINT, coordinate: coordinate };
                    //     self.digitizer.getMap().dispatchEvent({ type:'interaction_completed', data: data });


                    //     // this.finishDrawing();
                    //     // self.removeCurrentInteraction();
                    // }

                });

            }

        }

        if (!this.snapPointInteraction) {
            this.snapPointInteraction = new Snap({
                source: self.digitizer.vectorPoints.getSource()
            });

        }

        if (!this.snapLineInteraction) {
            this.snapLineInteraction = new Snap({
                source: self.digitizer.vectorLines.getSource()
            });

        }

        // if (!this.touch_cursor) {
        //     this.touch_cursor = new TouchCursorSelect();
        // }
        // this.digitizer.getMap().addInteraction(this.touch_cursor);

        // this.digitizer.getMap().addLayer(this.interactionLayer);

        this.digitizer.getMap().addInteraction(this.pointDrawInteraction);
        this.digitizer.getMap().addInteraction(this.snapLineInteraction);
        this.digitizer.getMap().addInteraction(this.snapPointInteraction);
        this.currentInteraction = interactionCode;
        // this.setCursor("crosshair");
    }


    addDrawLineInteraction(options) {
        let self = this;

        if (!this.drawLineInteraction) {

            if (!self.drawnLineFeatures) {
                self.drawnLineFeatures = new Collection([]);
                self.drawnLineFeatures.on("add", function (event) {
                    var feature = event.element;
                    let bline = feature.getGeometry();
                    var coords = bline.getCoordinates();
                    //checknodeExistance() is a function that is used to check weather the points are created or not.
                    //It accept the coordinates in the form of array.Mainly it is used when we draw a line on map(free draw)
                    // that line edge coordinates need tobe added on the ganerated data.the point will be created only if it is not present.
                    // var checkElmt = self.checkNodeExistance(coords);
                    //let wktLine = self._wktFormat.writeGeometry(bline);
                    //wktLine = self.snapLineToBoundary(wktLine);
                    // let lineData = { "geom": wktLine, "type": Gis.LINETYPE.CREATED };
                    // self.addPointtoRaw(tempCoord);

                    // self.digitizer.addLine("","",coords, CONSTANTS.LINETYPE.CREATED, []);
                    // self.digitizer.clearDrawLineTool();

                    let data = { "geometry": bline, interaction_code: CONSTANTS.INTERACTIONS.DRAW_LINE, coordinate: coords, options: options };
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });

                    //document.getElementById('infoPanel').style.display = "none";

                    // self.removeCurrentInteraction();

                });
            } else {
                self.drawnLineFeatures.clear();
            }
            let geometrylength = 1;
            if (this.digitizer.getTouchMode() === true) {
                this.drawLineInteraction = new TouchCursorDraw(
                    {
                        className: 'draw',
                        coordinate: this.digitizer.getMap().getView().getCenter(),
                        type: 'LineString',
                        features: self.drawnLineFeatures,

                    });
            }
            else {
                this.drawLineInteraction = new Draw({
                    features: self.drawnLineFeatures,
                    type: ('LineString'),
                    style: function (feature, resolution) {
                        let styles = [];
                        const style = new Style({
                            // fill: new Fill({
                            //     color: 'rgba(255, 255, 255, 0.2)',
                            // }),
                            // stroke: new Stroke({
                            //     color: 'rgba(0, 0, 0, 0.5)',
                            //     lineDash: [10, 10],
                            //     width: 2,
                            // }),
                            image: new Circle({
                                radius: 5,
                                stroke: new Stroke({
                                    color: 'rgba(0, 0, 0, 0.7)',
                                }),
                                fill: new Fill({
                                    color: 'rgba(255, 255, 255, 0.2)',
                                }),
                            }),
                        });
                        styles.push(style);
                        if (feature && feature.getGeometry().getType() === 'LineString') {
                            let styles1 = self.digitizer.styleBuilder.createLineStyle(feature, {}, resolution, "", {}, true);
                            styles.push(...styles1);
                        }
                        return styles;
                    },
                    condition: function (event) {

                        return true;
                    },
                    geometryFunction: function (coordinates, geometry, projection) {
                        // if (coordinates.length > geometrylength) {
                        //     geometrylength = geometrylength + 1;
                        //    // tempCoord.push(coordinates[coordinates.length - 2]);
                        // }
                        if (geometry) {
                            geometry.setCoordinates(coordinates);
                        } else {
                            geometry = new LineString(coordinates);


                        }
                        let data = { "geometry": geometry, interaction_code: CONSTANTS.INTERACTIONS.DRAW_LINE, coordinate: coordinates, options: options };
                        self.digitizer.getMap().dispatchEvent({ type: 'interaction_progress', data: data });
                        return geometry;
                    }
                });

            }


        }

        if (!this.snapPointInteraction) {
            this.snapPointInteraction = new Snap({
                source: self.digitizer.vectorPoints.getSource()
            });

        }

        if (!this.snapLineInteraction) {
            this.snapLineInteraction = new Snap({
                source: self.digitizer.vectorLines.getSource()
            });

        }


        // this.digitizer.getMap().addLayer(this.interactionLayer);

        this.digitizer.getMap().addInteraction(this.drawLineInteraction);
        this.digitizer.getMap().addInteraction(this.snapLineInteraction);
        this.digitizer.getMap().addInteraction(this.snapPointInteraction);

        this.currentInteraction = CONSTANTS.INTERACTIONS.DRAW_LINE;

        // this.setCursor("crosshair");
    }

    addDrawPolygonInteraction(options) {
        let self = this;

        if (!this.drawPolygonInteraction) {

            if (!self.drawnPolygonFeatures) {
                self.drawnPolygonFeatures = new Collection([]);
                self.drawnPolygonFeatures.on("add", function (event) {
                    var feature = event.element;
                    let poly = feature.getGeometry();
                    var coords = poly.getCoordinates();
                    if (!(arrayEquals(coords[0][0], coords[0][coords[0].length - 1]))) {
                        coords[0].push(coords[0][0]);
                    }
                    //checknodeExistance() is a function that is used to check weather the points are created or not.
                    //It accept the coordinates in the form of array.Mainly it is used when we draw a line on map(free draw)
                    // that line edge coordinates need tobe added on the ganerated data.the point will be created only if it is not present.
                    // var checkElmt = self.checkNodeExistance(coords);
                    //let wktLine = self._wktFormat.writeGeometry(bline);
                    //wktLine = self.snapLineToBoundary(wktLine);
                    // let lineData = { "geom": wktLine, "type": Gis.LINETYPE.CREATED };
                    // self.addPointtoRaw(tempCoord);

                    // self.digitizer.addLine("","",coords, CONSTANTS.LINETYPE.CREATED, []);
                    // self.digitizer.clearDrawLineTool();

                    let data = { "geometry": poly, interaction_code: CONSTANTS.INTERACTIONS.DRAW_POLYGON, coordinate: coords, options: options };
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });

                    //document.getElementById('infoPanel').style.display = "none";

                    // self.removeCurrentInteraction();

                });
            } else {
                self.drawnPolygonFeatures.clear();
            }
            let geometrylength = 1;

            if (this.digitizer.getTouchMode() === true) {
                this.drawPolygonInteraction = new TouchCursorDraw(
                    {
                        className: 'draw',
                        coordinate: this.digitizer.getMap().getView().getCenter(),
                        type: 'Polygon',
                        features: self.drawnPolygonFeatures,
                        // style: function (feature, resolution) {
                        //     let styles = [];
                        //     const style = new Style({

                        //         image: new Circle({
                        //             radius: 5,
                        //             stroke: new Stroke({
                        //                 color: 'rgba(0, 0, 0, 0.7)',
                        //             }),
                        //             fill: new Fill({
                        //                 color: 'rgba(255, 255, 255, 0.2)',
                        //             }),
                        //         }),
                        //     });
                        //     styles.push(style);
                        //     console.log(feature);
                        //     if (feature && feature.getGeometry()&& feature.getGeometry().getType() === 'Polygon') {
                        //         let styles1 = self.digitizer.styleBuilder.createPolygonAreaStyle(feature, {}, resolution, "", {}, true, true);
                        //         styles.push(...styles1);

                        //     }
                        //     return styles;
                        // },
                    });
            }
            else {
                this.drawPolygonInteraction = new Draw({
                    features: self.drawnPolygonFeatures,
                    type: ('Polygon'),
                    style: function (feature, resolution) {
                        let styles = [];
                        const style = new Style({

                            image: new Circle({
                                radius: 5,
                                stroke: new Stroke({
                                    color: 'rgba(0, 0, 0, 0.7)',
                                }),
                                fill: new Fill({
                                    color: 'rgba(255, 255, 255, 0.2)',
                                }),
                            }),
                        });
                        styles.push(style);
                        if (feature && feature.getGeometry().getType() === 'Polygon') {
                            let styles1 = self.digitizer.styleBuilder.createPolygonAreaStyle(feature, {}, resolution, "", {}, true, true);
                            styles.push(...styles1);

                        }
                        return styles;
                    },
                    condition: function (event) {

                        return true;
                    },
                    geometryFunction: function (coordinates, geometry, projection) {

                        if (geometry) {
                            geometry.setCoordinates(coordinates);
                        } else {
                            geometry = new Polygon(coordinates);
                        }
                        return geometry;
                    }
                });

            }


        }

        if (!this.snapPointInteraction) {
            this.snapPointInteraction = new Snap({
                source: self.digitizer.vectorPoints.getSource()
            });

        }

        if (!this.snapLineInteraction) {
            this.snapLineInteraction = new Snap({
                source: self.digitizer.vectorLines.getSource()
            });

        }


        // this.digitizer.getMap().addLayer(this.interactionLayer);

        this.digitizer.getMap().addInteraction(this.drawPolygonInteraction);
        this.digitizer.getMap().addInteraction(this.snapLineInteraction);
        this.digitizer.getMap().addInteraction(this.snapPointInteraction);
        this.currentInteraction = CONSTANTS.INTERACTIONS.DRAW_POLYGON;
        // this.setCursor("crosshair");
    }

    finishLineDrawing() {
        if (this.drawLineInteraction) {
            this.drawLineInteraction.finishDrawing();
            this.removeCurrentInteraction();
        }
    }
    removeLastPointFromLine() {
        if (this.drawLineInteraction) {
            this.drawLineInteraction.removeLastPoint();

        }
    }

    finishPolygonDrawing() {
        if (this.drawPolygonInteraction) {
            this.drawPolygonInteraction.finishDrawing();
            this.removeCurrentInteraction();
        }
    }
    removeLastPointFromPolygon() {
        if (this.drawPolygonInteraction) {
            this.drawPolygonInteraction.removeLastPoint();

        }
    }

    addCutSegmentInteraction(interactionCode, options) {
        let self = this;

        if (!this.splitSegmentInteraction) {
            this.splitSegmentInteraction = new ol_interaction_PointInsert({ sources: [this.digitizer.vectorLines.getSource(), this.digitizer.vectorPolygon.getSource()] });
            this.splitSegmentInteraction.on("afterclick", function (e) {
                console.log(e.data);
                if (e.data.is_segment) {

                    let data = { interaction_code: interactionCode, start_coordinate: e.data.coords[0], end_coordinate: e.data.coords[1], center_coordinate: e.data.coord, options: options };
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });


                }
                self.removeCurrentInteraction();

            });

        }
        this.digitizer.getMap().addInteraction(this.splitSegmentInteraction);
        if (this.digitizer.getTouchMode() === true) {
            if (!this.touchCursor) {
                this.touchCursor = new TouchCursor();
            }
            this.digitizer.getMap().addInteraction(this.touchCursor);
        }


        this.currentInteraction = interactionCode;
        // this.setCursor("crosshair");
    }

    addSelectFeatureInteraction(options, interactionCode) {
        let self = this;

        if (this.digitizer.getTouchMode() === true) {
            if (!this.selectFeatureHour) {

                this.selectFeatureHour = new TouchCursorSelect({


                    layerFilter: function (layer) {
                        if (layer.get("name") === options.layer_name) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }

                });

                this.selectFeatureHour.addButton({
                    className: 'ol-button-check',
                    click: function () {

                        var f = self.selectFeatureHour.getSelection();
                        console.log(f);
                        let data = { "id": f.get("id"), layer_name: options.layer_name, "geometry": f.getGeometry(), interaction_code: interactionCode, coordinates: f.getGeometry().getCoordinates(), options: options };
                        self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });


                    }
                });
                this.selectFeatureHour.addButton({
                    className: 'ol-button-x',
                    click: function () {
                        self.selectFeatureHour.resetSelection();
                        self.removeCurrentInteraction();

                    }
                });

            }
            this.selectFeatureHour.on('select', function (e) {
                if (e.selected.length > 0) {
                    console.log(e);
                    // let data = { "id": e.selected[0].get("id"), layer_name: options.layer_name, "geometry": e.selected[0].getGeometry(), interaction_code: interactionCode, coordinates: e.selected[0].getGeometry().getCoordinates(), options: options };
                    // self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });
                }
            });

            this.digitizer.getMap().addInteraction(this.selectFeatureHour);


        }
        else {
            if (!this.selectFeatureHour) {

                this.selectFeatureHour = new Select({

                    condition: pointerMove,
                    filter: function (feature, layer) {
                        if( (layer && layer.get("name") && layer.get("name") === options.layer_name) && feature.get("lock_edit") !==true)  {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }

                });


            }

            if (!this.selectFeatureClick) {

                this.selectFeatureClick = new Select({

                    condition: click,
                    filter: function (feature, layer) {
                        if ( (layer && layer.get("name") && layer.get("name") === options.layer_name) && feature.get("lock_edit") !==true)  {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }



                });

                this.selectFeatureClick.on('select', function (e) {

                    let data = { "id": e.selected[0].get("id"), layer_name: options.layer_name, "geometry": e.selected[0].getGeometry(), interaction_code: interactionCode, coordinates: e.selected[0].getGeometry().getCoordinates(), options: options };
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });
                });



            }


            if (!this.touch_cursor) {
                this.touch_cursor = new TouchCursorSelect();
            }

            // this.digitizer.getMap().addInteraction(this.touch_cursor);
            this.digitizer.getMap().addInteraction(this.selectFeatureClick);
            this.digitizer.getMap().addInteraction(this.selectFeatureHour);
        }

        this.currentInteraction = interactionCode;

    }

    addSelectFeatureWithLayerCodeInteraction(options, interactionCode) {
        let self = this;
        if (this.digitizer.getTouchMode() === true) {
            if (!this.selectFeatureHour) {

                this.selectFeatureHour = new TouchCursorSelect({


                    filter: function (feature) {
                        if (feature && feature.get("layer_code") === options.layer_code) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }

                });

                this.selectFeatureHour.addButton({
                    className: 'ol-button-check',
                    click: function () {

                        var f = self.selectFeatureHour.getSelection();
                        console.log(f);
                        let data = { "id": f.get("id"), layer_name: options.layer_name, "geometry": f.getGeometry(), interaction_code: interactionCode, coordinates: f.getGeometry().getCoordinates(), options: options };
                        self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });


                    }
                });
                this.selectFeatureHour.addButton({
                    className: 'ol-button-x',
                    click: function () {
                        self.selectFeatureHour.resetSelection();
                        self.removeCurrentInteraction();

                    }
                });

            }
            this.selectFeatureHour.on('select', function (e) {
                if (e.selected.length > 0) {
                    console.log(e);
                    // let data = { "id": e.selected[0].get("id"), layer_name: options.layer_name, "geometry": e.selected[0].getGeometry(), interaction_code: interactionCode, coordinates: e.selected[0].getGeometry().getCoordinates(), options: options };
                    // self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });
                }
            });

            this.digitizer.getMap().addInteraction(this.selectFeatureHour);


        }
        else {
            if (!this.selectFeatureHour) {

                this.selectFeatureHour = new Select({

                    condition: pointerMove,
                    filter: function (feature, layer) {
                        if (feature && feature.get("layer_code") === options.layer_code) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }

                });


            }

            if (!this.selectFeatureClick) {

                this.selectFeatureClick = new Select({

                    condition: click,
                    filter: function (feature, layer) {
                        if (feature && feature.get("layer_code") === options.layer_code) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    },

                    // multi: true

                });

                this.selectFeatureClick.on('select', function (e) {

                    let data = { "id": e.selected[0].get("id"), layer_code: options.layer_code, "geometry": e.selected[0].getGeometry(), interaction_code: interactionCode, coordinates: e.selected[0].getGeometry().getCoordinates(), options: options };
                    self.selectFeatureClick.getFeatures().clear();
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data });



                }

                );



            }


            // this.digitizer.getMap().addInteraction(this.touch_cursor);
            this.digitizer.getMap().addInteraction(this.selectFeatureClick);
            this.digitizer.getMap().addInteraction(this.selectFeatureHour);
        }
        this.currentInteraction = interactionCode;

    }

    addDeleteSegmentInteraction(options) {
        let self = this;

        if (!this.deleteSegmentInteraction) {
            this.deleteSegmentInteraction = new ol_interaction_SegmentSelect({ sources: [this.digitizer.vectorLines.getSource()] });
            this.deleteSegmentInteraction.on("afterclick", function (e) {


                if (e.data.is_segment) {

                    let data = { "data": e.data, interaction_code: CONSTANTS.INTERACTIONS.DELETE_SEGMENT };
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data, options: options });


                }
                self.removeCurrentInteraction();

            });

        }
        this.digitizer.getMap().addInteraction(this.deleteSegmentInteraction);
        if (this.digitizer.getTouchMode() === true) {
            if (!this.touchCursor) {
                this.touchCursor = new TouchCursor();
            }
            this.digitizer.getMap().addInteraction(this.touchCursor);
        }

        this.currentInteraction = CONSTANTS.INTERACTIONS.DELETE_SEGMENT;
        // this.setCursor("crosshair");
    }

    addSelectSegmentInteraction(options) {
        let self = this;

        if (!this.selectSegmentInteraction) {
            this.selectSegmentInteraction = new ol_interaction_SegmentSelect({ sources: [this.digitizer.vectorLines.getSource(), this.digitizer.vectorPolygon.getSource()] });
            this.selectSegmentInteraction.on("afterclick", function (e) {


                if (e.data.is_segment) {

                    let data = { "data": e.data, interaction_code: CONSTANTS.INTERACTIONS.SELECT_SEGMENT };
                    self.removeCurrentInteraction();
                    self.digitizer.getMap().dispatchEvent({ type: 'interaction_completed', data: data, options: options });


                }
               

            });

        }
        this.digitizer.getMap().addInteraction(this.selectSegmentInteraction);
        if (this.digitizer.getTouchMode() === true) {
            if (!this.touchCursor) {
                this.touchCursor = new TouchCursor();
            }
            this.digitizer.getMap().addInteraction(this.touchCursor);
        }

        this.currentInteraction = CONSTANTS.INTERACTIONS.SELECT_SEGMENT;
        // this.setCursor("crosshair");
    }

    addEditPointFreehandInteraction(options) {
        self = this;

        if (!this.editPointFreehandInteraction) {
            // this.interactionLayer.getSource().clear();

            var featureC = new Feature({
                geometry: new Point([options.x, options.y]),
                name: "",
                category: "A"

            });
            //this.interactionLayer.getSource().addFeature(featureC);
            if (this.digitizer.getTouchMode() === true) {
                self.editPointFreehandInteraction = new TouchCursorModify({
                    source: new VectorSource({
                        features: [featureC]
                    }),
                });
            }
            else {
                self.editPointFreehandInteraction = new Modify({
                    source: new VectorSource({
                        features: [featureC]
                    }),
                });
            }
            featureC.on("change", function (evt) {
                let ft = evt.target;

                if (options.onModify) {
                    options.onModify(ft.getGeometry().getCoordinates()[0], ft.getGeometry().getCoordinates()[1]);
                }
            });


        }
        // this.digitizer.getMap().addLayer(this.interactionLayer);
        this.digitizer.getMap().addInteraction(this.editPointFreehandInteraction);
        this.currentInteraction = CONSTANTS.INTERACTIONS.EDIT_POINT_FREEHAND;

    }

    removeEditPointFreehandInteraction() {
        // this.digitizer.getMap().removeLayer(this.interactionLayer);
        //this.editPointFreehandInteraction.getSource().clear();
        this.digitizer.getMap().removeInteraction(this.editPointFreehandInteraction);
        this.editPointFreehandInteraction = null;
        // this.interactionLayer.getSource().clear();
    }

    removeDeleteSegmentInteraction() {
        if (this.deleteSegmentInteraction) {

            this.digitizer.getMap().removeInteraction(this.deleteSegmentInteraction);
        }
        this.deleteSegmentInteraction = null;
        if (this.digitizer.getTouchMode() === true) {
            if (this.touchCursor) {
                this.digitizer.getMap().removeInteraction(this.touchCursor);
            }
            this.touchCursor = null;
        }
    }
    removeSelectSegmentInteraction() {
        if (this.selectSegmentInteraction) {

            this.digitizer.getMap().removeInteraction(this.selectSegmentInteraction);
        }
        this.selectSegmentInteraction = null;
        if (this.digitizer.getTouchMode() === true) {
            if (this.touchCursor) {
                this.digitizer.getMap().removeInteraction(this.touchCursor);
            }
            this.touchCursor = null;
        }
    }

    removeSelectFeatureInteraction() {
        this.digitizer.getMap().removeInteraction(this.selectFeatureClick);
        this.digitizer.getMap().removeInteraction(this.selectFeatureHour);
        if (this.digitizer.getTouchMode() === true) {
            if (this.selectFeatureHour && this.selectFeatureHour.resetSelection) {
                this.selectFeatureHour.resetSelection();
            }
        }
        this.selectFeatureClick = null;
        this.selectFeatureHour = null;

    }

    removeCutSegmentInteraction() {
        if (this.splitSegmentInteraction) {

            this.digitizer.getMap().removeInteraction(this.splitSegmentInteraction);
        }
        this.splitSegmentInteraction = null;

        if (this.digitizer.getTouchMode() === true) {
            if (this.touchCursor) {
                this.digitizer.getMap().removeInteraction(this.touchCursor);
            }
            this.touchCursor = null;
        }

    }


    removeDrawLineInteraction() {
        if (this.drawLineInteraction) {
            this.drawLineInteraction.abortDrawing();
            this.digitizer.getMap().removeInteraction(this.drawLineInteraction);
        }
        if (this.drawnLineFeatures) {
            this.drawnLineFeatures.clear();
        }
        if (this.snapPointInteraction) {
            this.digitizer.getMap().removeInteraction(this.snapPointInteraction);
        }
        if (this.snapLineInteraction) {
            this.digitizer.getMap().removeInteraction(this.snapLineInteraction);
        }
        this.drawLineInteraction = null;
        this.snapPointInteraction = null;
        this.snapLineInteraction = null;
        this.drawnLineFeatures = null;
    }

    removeDrawPolygonInteraction() {
        if (this.drawPolygonInteraction) {
            this.drawPolygonInteraction.abortDrawing();
            this.digitizer.getMap().removeInteraction(this.drawPolygonInteraction);
        }
        if (this.drawnPolygonFeatures) {
            this.drawnPolygonFeatures.clear();
        }
        if (this.snapPointInteraction) {
            this.digitizer.getMap().removeInteraction(this.snapPointInteraction);
        }
        if (this.snapLineInteraction) {
            this.digitizer.getMap().removeInteraction(this.snapLineInteraction);
        }
        this.drawPolygonInteraction = null;
        this.snapPointInteraction = null;
        this.snapLineInteraction = null;
        this.drawnPolygonFeatures = null;
    }

    removePlaceGridInteraction() {
        this.digitizer.getMap().removeLayer(this.interactionLayer);
        this.digitizer.getMap().removeInteraction(this.gridDrawInteraction);
        this.digitizer.getMap().removeInteraction(this.snapPointInteraction);
        this.gridDrawInteraction = null;
        this.snapPointInteraction = null;
        // this.interactionLayer=null;


    }


    removePlacePointInteraction() {
        this.digitizer.getMap().removeLayer(this.interactionLayer);
        this.digitizer.getMap().removeInteraction(this.pointDrawInteraction);
        this.digitizer.getMap().removeInteraction(this.snapPointInteraction);
        this.digitizer.getMap().removeInteraction(this.snapLineInteraction);
        this.gridDrawInteraction = null;
        this.snapPointInteraction = null;
        this.snapLineInteraction = null;
        // this.interactionLayer = null;
        this.pointDrawInteraction = null;

    }

    setCursor(cursor) {
        this.digitizer.getMap().getTargetElement().style.cursor = cursor;
    }


}

export { InteractionManager };