import { CONSTANTS } from "./Constants";


class UndoRedoManager {

    /*
     * 
     * Structure of Undo data 
     * [
     *  {
     *      ACTION: //'A','D','E'
     *      OBJECT  //'points', 'lines', 'polygons', 'layers'
     *      DATA  //DATA OBJECT
     *      DATA_OLD // For edit
     *      
     *  }
     * ]
     * 
     */
    constructor(digitizer) {

        this.digitizer = digitizer;
        this.undoStack = [];
        this.redoStack = [];



    }

    pushToUndoStack(actionArray) {
        this.undoStack.push(actionArray);
    }

    undo() {
        if (this.undoStack.length > 0) {
            let undoGroup = this.undoStack.pop();

            for (let i = 0; i < undoGroup.length; i++) {
                let undoItem = undoGroup[i];

                this.processUndoItem(undoItem);
            }

            this.redoStack.push(undoGroup);
        }
    }

    redo() {

        if (this.redoStack.length > 0) {
            let redoGroup = this.redoStack.pop();

            for (let i = 0; i < redoGroup.length; i++) {
                let redoItem = redoGroup[i];

                this.processRedoItem(redoItem);
            }

            this.undoStack.push(redoGroup);
        }
    }

    processUndoItem(undoItems) {

        for (let j = 0; j < undoItems.length; j++) {
            let undoItem = undoItems[j];
            switch (undoItem.ACTION) {
                case CONSTANTS.UNDO_ACTIONS.A:
                    this.digitizer.deleteRawData(undoItem.OBJECT, undoItem.DATA.id);
                    break;

                case CONSTANTS.UNDO_ACTIONS.D:
                    this.digitizer.addRawData(undoItem.OBJECT, undoItem.DATA);
                    break;

                case CONSTANTS.UNDO_ACTIONS.E:
                    this.digitizer.editRawData(undoItem.OBJECT, undoItem.DATA, undoItem.DATA_OLD);
                    break;
            }

        }

    }

    processRedoItem(redoItems) {

        for (let j = 0; j < redoItems.length; j++) {
            let redoItem = redoItems[j];
            switch (redoItem.ACTION) {
                case CONSTANTS.UNDO_ACTIONS.A:
                    this.digitizer.addRawData(redoItem.OBJECT, redoItem.DATA);
                    break;

                case CONSTANTS.UNDO_ACTIONS.D:
                    this.digitizer.deleteRawData(redoItem.OBJECT, redoItem.DATA.id);
                    break;

                case CONSTANTS.UNDO_ACTIONS.E:
                    this.digitizer.editRawData(redoItem.OBJECT, redoItem.DATA_OLD, redoItem.DATA);
                    break;
            }
        }
    }

    clearStack() {
        this.undoStack = [];
        this.redoStack = [];
    }



}

export { UndoRedoManager };