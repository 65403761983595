import WKB from "ol/format/WKB";
import { get as ol_proj_get } from "ol/proj";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { transformExtent } from "ol/proj";
import Feature from "ol/Feature";

class GPKGWriter {
  constructor(digitizer) {
    this.digitizer = digitizer;
    this.options = digitizer.options;
  }

  /**
   * Process OGC GeoPackage (SQLite database) once loaded
   * @param {*} loadedGpkgFile - name of GeoPackage file (for diagnostics only)
   * @param {WebAssembly} sqlWasm - sql.js SQLITE database access library
   * @param {string} displayProjection - map display projection (e.g. EPSG:3857)
   * @returns {object[]} array of 2 objects: [<data tables>, <slds>]
   *   <data tables>: OpenLayers vector sources, indexed by table name
   *   <slds>: SLD XML strings, indexed by layer name
   */
  processLayers(sqlWasm) {
    var db;

    // Data and associated SLD styles loaded both from GPKG
    var dataFromGpkg = {};
    var sldsFromGpkg = {};

    // try {
    db = new sqlWasm.Database();

    this.initMetadata(db);

    for (let i = 0; i < this.digitizer.options.layer_master.length; i++) {
      let layerMaster = this.digitizer.options.layer_master[i];
      if (layerMaster.user_layer && layerMaster.user_layer === true) {
        let source = this.digitizer.getVectorLayer(layerMaster.layer_code)?.getSource();
        if (source) {
          this.createLayerTable(db, layerMaster, source.getProperties()["pk"]);
        }
      }
    }

    // } catch (err) {
    //   throw new Error('Unable to extract feature tables from OGC GeoPackage file "' + '":\n' + err);
    // }

    var arraybuff = db.export();
    var blob = new Blob([arraybuff]);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.download = "bhunaksha.gpkg";
    a.onclick = function () {
      setTimeout(function () {
        window.URL.revokeObjectURL(a.href);
      }, 1500);
    };
    a.click();
  }

  decodeType(type) {
    type = type.toLowerCase();
    if (type.startsWith("string")) {
      return "TEXT";
    } else if (type.startsWith("int")) {
      return "INTEGER ";
    } else {
      return "TEXT ";
    }
  }

  initMetadata(db) {
    db.run(
      "CREATE TABLE gpkg_spatial_ref_sys (srs_name TEXT NOT NULL,srs_id INTEGER NOT NULL PRIMARY KEY,organization TEXT NOT NULL,organization_coordsys_id INTEGER NOT NULL,definition  TEXT NOT NULL,description TEXT)"
    );
    db.run(
      "CREATE TABLE gpkg_contents (table_name TEXT NOT NULL PRIMARY KEY,data_type TEXT NOT NULL,identifier TEXT UNIQUE,description TEXT DEFAULT '',last_change DATETIME NOT NULL DEFAULT (strftime('%Y-%m-%dT%H:%M:%fZ','now')),min_x DOUBLE, min_y DOUBLE,max_x DOUBLE, max_y DOUBLE,srs_id INTEGER,CONSTRAINT fk_gc_r_srs_id FOREIGN KEY (srs_id) REFERENCES gpkg_spatial_ref_sys(srs_id))"
    );
    db.run(
      "CREATE TABLE gpkg_geometry_columns (table_name TEXT NOT NULL,column_name TEXT NOT NULL,geometry_type_name TEXT NOT NULL,srs_id INTEGER NOT NULL,z TINYINT NOT NULL,m TINYINT NOT NULL,CONSTRAINT pk_geom_cols PRIMARY KEY (table_name, column_name),CONSTRAINT uk_gc_table_name UNIQUE (table_name),CONSTRAINT fk_gc_tn FOREIGN KEY (table_name) REFERENCES gpkg_contents(table_name),CONSTRAINT fk_gc_srs FOREIGN KEY (srs_id) REFERENCES gpkg_spatial_ref_sys (srs_id))"
    );
    db.run(
      'CREATE TABLE "layer_styles" ( "id" INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL, "f_table_catalog" TEXT(256), "f_table_schema" TEXT(256), "f_table_name" TEXT(256), "f_geometry_column" TEXT(256), "styleName" TEXT(30), "styleQML" TEXT, "styleSLD" TEXT, "useAsDefault" BOOLEAN, "description" TEXT, "owner" TEXT(30), "ui" TEXT(30), "update_time" DATETIME DEFAULT (strftime(\'%Y-%m-%dT%H:%M:%fZ\',\'now\')))'
    );
    db.run(
      "CREATE TABLE gpkg_extensions (table_name TEXT,column_name TEXT,extension_name TEXT NOT NULL,definition TEXT NOT NULL,scope TEXT NOT NULL,CONSTRAINT ge_tce UNIQUE (table_name, column_name, extension_name))"
    );

    let sqlStr = `INSERT INTO "gpkg_spatial_ref_sys" VALUES ('Undefined cartesian SRS',-1,'NONE',-1,'undefined','undefined cartesian coordinate reference system');
    INSERT INTO "gpkg_spatial_ref_sys" VALUES ('Undefined geographic SRS',0,'NONE',0,'undefined','undefined geographic coordinate reference system');
    INSERT INTO "gpkg_spatial_ref_sys" VALUES ('WGS 84 geodetic',4326,'EPSG',4326,'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AXIS["Latitude",NORTH],AXIS["Longitude",EAST],AUTHORITY["EPSG","4326"]]','longitude/latitude coordinates in decimal degrees on the WGS 84 spheroid');
    `;
    db.run(sqlStr);

    sqlStr = `INSERT INTO "gpkg_contents" ("table_name", "data_type", "identifier", "description", "min_x", "min_y", "max_x", "max_y", "srs_id")
     VALUES ('layer_styles', 'attributes', 'layer_styles', '',  '', '', '', '', '0');`;
    db.run(sqlStr);
  }

  createLayerTable(db, layerMaster, pkfield) {
    let self = this;
    let formatWKB = new WKB({ hex: false, ewkb: false });

    let sqlStr = 'CREATE TABLE "' + layerMaster.layer_name + '" (';
    if (!pkfield) pkfield = "fid";
    sqlStr += '"' + pkfield + '" INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL, "geom" BLOB ';
    for (let i = 0; i < layerMaster.attrs.length; i++) {
      if (layerMaster.attrs[i].key === pkfield) {
        continue;
      }
      sqlStr += ', "' + layerMaster.attrs[i].key + '" ';
      if (layerMaster.attrs[i].mandatory === true) {
        sqlStr += " NOT NULL ";
      }
      sqlStr += this.decodeType(layerMaster.attrs[i].type);
    }

    sqlStr += ") ";

    db.run(sqlStr);

    let srid = 4326;
    let proj = "EPSG:4326";
    let source = gisvar.findLayerById(layerMaster.layer_code)?.getSource();
    if (source.getProperties().origProjection) {
      proj = source.getProperties().origProjection;
    }

    if (source) {
      let extent = source.getExtent();
      if (source.getProperties().origProjection) {
        extent = transformExtent(extent, this.digitizer.options.output_proj, source.getProperties().origProjection);
      } else {
        //srid = Number(this.digitizer.options.output_proj.replace("EPSG:", ""));
        extent = transformExtent(extent, this.digitizer.options.output_proj, proj);
      }

      sqlStr = 'INSERT INTO "gpkg_contents" ("table_name", "data_type", "identifier", "description",   "min_x", "min_y", "max_x", "max_y", "srs_id") ';
      sqlStr += " VALUES(?,?,?,?,?,?,?,?,?) ";

      let stmt1 = db.prepare(sqlStr);
      stmt1.run([layerMaster.layer_name, "features", layerMaster.layer_name, "", extent[0], extent[2], extent[1], extent[3], srid]);
      stmt1.free();

      sqlStr = 'INSERT INTO "' + layerMaster.layer_name + '" (geom ';
      for (let i = 0; i < layerMaster.attrs.length; i++) {
        if (layerMaster.attrs[i].key === pkfield) {
          continue;
        }
        sqlStr += ', "' + layerMaster.attrs[i].key + '" ';
      }

      sqlStr += ") VALUES( ? ";
      for (let i = 0; i < layerMaster.attrs.length; i++) {
        if (layerMaster.attrs[i].key === pkfield) {
          continue;
        }
        sqlStr += ",? ";
      }
      sqlStr += ") ;";
      stmt1 = db.prepare(sqlStr);
      source.forEachFeature(function (f) {
        let featureWkb = new Uint8Array(
          formatWKB.writeFeature(new Feature(f.getGeometry()), {
            dataProjection: "EPSG:" + srid,
            featureProjection: self.digitizer.options.output_proj,
          })
        );
        // console.log(featureWkb);

        let extent = f.getGeometry().getExtent();

        extent = transformExtent(extent, self.digitizer.options.output_proj, proj);
        // console.log(extent);
        let gpkgKb = self.createGeoPkgHeader(4326, extent[0], extent[2], extent[1], extent[3]);

        var mergedArray = new Uint8Array(gpkgKb.length + featureWkb.length);
        mergedArray.set(gpkgKb);
        mergedArray.set(featureWkb, gpkgKb.length);
        // console.log(self.toHexString(featureWkb));
        let data = [];
        data.push(mergedArray);
        for (let i = 0; i < layerMaster.attrs.length; i++) {
          if (layerMaster.attrs[i].key === pkfield) {
            continue;
          }
          data.push(f.getProperties()[layerMaster.attrs[i].key]);
        }

        stmt1.run(data);
      });
      stmt1.free();

      sqlStr = `INSERT INTO "layer_styles" ( "f_table_catalog", "f_table_schema", "f_table_name", "f_geometry_column", "styleName", "styleQML", "styleSLD", "useAsDefault", "description", "owner", "ui")
     VALUES ( '', '', ?, 'geom', ?, '', ?, '1', '', '', '');`;

      stmt1 = db.prepare(sqlStr);
      stmt1.run([layerMaster.layer_code, layerMaster.layer_code, layerMaster.sld_style]);
      stmt1.free();

      sqlStr = `INSERT INTO  "gpkg_geometry_columns" ("table_name", "column_name", "geometry_type_name", "srs_id", "z", "m") 
    VALUES (?, 'geom', ?,?, '0', '0');
    `;

      stmt1 = db.prepare(sqlStr);
      stmt1.run([layerMaster.layer_code, layerMaster.geom_type.toUpperCase(), srid]);
      stmt1.free();
    }
  }

  createGeoPkgHeader(srid, minx, maxx, miny, maxy) {
    // console.log(` ${minx}  ${maxx} ${miny}} ${maxy}`);
    const littleEndian = true;
    let header = new Uint8Array(40);
    header[0] = 0x47;
    header[1] = 0x50;
    header[2] = 0x00;
    header[3] = 3;

    let intArray = new Int32Array(1);
    intArray[0] = srid;
    let view = new DataView(intArray.buffer);
    header[4] = view.getUint8(0, littleEndian);
    header[5] = view.getUint8(1, littleEndian);
    header[6] = view.getUint8(2, littleEndian);
    header[7] = view.getUint8(3, littleEndian);

    var floatArray = new Float64Array(1);
    floatArray[0] = minx;
    view = new DataView(floatArray.buffer);
    header[8] = view.getUint8(0, littleEndian);
    header[9] = view.getUint8(1, littleEndian);
    header[10] = view.getUint8(2, littleEndian);
    header[11] = view.getUint8(3, littleEndian);
    header[12] = view.getUint8(4, littleEndian);
    header[13] = view.getUint8(5, littleEndian);
    header[14] = view.getUint8(6, littleEndian);
    header[15] = view.getUint8(7, littleEndian);

    floatArray = new Float64Array(1);
    floatArray[0] = maxx;
    view = new DataView(floatArray.buffer);
    header[16] = view.getUint8(0, littleEndian);
    header[17] = view.getUint8(1, littleEndian);
    header[18] = view.getUint8(2, littleEndian);
    header[19] = view.getUint8(3, littleEndian);
    header[20] = view.getUint8(4, littleEndian);
    header[21] = view.getUint8(5, littleEndian);
    header[22] = view.getUint8(6, littleEndian);
    header[23] = view.getUint8(7, littleEndian);

    floatArray = new Float64Array(1);
    floatArray[0] = miny;
    view = new DataView(floatArray.buffer);
    header[24] = view.getUint8(0, littleEndian);
    header[25] = view.getUint8(1, littleEndian);
    header[26] = view.getUint8(2, littleEndian);
    header[27] = view.getUint8(3, littleEndian);
    header[28] = view.getUint8(4, littleEndian);
    header[29] = view.getUint8(5, littleEndian);
    header[30] = view.getUint8(6, littleEndian);
    header[31] = view.getUint8(7, littleEndian);

    floatArray = new Float64Array(1);
    floatArray[0] = maxy;
    view = new DataView(floatArray.buffer);
    header[32] = view.getUint8(0, littleEndian);
    header[33] = view.getUint8(1, littleEndian);
    header[34] = view.getUint8(2, littleEndian);
    header[35] = view.getUint8(3, littleEndian);
    header[36] = view.getUint8(4, littleEndian);
    header[37] = view.getUint8(5, littleEndian);
    header[38] = view.getUint8(6, littleEndian);
    header[39] = view.getUint8(7, littleEndian);
    return header;

    // Combine the header buffer and WKB buffer to create the final GeoPackage binary geometry
    //const gpkgGeom = Buffer.concat([headerBuffer, wkbBuffer], 8 + wkbSize);
  }

  toHexString(byteArray) {
    return Array.prototype.map
      .call(byteArray, function (byte) {
        return ("0" + (byte & 0xff).toString(16)).slice(-2);
      })
      .join("");
  }
}

export { GPKGWriter };
