import Map from "ol/Map";
import View from "ol/View";
import { LineString, Point, Circle as CircleGeom, Polygon, GeometryCollection } from "ol/geom";
import WKT from "ol/format/WKT";
import WKB from "ol/format/WKB";
import { get as ol_proj_get } from "ol/proj";
import MVT from "ol/format/MVT";
import GeoJSON from "ol/format/GeoJSON";
import GMLFormat from "ol/format/GML";
import VectorLayer from "ol/layer/Vector";
import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import ImageLayer from "ol/layer/Image";
import Feature from "ol/Feature";
import MousePosition from "ol/control/MousePosition";
import FullScreen from "ol/control/FullScreen";
import { Fill, Stroke, Style, Circle, Text } from "ol/style";
import { createStringXY } from "ol/coordinate";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import Attribution from "ol/control/Attribution";
// import Grid from 'tui-grid';
// import 'jsts/dist/jsts';
import Coordinate from "jsts/org/locationtech/jts/geom/Coordinate";
import LineSegment from "jsts/org/locationtech/jts/geom/LineSegment";
import GeometryFactory from "jsts/org/locationtech/jts/geom/GeometryFactory";
import UnionOp from "jsts/org/locationtech/jts/operation/union/UnionOp";
import BufferOp from "jsts/org/locationtech/jts/operation/buffer/BufferOp";
import DistanceOp from "jsts/org/locationtech/jts/operation/distance/DistanceOp";
import RelateOp from "jsts/org/locationtech/jts/operation/relate/RelateOp";
import Polygonizer from "jsts/org/locationtech/jts/operation/polygonize/Polygonizer";
import InteriorPointArea from "jsts/org/locationtech/jts/algorithm/InteriorPointArea";
import WKTReader from "jsts/org/locationtech/jts/io/WKTReader";
import WKTWriter from "jsts/org/locationtech/jts/io/WKTWriter";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import ImageWMS from "ol/source/ImageWMS";
import TileWMS from "ol/source/TileWMS";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import TileArcGISRest from "ol/source/TileArcGISRest";
import BingMaps from "ol/source/BingMaps";
import XYZ from "ol/source/XYZ";
// import GeometryType from 'ol/geom/GeometryType'

import { defaultOptions } from "./Options";
import { CONSTANTS } from "./Constants";
import { UIBuilder } from "./UIBuilder";
import { GPKGReader } from "./GPKGReader";
import { GPKGWriter } from "./GPKGWriter";
import { isStringEmpty, circleIntersection, arrayEquals } from "./utils";
import { InteractionManager } from "./InteractionManager";
import { UndoRedoManager } from "./UndoRedoManager";
import { StyleBuilder } from "./StyleBuilder";
import GeoImageSource from "ol-ext/source/GeoImage";
//import PerspectiveMap from "ol-ext/map/PerspectiveMap";
import { ImageLayerManager } from "./ImageLayerManager";
import { transform } from "ol/proj";
import { BhuNakshaLogo } from "./BhuNakshaLogo";
import { LocateMeButton } from "./LocateMeButton";
import { ExtraControlButtonArea } from "./ExtraControlButtonArea";
import { SearchButton } from "./SearchButton";
import { GeoTracker } from "./GeoTracker";
import { geojson as flatgeobuf } from "flatgeobuf";
import * as SLDReader from "@nieuwlandgeo/sldreader";

class Digitizer {
  static VERSION = "1.3.2";

  constructor(targetDiv, options) {
    this.map = null;
    this.styleDef = {};
    let defaultCopy = Object.assign({}, defaultOptions);
    this.options = Object.assign(defaultCopy, options); // deepMerge(defaultOptions ,options);
    this.options.layer_master = options.layer_master ? defaultOptions.layer_master.concat(options.layer_master) : defaultOptions.layer_master;

    if (!"visible_tools" in this.options) {
      this.options.visible_tools = CONSTANTS.DEFAULT_VISIBLE_TOOLS;
    }

    this._wktFormat = new WKT();
    this._jstsReader = new WKTReader();
    this._jstsWriter = new WKTWriter();
    this._geometryFactory = new GeometryFactory();
    this.generatedData = { points: [], lines: [], polygons: [] /* layers: [] ,linesegments: [] */ };
    this.gridData = { segment: [], lines: [], polygon: [] };
    // this.CustomLayerMaster = {};
    // this.CustomLayerMasterMin = {};
    this.undoStack = [];
    this.redoStack = [];

    //GRID
    this.isgraticuleshown = false;
    this.gridSpaceing = 10;
    this.angleValue = 5;
    this.currentGridLoc = [];
    //
    this.uiBuilder = new UIBuilder(targetDiv, this);

    this.undoRedoManager = new UndoRedoManager(this);
    this.interactionManager = new InteractionManager(this);
    this.styleBuilder = new StyleBuilder();
    this.imageLayerManager = new ImageLayerManager(this);

    this.uiBuilder.loadUI();

    GeoImageSource.prototype.getUrl = function () {
      return this._image.src;
    };

    this.touchMode = !this.hasMouse();
  }

  initMaps() {
    var self = this;

    let polyFeatures = []; //for polygon
    let ptFeatures = []; //for points
    let lnFeatures = []; //for lines
    let styleDefs = self.options.styleDefs;

    if (!this.vectorPoints || this.vectorPoints === null) {
      //initializing the vectorpoints
      this.vectorPoints = new VectorLayer({
        name: CONSTANTS.LAYER_NAMES.VECTOR_POINTS,
        source: new VectorSource({
          features: ptFeatures,
        }),
        zIndex: 54,
        layer_type: CONSTANTS.LAYERTYPE.VECTOR,
        style: function (feature, resolution) {
          let layerCode = feature.get("layer_code");
          let layerMaster = self.getLayerMasterDataFromCode(layerCode);
          let ev = self.getDataById("points", feature.get("id"));

          if (layerMaster) {
            if (!(layerCode in self.styleDef)) {
              if (layerMaster?.sld_style && layerMaster?.sld_style !== "") {
                const sld = SLDReader.Reader(layerMaster.sld_style);
                const lyr = SLDReader.getLayer(sld);
                const style = SLDReader.getStyle(lyr);
                const featureTypeStyle = style.featuretypestyles[0];

                const styleFun = SLDReader.createOlStyleFunction(featureTypeStyle, {
                  imageLoadedCallback: () => {
                    self.vectorPolygon.changed();
                  },
                });
                self.styleDef[layerCode] = styleFun;
              }
            }
            if (layerMaster?.sld_style && layerMaster?.sld_style !== "") {
              return self.styleDef[layerCode](feature, resolution);
            } else {
              return self.styleBuilder.createPointCircleStyle(feature, ev.data, resolution, layerMaster.label, {
                ...layerMaster.style_def,
              });
            }
          }
        },
      });
    }
    if (!this.vectorLines || this.vectorLines === null) {
      //initializing vectorlines
      this.vectorLines = new VectorLayer({
        name: CONSTANTS.LAYER_NAMES.VECTOR_LINES,
        layer_type: CONSTANTS.LAYERTYPE.VECTOR,
        source: new VectorSource({
          features: lnFeatures,
        }),
        zIndex: 52,
        style: function (feature, resolution) {
          let layerCode = feature.get("layer_code");
          let layerMaster = self.getLayerMasterDataFromCode(layerCode);
          let ev = self.getDataById("lines", feature.get("id"));

          if (layerMaster) {
            if (!(layerCode in self.styleDef)) {
              if (layerMaster?.sld_style && layerMaster?.sld_style !== "") {
                const sld = SLDReader.Reader(layerMaster.sld_style);
                const lyr = SLDReader.getLayer(sld);
                const style = SLDReader.getStyle(lyr);
                const featureTypeStyle = style.featuretypestyles[0];

                const styleFun = SLDReader.createOlStyleFunction(featureTypeStyle, {
                  imageLoadedCallback: () => {
                    self.vectorPolygon.changed();
                  },
                });
                self.styleDef[layerCode] = styleFun;
              }
            }
            if (layerMaster?.sld_style && layerMaster?.sld_style !== "") {
              return self.styleDef[layerCode](feature, resolution);
            } else {
              return self.styleBuilder.createLineStyle(feature, ev.data, resolution, layerMaster.label, { ...layerMaster.style_def });
            }
          }

          // let st = new Style({
          //     stroke: new Stroke({
          //         width: 1,
          //         color: [237, 212, 0, 0.8],
          //     }),

          //     text: new Text({
          //         placement: 'line',
          //         textBaseline: 'bottom',
          //         text: 'A',
          //         fill: new Fill({ color: 'rgba(0, 0, 0, 0.7)' }),
          //         stroke: new Stroke({
          //             color: '#fff',
          //             width: 3
          //         })
          //     })
          // });

          // let styles = [];
          // // let style = new Style({
          // //     geometry: feature.getGeometry(),
          // //     stroke: new Stroke({
          // //         width: 1,
          // //         color: [255, 160, 0, 1], //237, 212, 0, 0.8
          // //     }),
          // //     text: new Text({
          // //         placement: 'line',
          // //         textBaseline: 'bottom',
          // //         text: feature.get('length'),
          // //         fill: new Fill({ color: 'rgba(0, 0, 0, 0.7)' }),
          // //     })
          // // });

          // //styles.push(style);

          // feature.getGeometry().forEachSegment(function (a, b) {

          //     const segment = new LineString([a, b]);
          //     const label = segment.getLength().toFixed(2);
          //     let st1 = st.clone();
          //     st1.setGeometry(segment);
          //     st1.getText().setText(label);
          //     styles.push(st1);
          //     //  segStyle.setGeometry(segment);
          //     // styles.push(segStyle);
          // });
        },
      });
    }

    if (!this.vectorPolygon || this.vectorPolygon === null) {
      //initializing vectorpolygon

      this.vectorPolygon = new VectorLayer({
        name: CONSTANTS.LAYER_NAMES.VECTOR_POLYGONS,
        layer_type: CONSTANTS.LAYERTYPE.VECTOR,
        source: new VectorSource({
          features: polyFeatures,
        }),
        zIndex: 50,
        style: function (feature, resolution) {
          let layerCode = feature.get("layer_code");
          let layerMaster = self.getLayerMasterDataFromCode(layerCode);
          let ev = self.getDataById("polygons", feature.get("id"));

          if (layerMaster) {
            if (!(layerCode in self.styleDef)) {
              if (layerMaster?.sld_style && layerMaster?.sld_style !== "") {
                const sld = SLDReader.Reader(layerMaster.sld_style);
                const lyr = SLDReader.getLayer(sld);
                const style = SLDReader.getStyle(lyr);
                const featureTypeStyle = style.featuretypestyles[0];

                const styleFun = SLDReader.createOlStyleFunction(featureTypeStyle, {
                  imageLoadedCallback: () => {
                    self.vectorPolygon.changed();
                  },
                });
                self.styleDef[layerCode] = styleFun;
              }
            }
            if (layerMaster?.sld_style && layerMaster?.sld_style !== "") {
              return self.styleDef[layerCode](feature, resolution);
            } else {
              return self.styleBuilder.createPolygonAreaStyle(feature, ev.data, resolution, layerMaster.label, {
                ...layerMaster.style_def,
              });
            }
          }

          // return new Style({
          //     stroke: new Stroke({
          //         color: 'rgba(0, 200, 0, 0.2)',
          //         width: 1,
          //     }),
          //     fill: new Fill({
          //         color: 'rgba(0, 200, 0, 0.1)',
          //     }),
          //     text: new Text({
          //         overflow: true,
          //         fill: new Fill({
          //             color: '#000',
          //         }),
          //         text: feature.get('name') + "\n" + "\n" + feature.getGeometry().getArea().toFixed(2) + "\n",
          //         stroke: new Stroke({
          //             color: '#fff',
          //             width: 3
          //         })
          //     })
          // });
        },
      });
    }
    if (!this.tempLayer || this.tempLayer === null) {
      //initialize templayer
      this.tempLayer = new VectorLayer({
        name: CONSTANTS.LAYER_NAMES.TEMP_LAYER,
        layer_type: CONSTANTS.LAYERTYPE.TEMP,
        source: new VectorSource(),
        zIndex: 56,
        style: function (feature, resolution) {
          var styles = [
            new Style({
              stroke: new Stroke({
                color: "rgba(0, 153, 255, 0.9)",
                width: 1,
              }),
              fill: new Fill({
                color: "rgb(118, 210, 117, 0.8)",
              }),
              image: new Circle({
                radius: 12,
                fill: new Fill({ color: "rgba(61, 90, 254, 0.1)" }),
                stroke: new Stroke({
                  color: "rgba(0, 153, 255, 0.9)",
                  width: 1,
                }),
              }),
              text: new Text({
                placement: feature.getGeometry().getType() === "LineString" ? "line" : "middle",
                textBaseline: feature.getGeometry().getType() === "LineString" ? "bottom" : "middle",
                textAlign: "center",
                // textBaseline: 'middle',
                text: feature.get("label"),
                fill: new Fill({ color: "rgba(26, 35, 126, 0.9)" }),
                stroke: new Stroke({
                  color: "rgba(255, 255, 255, 0.9)",
                  width: 2,
                }),
                offsetX: 2,
                offsetY: 2,
              }),
            }),
          ];
          return styles;
        },
      });
    }

    // if (!this.customLayer || this.customLayer === null) {
    //     this.customLayer = new VectorLayer({
    //         name: CONSTANTS.LAYER_NAMES.CUSTOM_LAYER,
    //         source: new VectorSource(),
    //         style: function (feature, resolution) {
    //             let ftType = feature.get("geom_type");
    //             if (ftType === CONSTANTS.LAYERTYPE.POINTS) {
    //                 let iconUrl;
    //                 let fill = new Fill({ color: 'rgba(167, 56, 113, 0.8)' });
    //                 let ftlayerCode = feature.get("layer_code");
    //                 let lm = self.CustomLayerMaster[CONSTANTS.LAYERTYPE.POINTS][ftlayerCode];
    //                 let displayText = '';
    //                 let iter = 0;
    //                 for (const attr of lm.attrs) {
    //                     if (!feature.get(attr.key)) {
    //                         continue;
    //                     }
    //                     if (iter == 0) {
    //                         displayText += feature.get(attr.key);
    //                         iter++;
    //                     } else {
    //                         displayText += " / " + feature.get(attr.key);
    //                     }
    //                 }
    //                 let layerConf = lm.layer_conf;
    //                 if (layerConf) {
    //                     if (layerConf.icon) {
    //                         iconUrl = layerConf.icon;
    //                     } else if (layerConf.fillcolor) {
    //                         fill = new Fill({ color: layerConf.fillcolor });
    //                     }
    //                 }
    //                 if (iconUrl) {
    //                     return new Style({
    //                         image: new Icon({
    //                             // color: '#BADA55',
    //                             crossOrigin: 'anonymous',
    //                             // For Internet Explorer 11
    //                             // imgSize: [100, 100],
    //                             scale: 0.05,
    //                             anchor: [1, 400],
    //                             anchorXUnits: 'fraction',
    //                             anchorYUnits: 'pixels',
    //                             src: iconUrl,
    //                         }),
    //                         text: new Text({
    //                             textAlign: 'center',
    //                             textBaseline: 'middle',
    //                             text: displayText,
    //                             fill: new Fill({ color: 'rgba(33, 33, 33, 0.8)' }),
    //                             offsetY: 20
    //                         })
    //                     });
    //                 }
    //             } else if (ftType === CONSTANTS.LAYERTYPE.LINES) {
    //                 let color = 'rgba(45, 32, 208, 0.8)';
    //                 let ftlayerCode = feature.get("layer_code");
    //                 let lm = self.CustomLayerMaster[CONSTANTS.LAYERTYPE.LINES][ftlayerCode];
    //                 let displayText = '';
    //                 let iter = 0;
    //                 for (const attr of lm.attrs) {
    //                     if (!feature.get(attr.key)) {
    //                         continue;
    //                     }
    //                     if (iter == 0) {
    //                         displayText += feature.get(attr.key);
    //                         iter++;
    //                     } else {
    //                         displayText += " / " + feature.get(attr.key);
    //                     }
    //                 }
    //                 let layerConf = lm.layer_conf;
    //                 if (layerConf && layerConf.strokecolor) {
    //                     color = layerConf.strokecolor;
    //                 }
    //                 return new Style({
    //                     stroke: new Stroke({
    //                         width: 5,
    //                         color: color,
    //                     }),
    //                     text: new Text({
    //                         placement: 'line',
    //                         textBaseline: 'bottom',
    //                         text: displayText,
    //                         fill: new Fill({ color: 'rgba(0, 0, 0, 0.7)' }),
    //                         offsetY: 20
    //                     })
    //                 });
    //             } else if (ftType === CONSTANTS.LAYERTYPE.POLYGON) {
    //                 let color = 'rgba(0, 200, 0, 0.1)';
    //                 let ftlayerCode = feature.get("layer_code");
    //                 let lm = CONSTANTS.CustomLayerMaster[CONSTANTS.LAYERTYPE.POLYGON][ftlayerCode];
    //                 let displayText = '';
    //                 let iter = 0;
    //                 for (const attr of lm.attrs) {
    //                     if (!feature.get(attr.key)) {
    //                         continue;
    //                     }
    //                     if (iter == 0) {
    //                         displayText += feature.get(attr.key);
    //                         iter++;
    //                     } else {
    //                         displayText += " / " + feature.get(attr.key);
    //                     }
    //                 }
    //                 let layerConf = lm.layer_conf;
    //                 if (layerConf && layerConf.fillcolor) {
    //                     color = layerConf.fillcolor;
    //                 }
    //                 return new Style({
    //                     stroke: new Stroke({
    //                         color: 'rgba(0, 200, 0, 0.2)',
    //                         width: 1,
    //                     }),
    //                     fill: new Fill({
    //                         color: color,
    //                     }),
    //                     text: new Text({
    //                         overflow: true,
    //                         fill: new Fill({
    //                             color: '#000',
    //                         }),
    //                         text: displayText,
    //                         stroke: new Stroke({
    //                             color: '#fff',
    //                             width: 3
    //                         }),
    //                         offsetY: 20
    //                     })
    //                 });
    //             }
    //         }
    //     });
    // }
    let createdLayers = [];
    // let createdLayers = [this.vectorPolygon, this.vectorLines, this.vectorLineSegments, this.vectorPoints, this.tempLayer, this.customLayer];
    // if (this.initialData.wms_layers && this.initialData.wms_layers.length > 0) {
    //     for (const layerData of this.initialData.wms_layers) {
    //         let layer = new ImageLayer({
    //             name: layerData.params.LAYERS,
    //             source: new ImageWMSSource({
    //                 url: layerData.url,
    //                 params: layerData.params,
    //                 serverType: layerData.server_type,
    //                 visible: layerData.visible,
    //             }),
    //             opacity: layerData.opacity
    //         });
    //         createdLayers.push(layer);
    //     }
    // }

    //Added on 18-01-2022
    ///////// New layers
    if (this.options.proj_defs) {
      proj4.defs(this.options.proj_defs);
      register(proj4);
    }

    for (let i = 0; i < this.options.output_layers.length; i++) {
      let wmsLayer = null;
      let layerDef = this.options.output_layers[i];
      if (layerDef.searchable) {
        self.SearchableLayerCode = layerDef.source_parms.params.layer_code;
      }
      wmsLayer = self.createLayer(layerDef);
      createdLayers.push(wmsLayer);
    }
    // createdLayers.push(this.customLayer);
    createdLayers.push(this.vectorPolygon);
    createdLayers.push(this.vectorLines);
    createdLayers.push(this.vectorPoints);
    createdLayers.push(this.tempLayer);

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      //projection: 'EPSG:24379',
      // comment the following two lines to have the mouse position
      // be placed within the map.
      className: "ol-control custom-mouse-position",
      // target: document.getElementById('mouse-position'),
    });
    if (this.map === null) {
      //initalizing the map

      let controls = [mousePositionControl, new ScaleLine({ units: "metric" }), new BhuNakshaLogo()];

      if (self.options.locate_me_button === true) {
        let locateMeOpt = {
          className: "locate-me-btn ",
          handler: self.locateMe,
          digitizer: this,
        };
        controls.push(new LocateMeButton(locateMeOpt));
      }
      if (self.options.full_screen_button === true) {
        controls.push(new FullScreen({ source: "bhugisDIVContents" }));
      }
      if (self.options.search_button_function) {
        controls.push(
          new SearchButton({
            handler: self.options.search_button_function,
          })
        );
      }
      controls.push(new ExtraControlButtonArea({ className: "extra-control-button-area " }));
      this.map = new Map({
        controls: defaultControls().extend(controls),
        layers: createdLayers,
        target: "map",
        view: new View({
          projection: self.options.output_proj,
          //center: transform([77.160061, 8.392944], 'EPSG:4326', 'EPSG:32642'),
          center: self.options.map_center,
          //center: [0, 0],
          zoom: 13,
        }),
      });
      this.map.getTargetElement().classList.add("spinner");

      this.map.on("contextmenu", function (e) {
        e.preventDefault();
      });
      this.map.on("click", function (e) {
        if (self.options.default_click_function && self.interactionManager.getCurrentInteraction() === CONSTANTS.INTERACTIONS.POINTER) {
          self.options.default_click_function(e.coordinate);
        }

        if (document.getElementById("CustomContextMenu").style.display === "block") {
          document.getElementById("CustomContextMenu").style.display = "none";
        }
      });
      this.map.on("interaction_completed", function (e) {
        let undoItemGroup = [];
        if (e.data) {
          switch (e.data.interaction_code) {
            case CONSTANTS.INTERACTIONS.DELETE_POINT:
              self.deletePointCoordinate(e.data.coordinates, undoItemGroup);

              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.DELETE_SEGMENT:
              self.deleteSegment(e.data.data.coords[0], e.data.data.coords[1], undoItemGroup);
              break;

            case CONSTANTS.INTERACTIONS.SELECT_SEGMENT:
              // console.log(e.options);
              if (e.options.event_source && e.options.event_source === "SNAP_POINT") {
                // self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT,{event_source: "SNAP_POINT", segment_data: e.data});
                self.interactionManager.removeCurrentInteraction();
                self.snapPointToSegment(e.options.point_data, e.data.data.coords);
                return;
              } else if (e.options.event_source && e.options.event_source === "TRIM_LINE") {
                self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT, { event_source: "TRIM_LINE2", segment_data: e.data.data });
                return;
              } else if (e.options.event_source && e.options.event_source === "TRIM_LINE2") {
                //self.trimLine(e.data.options.segment_data.data.coords)
                // console.log(JSON.stringify(e.data));
                // console.log(JSON.stringify(e.options));
                self.trimLine(e.options.segment_data.coords, e.data.data.coords);
                self.interactionManager.removeCurrentInteraction();
                return;
              } else if (e.options.event_source && e.options.event_source === "AREA_DIVISION") {
                if (e.options.on_select) {
                  e.options.on_select(e.data.data);
                }
                self.interactionManager.removeCurrentInteraction();
                return;
              }
              // self.interactionManager.removeCurrentInteraction();
              break;
            case CONSTANTS.INTERACTIONS.DELETE_LINE:
              self.deleteLineByID(e.data.id, undoItemGroup);
              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.DELETE_POLYGON:
              self.deletePolygonByID(e.data.id, undoItemGroup);
              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.SPLIT_POLYGON:
              self.splitPolygon(e.data.id, undoItemGroup);
              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.EDIT_POINT:
              if (e.data.options.event_source && e.data.options.event_source === "SNAP_POINT") {
                // let data = self.digitizer.getDataById("points", dataArg.id);
                console.log(e.data);

                self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT, {
                  event_source: "SNAP_POINT",
                  point_data: e.data.coordinates,
                });

                return;
              } else {
                self.uiBuilder.constructAndOpenEditPointPanel(e.data);
                self.interactionManager.removeCurrentInteraction();
              }
              break;

            case CONSTANTS.INTERACTIONS.PLACE_POINT:
              self.addPoint("", "", e.data.coordinate, undoItemGroup);
              //self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.DRAW_LINE:
              if (e.data.options.event_source && e.data.options.event_source === "EXTERNAL_ACTION_BUTTON") {
                if (e.data.options.target_function) {
                  e.data.geometry_wkt = self._wktFormat.writeGeometry(e.data.geometry);
                  e.data.options.target_function(e.data);
                }
                self.interactionManager.removeCurrentInteraction();
                break;
              } else {
                if (e.data.options.layer_code === "_SPLIT_LINE_") {
                  self.addLine("", "", e.data.coordinate, undoItemGroup, e.data.options.layer_code);
                  if (e.data.options.split_mode && e.data.options.split_mode === "LIVE") {
                    if (self.mergeList && self.mergeList.length > 0) {
                      let splitted = self.splitMultiplePolygons();
                      if (splitted) {
                        self.clearSplitLines();
                        self.clearTempGeom();
                      }
                    }
                    // else{
                    //     self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SPLIT_POLYGON);
                    // }
                  }
                  // document.getElementById("_poly_split_draw_line_accept").classList.add('DN');
                  // document.getElementById("_poly_split_draw_line_clear").classList.add('DN');
                  // if(self.mergeList.length>0){
                  //     // undoItemGroup =[];
                  //     for(let k=0;k<self.mergeList.length;k++){
                  //          self. splitPolygonTemp(self.mergeList[k].id) ;
                  //     }

                  // }
                } else {
                  self.addLine("", "", e.data.coordinate, undoItemGroup, e.data.options.layer_code);
                }
                self.interactionManager.removeCurrentInteraction();
                break;
              }
            case CONSTANTS.INTERACTIONS.CUT_SEGMENT:
              self.insertPointInSegment(e.data.start_coordinate, e.data.end_coordinate, e.data.center_coordinate, undoItemGroup);
              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.DRAW_POLYGON:
              if (e.data.options.event_source && e.data.options.event_source === "EXTERNAL_ACTION_BUTTON") {
                if (e.data.options.target_function) {
                  let polygon = new Polygon(e.data.coordinate);
                  e.data.geometry_wkt = self._wktFormat.writeGeometry(polygon);
                  e.data.options.target_function(e.data);
                }
                self.interactionManager.removeCurrentInteraction();
                break;
              } else {
                self.addPolygon("", "", e.data.coordinate, undoItemGroup, e.data.options.layer_code);
                self.interactionManager.removeCurrentInteraction();
                break;
              }
            case CONSTANTS.INTERACTIONS.INSERT_POINT_ON_LINE:
              self.uiBuilder.lineOnPointUI(e.data.id, e.data.coordinates);
              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.CUT_SEGMENT_UI:
              self.uiBuilder.cutSegment(e.data.start_coordinate, e.data.end_coordinate, e.data.center_coordinate);
              self.interactionManager.removeCurrentInteraction();
              break;

            case CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE:
              if (e.data.options.event_source && e.data.options.event_source === "EXTERNAL_ACTION_BUTTON") {
                if (e.data.options.target_function) {
                  e.data.options.target_function(e.data);
                }
                self.interactionManager.removeCurrentInteraction();
              } else if (e.data.options.event_source && e.data.options.event_source === "MERGE_POLYGONS") {
                if (!self.mergeList) {
                  self.mergeList = [];
                }
                let idx = self.mergeList.findIndex(function (obj) {
                  return obj.id === e.data.id;
                });
                if (idx >= 0) {
                  self.mergeList.splice(idx, 1);

                  let features = [];
                  self.tempLayer.getSource().forEachFeature(function (feature) {
                    if (feature.get("id") === e.data.id) {
                      features.push(feature);
                    }
                  });

                  features.forEach(function (feature) {
                    self.tempLayer.getSource().removeFeature(feature);
                  });
                } else {
                  self.mergeList.push({ id: e.data.id, layer_code: e.data.layer_code });
                  self.showTempGeom([e.data.geometry], "", "", e.data.id);
                }
              } else if (e.data.options.event_source && e.data.options.event_source === "AREA_DIVISION") {
                if (e.data.options.on_select) {
                  e.data.options.on_select(e.data);
                }
                self.interactionManager.removeCurrentInteraction();
                return;
              } else {
                self.uiBuilder.constructAndOpenAttributePanel(e.data);
                self.interactionManager.removeCurrentInteraction();
              }

              break;

            case CONSTANTS.INTERACTIONS.PICK_COORDINATE:
              if (e.data.options.event_source && e.data.options.event_source === "EXTERNAL_ACTION_BUTTON") {
                if (e.data.options.target_function) {
                  e.data.options.target_function(e.data.coordinate);
                }
              }
              self.interactionManager.removeCurrentInteraction();
              break;
          }

          if (undoItemGroup.length >= 1) {
            self.undoRedoManager.pushToUndoStack(undoItemGroup);
          }
        } else {
          self.interactionManager.removeCurrentInteraction();
        }
      });

      this.map.on("interaction_progress", function (e) {
        switch (e.data.interaction_code) {
          case CONSTANTS.INTERACTIONS.DRAW_LINE:
            if (e.data.options.layer_code === "_SPLIT_LINE_") {
              if (self.mergeList && self.mergeList.length > 0) {
                // undoItemGroup =[];
                self.clearTempGeom();
                for (let k = 0; k < self.mergeList.length; k++) {
                  self.splitPolygonTemp(self.mergeList[k].id, self._wktFormat.writeGeometry(e.data.geometry));
                }
              }
            }
            break;

          case CONSTANTS.INTERACTIONS.PICK_COORDINATE_MULTI:
            if (e.data.options.event_source && e.data.options.event_source === "EXTERNAL_ACTION_BUTTON") {
              if (e.data.options.target_function) {
                e.data.options.target_function(e.data.coordinate);
              }
            }

            break;
        }
      });

      // let renderCompleted;
      // this.map.on('loadstart', function () {
      //     self.map.getTargetElement().classList.add('spinner');
      // });
      // this.map.on('loadend', function () {
      //     if (renderCompleted){
      //         self.map.getTargetElement().classList.remove('spinner');
      //     }
      // });
      this.map.once("rendercomplete", function () {
        // renderCompleted = true;
        self.map.getTargetElement().classList.remove("spinner");
      });

      // setTimeout(function () {
      //             //Added on 18-01-2022
      //             if (self.options.output_map_extent) {
      //                 self.map.getView().fit(self.options.output_map_extent, self.map.getSize());
      //             }
      //             //
      //         }, 100);
    }

    //key binidings for shorctcut actions
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && (event.key === "y" || event.key === "Y")) {
        self.undoRedoManager.redo();
        event.preventDefault();
      } else if (event.ctrlKey && (event.key === "z" || event.key === "Z")) {
        self.undoRedoManager.undo();
        event.preventDefault();
      } else if (event.key === "Escape") {
        if (self.interactionManager.getCurrentInteraction() === CONSTANTS.INTERACTIONS.DRAW_LINE) {
          self.interactionManager.removeLastPointFromLine();
        }
      } else if (event.key === "F2") {
        if (self.interactionManager.getCurrentInteraction() === CONSTANTS.INTERACTIONS.DRAW_LINE) {
          self.interactionManager.finishLineDrawing();
        }
      }
    });
  }

  createID() {
    return (
      Array(16)
        .fill(0)
        .map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 97))
        .join("") + Date.now().toString(24)
    );
  }

  loadInitialMap() {
    let self = this;
    this.initMaps();
    if (document.getElementById(this.uiBuilder.targetDiv).style.width === "" && document.getElementById(this.uiBuilder.targetDiv).style.height === "") {
      this.uiBuilder.toggleFullscreen();
      // document.getElementById("fullscreenSwitcher").classList.add("DN");
      // if (this.options.initial_polygon && this.options.initial_polygon.length > 0) {
      //     this.map.getView().fit(this.vectorPoints.getSource().getExtent(), this.map.getSize());
      // }
    }
    this.uiBuilder.initLayerMaster();
    if (this.options.layer_data && this.options.layer_data.length > 0) {
      this.addLayerData(this.options.layer_data);
      this.map.getView().fit(this.vectorPoints.getSource().getExtent(), this.map.getSize());
    } else {
      // setTimeout(function () {
      //Added on 18-01-2022
      if (self.options.map_extent) {
        self.map.getView().fit(self.options.map_extent, self.map.getSize());
      }
      //
      // }, 100);
    }

    if (this.options.hide_toolbar === true) {
    } else {
      self.uiBuilder.tollbarPanel.reposition({
        my: "right-bottom",
        at: "right-bottom",
        of: "div.map",
        offsetX: -40,
        offsetY: -5,
      });
    }
    if (self.options.onloaded) {
      self.options.onloaded(self);
    }
  }

  appendLayerMaster(layerMasterEntries) {
    let self = this;
    for (let i = 0; i < layerMasterEntries.length; i++) {
      var x = document.createElement("OPTION");
      x.value = layerMasterEntries[i].layer_code;
      x.innerText = layerMasterEntries[i].layer_name;
      document.getElementById("dropdownListType").appendChild(x);
      this.options.layer_master.push(layerMasterEntries[i]);
    }
  }
  // preDrawData() {
  //     var self = this;
  //     let preFillData = this.options.output.prefilled_data;
  //     if (preFillData && Object.keys(preFillData).length !== 0) {
  //         //prefilPoints
  //         if (preFillData.points && preFillData.points.length > 0) {
  //             let preFilPoints = preFillData.points;
  //             for (let i = 0; i < preFilPoints.length; i++) {
  //                 //this.createPoint(preFilPoints[i].name, preFilPoints[i].geom, Gis.POINTTYPE.CREATED, preFilPoints[i].id, i);

  //                 let ptFeature = new Feature({
  //                     geometry: this._wktFormat.readGeometry(preFilPoints[i].geom),
  //                     name: preFilPoints[i].name,
  //                     category: preFilPoints[i].category,
  //                     id: preFilPoints[i].id,
  //                     layer_type: Gis.LAYERTYPE.POINTS
  //                 });
  //                 this.vectorPoints.getSource().addFeature(ptFeature);
  //                 this.generatedData.points.push({
  //                     "name": preFilPoints[i].name,
  //                     "geom": preFilPoints[i].geom,
  //                     id: preFilPoints[i].id,
  //                     idx: preFilPoints[i].idx,
  //                     "X": preFilPoints[i].X,
  //                     "Y": preFilPoints[i].Y,
  //                     "category": preFilPoints[i].category
  //                 });

  //             }
  //         }
  //         //prefilLines
  //         if (preFillData.lines && preFillData.lines.length > 0) {
  //             let preFilLines = preFillData.lines;
  //             for (let i = 0; i < preFilLines.length; i++) {
  //                 //this.createLine(preFilLines[i].geom, preFilLines[i].category, preFilLines[i].id);

  //                 let lnFeature = new Feature({
  //                     geometry: self._wktFormat.readGeometry(preFilLines[i].geom),
  //                     id: preFilLines[i].id,
  //                     // type: "LN",
  //                     type: preFilLines[i].type,
  //                     name: preFilLines[i].name,
  //                     category: preFilLines[i].type,
  //                     layer_type: Gis.LAYERTYPE.LINES
  //                 });
  //                 self.vectorLines.getSource().addFeature(lnFeature);
  //                 var toPush = {
  //                     "id": preFilLines[i].id,
  //                     "points": preFilLines[i].points,
  //                     "name": preFilLines[i].name,
  //                     "geom": preFilLines[i].geom,
  //                     "details": preFilLines[i].details,
  //                     "type": preFilLines[i].type
  //                 }
  //                 self.generatedData.lines.push(JSON.parse(JSON.stringify(toPush)));
  //                 self.gridData.lines.push(toPush)
  //                 self.gridData.lines[self.gridData.lines.length - 1].actions = '<img title="delete" class="cursorPointer datalineClass" alt="" width="15" src=' + Gis.ServicePath + 'assets/img/trash.svg references="' + preFilLines[i].id + '">';
  //             }
  //         }
  //         //prefilPolygons
  //         if (preFillData.polygons && preFillData.polygons.length > 0) {
  //             let preFilPolygons = preFillData.polygons;
  //             for (let i = 0; i < preFilPolygons.length; i++) {
  //                 var tempName = preFilPolygons[i].name;
  //                 var toPush = {
  //                     "id": preFilPolygons[i].id,
  //                     "name": preFilPolygons[i].name,
  //                     "geom": preFilPolygons[i].geom,
  //                     "area": preFilPolygons[i].area,
  //                     "category": preFilPolygons[i].category,
  //                 }
  //                 // tempName += " ( ";
  //                 // var first = true;;
  //                 // var attr = this.getEditableAttributes(this.initialData.attributes);

  //                 // for (var t = 0; t < attr.length; t++) {
  //                 //     toPush[attr[t].field] = preFilPolygons[i][attr[t].field];
  //                 //     if (first) {
  //                 //         tempName += preFilPolygons[i][attr[t].field];
  //                 //         first = false;
  //                 //     } else {
  //                 //         tempName += " / " + preFilPolygons[i][attr[t].field];
  //                 //     }
  //                 // }
  //                 // tempName += " )";

  //                 let feature = new Feature({
  //                     geometry: this._wktFormat.readGeometry(preFilPolygons[i].geom),
  //                     name: tempName,
  //                     category: preFilPolygons[i].category,
  //                     id: preFilPolygons[i].id,
  //                     layer_type: Gis.LAYERTYPE.POLYGON

  //                 });

  //                 this.generatedData.polygons.push(JSON.parse(JSON.stringify(toPush)));
  //                 this.gridData.polygon.push(toPush);
  //                 this.gridData.polygon[this.gridData.polygon.length - 1].actions = '<img alt="" width="15" title="Delete" class="cursorPointer datapolygonClass" references="' + preFilPolygons[i].id + '" src=' + Gis.ServicePath + 'assets/img/trash.svg>';
  //                 this.vectorPolygon.getSource().addFeature(feature);
  //             }
  //         }
  //         // if (preFillData.linesegments && preFillData.linesegments.length > 1) {
  //         //     let prefillsegments = preFillData.linesegments;
  //         //     for (var i = 0; i < prefillsegments.length; i++) {
  //         //         let lnFeature = new Feature({
  //         //             geometry: this._wktFormat.readGeometry(prefillsegments[i].geom),
  //         //             id: prefillsegments[i].id,
  //         //             name: prefillsegments[i].name,
  //         //             category: "C",
  //         //             layer_type: Gis.LAYERTYPE.SEGMENT,
  //         //             type: prefillsegments[i].type,
  //         //             length: prefillsegments[i].length
  //         //         });
  //         //         var toPush = {
  //         //             "id": prefillsegments[i].id,
  //         //             "parentNodes": prefillsegments[i].parentNodes,
  //         //             "parentid": prefillsegments[i].parentid,
  //         //             "name": prefillsegments[i].name,
  //         //             "node": prefillsegments[i].node,
  //         //             "length": prefillsegments[i].length,
  //         //             "coordinates": prefillsegments[i].coordinates,
  //         //             "geom": prefillsegments[i].geom
  //         //         }
  //         //         self.generatedData.linesegments.push(JSON.parse(JSON.stringify(toPush)));
  //         //         self.gridData.segment.push(toPush)
  //         //         self.gridData.segment[self.gridData.segment.length - 1].actions = '<img class="cursorPointer datasegmentClass" title="Delete" alt="" width="15" references="' + prefillsegments[i].id + '" src=' + Gis.ServicePath + 'assets/img/trash.svg>';
  //         //         self.gridData.segment[self.gridData.segment.length - 1].details = "Initial";
  //         //         // self.vectorLineSegments.getSource().addFeature(lnFeature);
  //         //     }
  //         // }

  //         if (preFillData.generatedLayers && preFillData.generatedLayers.length > 0) {
  //             for (const gl of preFillData.generatedLayers) {
  //                 self.createCustomGeom(gl, gl.id);
  //             }
  //         }
  //         setTimeout(function () {
  //             try {
  //                 self.map.getView().fit(self.vectorPoints.getSource().getExtent(), self.map.getSize());
  //             }
  //             catch (err) {
  //                 console.log(err);
  //             }
  //         }, 1000);
  //         self.refreshMappingListGrid();
  //     }
  //     setTimeout(function () {
  //         //Added on 18-01-2022
  //         if (self.options.output_map_extent) {
  //             self.map.getView().fit(self.options.output_map_extent, self.map.getSize());
  //         }
  //         //
  //     }, 100);
  // }

  createLayer(layerDef) {
    let self = this;
    let wmsLayer = null;
    if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.OSM) {
      wmsLayer = new TileLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.OSM,
        visible: layerDef.visible,
        source: new OSM(),
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.ARCGIS) {
      wmsLayer = new TileLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.ARCGIS,
        source: new TileArcGISRest(
          layerDef.source_parms
          // {
          // url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
          // attributions: [new ol.Attribution({
          //     html: 'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community  © <a href="http://www.arcgis.com" target="_blank">ArcGIS</a>'
          // })]

          //  }
        ),
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BHUVAN) {
      let srcParams = layerDef.source_parms;
      srcParams.attributions = [new Attribution(layerDef.source_parms.attributions)];
      wmsLayer = new TileLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.BHUVAN,
        visible: layerDef.visible,
        preload: 0,
        source: new TileWMS(srcParams),
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BING) {
      wmsLayer = new TileLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.BING,
        visible: layerDef.visible,
        preload: 0,
        source: new BingMaps(layerDef.source_parms),
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_IMAGE) {
      wmsLayer = new ImageLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_IMAGE,
        source: new ImageWMS(layerDef.source_parms),
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_VECTOR) {
      wmsLayer = new ImageLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_VECTOR,
        source: new ImageWMS(layerDef.source_parms),
        visible: layerDef.source_parms.visible,
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.TILE_LAYER) {
      let srcParams = layerDef.source_parms;
      let layerParms = {};
      if (layerDef.layerParms) {
        layerParms = layerDef.layerParms;
      }
      layerParms.source = new XYZ(srcParams);
      layerParms.layer_type = CONSTANTS.REFERENCE_MAP_TYPE.TILE_LAYER;
      (layerParms.id = layerDef.layer_id), (layerParms.name = layerDef.name ? layerDef.name : layerDef.layer_id), (wmsLayer = new TileLayer(layerParms));
      // } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_TILE) {
      //     let srcParams = layerDef.source_parms;
      //     //srcParams.format = new MVT();
      //     let layerParms = {};
      //     if (layerDef.layerParms) {
      //         layerParms = layerDef.layerParms;
      //     }
      //     layerParms.source = new VectorTileSource(srcParams);
      //     layerParms.layer_type = CONSTANTS.LAYERTYPE.BASE_MAP;
      //     layerParms.name=layerDef.layer_id;
      //     layerParms.style = self.styleBuilder.createSimpleStyle();
      //     wmsLayer = new VectorTileLayer(layerParms);
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.IMAGE_LAYER) {
      let srcParams = layerDef.source_parms;
      let layerParms = {};
      if (layerDef.layerParms) {
        layerParms = layerDef.layerParms;
      }
      (layerParms.name = layerDef.layer_id), (layerParms.layer_type = CONSTANTS.REFERENCE_MAP_TYPE.IMAGE_LAYER);
      (layerParms.id = layerDef.layer_id), (layerParms.name = layerDef.name ? layerDef.name : layerDef.layer_id), (layerParms.source = new ImageWMS(srcParams));
      layerParms.visible = layerDef.source_parms.visible;

      wmsLayer = new ImageLayer(layerParms);
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GML) {
      layerDef.source_parms.format = new GMLFormat();
      let style = this.styleBuilder.createSimpleStyle();
      if (layerDef.style) {
        if (layerDef.style.geom_type?.toUpperCase() === "POLYGON" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOLYGON") {
          style = function (feature, resolution) {
            return self.styleBuilder.createPolygonAreaStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        } else if (layerDef.style.geom_type?.toUpperCase() === "LINESTRING" || layerDef.style.geom_type?.toUpperCase() === "MULTILINESTRING") {
          style = function (feature, resolution) {
            return self.styleBuilder.createLineStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        }
        // else if (layerDef.style.geom_type?.toUpperCase() === "POINT" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOINT"){
        //     style = function (feature, resolution) {

        //         self.styleBuilder.createSimpleStyle(feature, feature.getProperties(), resolution, layerDef.style.label, { ...layerDef.style.style_def });
        //     };
        // }
      }
      if (layerDef?.sld_style && layerDef?.sld_style !== "") {
        const sld = SLDReader.Reader(layerDef.sld_style);
        const lyr = SLDReader.getLayer(sld);
        const sldStyle = SLDReader.getStyle(lyr);
        const featureTypeStyle = sldStyle.featuretypestyles[0];

        style = SLDReader.createOlStyleFunction(featureTypeStyle, {
          imageLoadedCallback: () => {
            self.vectorPolygon.changed();
          },
        });
      }
      wmsLayer = new VectorLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GML,
        source: new VectorSource(layerDef.source_parms),
        style: style,
        visible: layerDef.source_parms.visible,
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GEOJSON) {
      layerDef.source_parms.format = new GeoJSON();
      let style = this.styleBuilder.createSimpleStyle();
      if (layerDef.style) {
        if (layerDef.style.geom_type?.toUpperCase() === "POLYGON" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOLYGON") {
          style = function (feature, resolution) {
            return self.styleBuilder.createPolygonAreaStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        } else if (layerDef.style.geom_type?.toUpperCase() === "LINESTRING" || layerDef.style.geom_type?.toUpperCase() === "MULTILINESTRING") {
          style = function (feature, resolution) {
            return self.styleBuilder.createLineStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        }
        // else if (layerDef.style.geom_type?.toUpperCase() === "POINT" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOINT"){
        //     style = function (feature, resolution) {

        //         self.styleBuilder.createSimpleStyle(feature, feature.getProperties(), resolution, layerDef.style.label, { ...layerDef.style.style_def });
        //     };
        // }
      }
      if (layerDef?.sld_style && layerDef?.sld_style !== "") {
        const sld = SLDReader.Reader(layerDef.sld_style);
        const lyr = SLDReader.getLayer(sld);
        const sldStyle = SLDReader.getStyle(lyr);
        const featureTypeStyle = sldStyle.featuretypestyles[0];

        style = SLDReader.createOlStyleFunction(featureTypeStyle, {
          imageLoadedCallback: () => {
            self.vectorPolygon.changed();
          },
        });
      }
      wmsLayer = new VectorImageLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GEOJSON,
        source: new VectorSource({
          loader: async function () {
            // Fetch the flatgeobuffer
            const response = await fetch(layerDef.source_parms.url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(layerDef.source_parms.input),
            });
            // ...and parse all its features

            let length = Number(response.headers.get("content-length"));
            if (response.status === 200 && !isNaN(length) && length > 0) {
              const jsonData = await response.json();
              const f = layerDef.source_parms.format.readFeatures(jsonData);
              this.addFeatures(f);
            }
            if (layerDef?.callback) {
              layerDef.callback();
            }
          },
        }),
        style: style,
        visible: layerDef.source_parms.visible,
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_FGB) {
      layerDef.source_parms.format = new GeoJSON();
      let style = this.styleBuilder.createSimpleStyle();
      if (layerDef.style) {
        if (layerDef.style.geom_type?.toUpperCase() === "POLYGON" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOLYGON") {
          style = function (feature, resolution) {
            return self.styleBuilder.createPolygonAreaStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        } else if (layerDef.style.geom_type?.toUpperCase() === "LINESTRING" || layerDef.style.geom_type?.toUpperCase() === "MULTILINESTRING") {
          style = function (feature, resolution) {
            return self.styleBuilder.createLineStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        }
        // else if (layerDef.style.geom_type?.toUpperCase() === "POINT" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOINT"){
        //     style = function (feature, resolution) {

        //         self.styleBuilder.createSimpleStyle(feature, feature.getProperties(), resolution, layerDef.style.label, { ...layerDef.style.style_def });
        //     };
        // }
      }
      if (layerDef?.sld_style && layerDef?.sld_style !== "") {
        const sld = SLDReader.Reader(layerDef.sld_style);
        const lyr = SLDReader.getLayer(sld);
        const sldStyle = SLDReader.getStyle(lyr);
        const featureTypeStyle = sldStyle.featuretypestyles[0];

        style = SLDReader.createOlStyleFunction(featureTypeStyle, {
          imageLoadedCallback: () => {
            self.vectorPolygon.changed();
          },
        });
      }
      wmsLayer = new VectorImageLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_FGB,
        source: new VectorSource({
          loader: async function () {
            let serviceUrl = layerDef.source_parms.url;
            if (layerDef.source_parms.auth_key) {
              serviceUrl += "?auth_key=" + layerDef.source_parms.auth_key;
            }
            // Fetch the flatgeobuffer
            const response = await fetch(serviceUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(layerDef.source_parms.input),
            });
            // ...and parse all its features

            let length = Number(response.headers.get("content-length"));
            if (response.status === 200 && !isNaN(length) && length > 0) {
              for await (let feature of flatgeobuf.deserialize(response.body)) {
                //feature.getGeometry().transform('EPSG:32643', 'EPSG:32643')
                // add each feature to the map, after projecting it to
                //console.log(feature);
                //
                let f = layerDef.source_parms.format.readFeature(feature);
                f.setId(self.createID());
                this.addFeature(f);
              }

              if (layerDef?.focus === true) {
                self.getMap().getView().fit(wmsLayer.getSource().getExtent());
              }
            }
            if (layerDef?.callback) {
              layerDef.callback();
            }
          },
        }),
        style: style,
        visible: layerDef.source_parms.visible,
      });
    } else if (layerDef.map_type === CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_DATA) {
      let style = this.styleBuilder.createSimpleStyle();
      if (layerDef.style) {
        if (layerDef.style.geom_type?.toUpperCase() === "POLYGON" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOLYGON") {
          style = function (feature, resolution) {
            return self.styleBuilder.createPolygonAreaStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        } else if (layerDef.style.geom_type?.toUpperCase() === "LINESTRING" || layerDef.style.geom_type?.toUpperCase() === "MULTILINESTRING") {
          style = function (feature, resolution) {
            return self.styleBuilder.createLineStyle(feature, { attrs: feature.getProperties() }, resolution, layerDef.style.label, {
              ...layerDef.style.style_def,
            });
          };
        }
        // else if (layerDef.style.geom_type?.toUpperCase() === "POINT" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOINT"){
        //     style = function (feature, resolution) {

        //         self.styleBuilder.createSimpleStyle(feature, feature.getProperties(), resolution, layerDef.style.label, { ...layerDef.style.style_def });
        //     };
        // }
      }
      if (layerDef?.sld_style && layerDef?.sld_style !== "") {
        const sld = SLDReader.Reader(layerDef.sld_style);
        const lyr = SLDReader.getLayer(sld);
        const sldStyle = SLDReader.getStyle(lyr);
        const featureTypeStyle = sldStyle.featuretypestyles[0];

        style = SLDReader.createOlStyleFunction(featureTypeStyle, {
          imageLoadedCallback: () => {
            self.vectorPolygon.changed();
          },
        });
      }
      wmsLayer = new VectorImageLayer({
        id: layerDef.layer_id,
        name: layerDef.name ? layerDef.name : layerDef.layer_id,
        layer_type: CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_DATA,
        source: layerDef.source_parms.vector_source,
        style: style,
        visible: layerDef.source_parms.visible,
      });

      if (layerDef?.focus === true) {
        self.getMap().getView().fit(wmsLayer.getSource().getExtent());
      }
    }
    return wmsLayer;
  }

  addLayer(layerDef) {
    let wmsLayer = this.createLayer(layerDef);
    this.map.addLayer(wmsLayer);
  }

  setCenter(lon, lat) {
    this.getMap()
      .getView()
      .setCenter(transform([lon, lat], "EPSG:4326", this.options.output_proj));
  }

  getMap() {
    return this.map;
  }

  clearTempGeom() {
    this.tempLayer.getSource().clear();
  }

  showArcPoint(_parentid, inputId, _callback) {
    var self = this;

    let idx1 = document.getElementById(_parentid).querySelector(".point_combo1").value;
    let idx2 = document.getElementById(_parentid).querySelector(".point_combo2").value;
    // if (idx1 !== idx2) {
    let pt1 = self._jstsReader.read(self.generatedData.points[Number(idx1)].geom);
    let pt2 = self._jstsReader.read(self.generatedData.points[Number(idx2)].geom);
    let d1, d2;

    self.tempLayer.getSource().clear();
    if (document.getElementById(_parentid).querySelector(".radius_input1").value && pt1) {
      d1 = Number(document.getElementById(_parentid).querySelector(".radius_input1").value) * this.options.scaleFactor;
      var featureC = new Feature({
        geometry: new CircleGeom([pt1.getCoordinate().x, pt1.getCoordinate().y], d1),
        name: "",
        type: "Cr",
      });
      self.tempLayer.getSource().addFeature(featureC);
    }

    if (document.getElementById(_parentid).querySelector(".radius_input2").value && pt2) {
      d2 = Number(document.getElementById(_parentid).querySelector(".radius_input2").value) * this.options.scaleFactor;
      var featureC = new Feature({
        geometry: new CircleGeom([pt2.getCoordinate().x, pt2.getCoordinate().y], d2),
        name: "",
        type: "Cr",
      });
      self.tempLayer.getSource().addFeature(featureC);
    }

    if (d1 && d2) {
      try {
        if (inputId !== "intersectpoint") {
          let cords = circleIntersection(pt1.getCoordinate().x, pt1.getCoordinate().y, d1, pt2.getCoordinate().x, pt2.getCoordinate().y, d2);
          let jp1 = self._jstsReader.read("POINT (" + cords[0] + " " + cords[2] + ")");
          let jp2 = self._jstsReader.read("POINT (" + cords[1] + " " + cords[3] + ")");
          var a1, b1, ring1;
          // if (self.options.initial_polygon.length > 0) {
          //     for (var er = 0; er < self.options.initial_polygon.length; er++) {
          //         a1 = self._jstsReader.read(self.options.initial_polygon[er]);
          //         b1 = a1;
          //         if (er === 0) {
          //             ring1 = b1;
          //         }
          //         ring1 = ring1.union(b1);
          //     }
          // }
          // let basePoly = ring1;//self._jstsReader.read(self.initialData.initial_polygon[2]);
          let ptCnt = 1;
          var interSections = document.getElementById(_parentid).querySelector(".intersection_combo");
          while (interSections.firstChild) {
            interSections.removeChild(interSections.firstChild);
          }
          // if (basePoly.intersects(jp1)) {
          var optionChild = document.createElement("option");
          optionChild.value = self._jstsWriter.write(jp1);
          optionChild.innerHTML = ptCnt;
          document.getElementById(_parentid).querySelector(".intersection_combo").appendChild(optionChild);
          ptCnt++;
          // }
          // if (basePoly.intersects(jp2)) {
          var optionChild = document.createElement("option");
          optionChild.value = self._jstsWriter.write(jp2);
          optionChild.innerHTML = ptCnt;
          document.getElementById(_parentid).querySelector(".intersection_combo").appendChild(optionChild);
          // }
        }
        let selP = self._jstsReader.read(document.getElementById(_parentid).querySelector(".intersection_combo").value);
        if (selP) {
          var featureC = new Feature({
            geometry: new Point([selP.getX(), selP.getY()]),
            name: document.getElementById(_parentid).querySelector(".point_name").value,
            category: "A",
            line: -1,
          });
          self.tempLayer.getSource().addFeature(featureC);

          if (_callback) {
            _callback([selP.getX(), selP.getY()]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    // }
  }

  addPointsFromTemp(category) {
    let features = this.tempLayer.getSource().getFeatures();
    let undoGroupItem = [];
    for (var i = 0; i < features.length; i++) {
      if (features[i].get("category") === category) {
        let ptName = features[i].get("name");
        let wktPt = this._wktFormat.writeGeometry(features[i].getGeometry());
        let pointData = { name: ptName, geom: wktPt, idx: i };
        this.addPoint("", ptName, features[i].getGeometry().getCoordinates(), undoGroupItem);

        break;
      }
    }
    if (undoGroupItem.length > 0) {
      this.undoRedoManager.pushToUndoStack(undoGroupItem);
    }
    this.clearTempGeom();
  }
  toggleVisibleInLayer(layerid, visible) {
    let tvLayer = this.map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("id") == layerid);
    tvLayer.setVisible(visible);
  }

  setStyleInLayer(layerid, styleDef) {
    let layer = this.map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("id") == layerid);
    let customStyle = this.styleBuilder.createCustomStyle(styleDef);
    layer.setStyle(customStyle);
  }

  setStyleInFeature(ft, styleDef) {
    let customStyle = this.styleBuilder.createCustomStyle(styleDef);
    ft.setStyle(customStyle);
  }

  generatePointsData(coodinates) {
    let pointList = [];
    for (let i = 0; i < coodinates.length; i++) {
      let fts = this.vectorPoints.getSource().getFeaturesAtCoordinate(coodinates[i]);

      if (fts) {
        pointList.push({ id: fts[0].get("id"), coordinate: coodinates[i], name: fts[0].get("name") });
      }
    }

    return pointList;
  }

  cutPointAtDistance(startCoord, endCoord, ptDist, forward = true) {
    var accDistance = 0;
    var c;
    var p1, p2;

    var pt1 = new Point(startCoord);
    var pt2 = new Point(endCoord);
    //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));

    if (forward) {
      p1 = this._jstsReader.read(this._wktFormat.writeGeometry(pt1));
      p2 = this._jstsReader.read(this._wktFormat.writeGeometry(pt2));
    } else {
      p1 = this._jstsReader.read(this._wktFormat.writeGeometry(pt2));
      p2 = this._jstsReader.read(this._wktFormat.writeGeometry(pt1));
    }

    accDistance = DistanceOp.distance(p1, p2); // p1.distance(p2);
    // if (accDistance + p1.distance(p2) < ptDist) {
    //     accDistance += p1.distance(p2);
    //     p1 = p2;
    // } else
    if (accDistance <= ptDist) {
      c = p2;
    } else {
      var r1 = ptDist; //(ptDist - accDistance);
      var r2 = DistanceOp.distance(p1, p2) - r1;

      var x1 = (r1 * p2.getX() + r2 * p1.getX()) / (r1 + r2);
      var y1 = (r1 * p2.getY() + r2 * p1.getY()) / (r1 + r2);
      c = [x1, y1];
    }

    return c;
  }

  showCutPoint(startCoord, endCoord, midPoint, name) {
    let self = this;
    self.tempLayer.getSource().clear();

    var l1 = new LineString([startCoord, midPoint]);
    var l2 = new LineString([midPoint, endCoord]);
    var m = new Point(midPoint);
    var s1 = new Feature(l1);

    s1.set("label", l1.getLength().toFixed(2));
    this.tempLayer.getSource().addFeature(s1);

    var s2 = new Feature(l2);

    s2.set("label", l2.getLength().toFixed(2));
    this.tempLayer.getSource().addFeature(s2);

    var s3 = new Feature(m);
    s3.set("label", name);
    s3.set("name", name);

    this.tempLayer.getSource().addFeature(s3);
  }
  // clearDrawLineTool() {
  //     if (this.interactionManager.currentInteraction === CONSTANTS.INTERACTIONS.DRAW_LINE) {
  //         this.interactionManager.removeCurrentInteraction();
  //     }
  // }

  createGrid(x, y, d) {
    if (!d) {
      d = this.uiBuilder.gridSpaceing;
    }
    if (this.graticlueLayer && this.graticlueLayer !== null) {
      this.map.removeLayer(this.graticlueLayer);
      this.graticlueLayer = null;
    }
    this.graticlueLayer = new VectorLayer({
      name: "graticlueLayer",
      source: new VectorSource({
        features: [],
      }),
      style: function (feature, resolution) {
        return new Style({
          stroke: new Stroke({
            width: 1,
            color: [145, 45, 208, 0.8], //237, 212, 0, 0.8
          }),
        });
      },
    });
    this.map.addLayer(this.graticlueLayer);

    let extent = this.vectorPoints.getSource().getExtent();
    extent[0] = extent[0] - 40;
    extent[1] = extent[1] - 40;
    extent[2] = extent[2] + 40;
    extent[3] = extent[3] + 40;

    let x1 = x;

    while (x1 >= extent[0]) {
      let c = [];
      c.push([x1, extent[1]]);
      c.push([x1, extent[3]]);
      x1 -= d;
      let lineGeom = new LineString(c);

      let lnFeature = new Feature({
        geometry: lineGeom,
      });

      this.graticlueLayer.getSource().addFeature(lnFeature);
    }

    x1 = x;
    while (x1 <= extent[2]) {
      let c = [];
      c.push([x1, extent[1]]);
      c.push([x1, extent[3]]);
      x1 += d;
      let lineGeom = new LineString(c);

      let lnFeature = new Feature({
        geometry: lineGeom,
      });

      this.graticlueLayer.getSource().addFeature(lnFeature);
    }

    let y1 = y;

    while (y1 >= extent[1]) {
      let c = [];
      c.push([extent[0], y1]);
      c.push([extent[2], y1]);
      y1 -= d;
      let lineGeom = new LineString(c);

      let lnFeature = new Feature({
        geometry: lineGeom,
      });

      this.graticlueLayer.getSource().addFeature(lnFeature);
    }

    y1 = y;

    while (y1 <= extent[3]) {
      let c = [];
      c.push([extent[0], y1]);
      c.push([extent[2], y1]);
      y1 += d;
      let lineGeom = new LineString(c);

      let lnFeature = new Feature({
        geometry: lineGeom,
      });

      this.graticlueLayer.getSource().addFeature(lnFeature);
    }

    this.currentGridLoc = [x, y];
    this.isgraticuleshown = true;
  }

  rotateGridLeft() {
    let self = this;

    var angle = Number(self.angleValue) * (Math.PI / 180);
    self.rotateFeatures(self.graticlueLayer, angle, self.currentGridLoc[0], self.currentGridLoc[1]);
  }

  rotateGridRight() {
    let self = this;

    var angle = Number(self.angleValue) * (Math.PI / 180);
    self.rotateFeatures(self.graticlueLayer, angle * -1, self.currentGridLoc[0], self.currentGridLoc[1]);
  }

  removeGrid() {
    let self = this;
    self.map.removeLayer(self.graticlueLayer);
    if (self.interactionManager.currentInteraction === CONSTANTS.INTERACTIONS.PLACE_GRID) {
      self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.POINTER);
    }
  }

  rotateFeatures(layer, angle, x, y) {
    let features = layer.getSource().getFeatures();
    features.forEach(function (feature) {
      feature.getGeometry().rotate(angle, [x, y]);
    });
  }

  createFeatureFromWKT(wktGeometry, attr, id) {
    let feature = new Feature({
      geometry: this._wktFormat.readGeometry(wktGeometry),
    });
    feature.setProperties(attr);

    if (id) feature.setId(id);
    return feature;
  }

  addLayerData(layerDataArray, undoable = false) {
    let undoItemGroup = [];

    for (let i = 0; i < layerDataArray.length; i++) {
      if (layerDataArray[i].geom && layerDataArray[i].layer_code) {
        let layerMasterData = this.getLayerMasterDataFromCode(layerDataArray[i].layer_code);
        if (layerMasterData) {
          if (
            layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.POLYGON.toUpperCase() ||
            layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.MULTI_POLYGON.toUpperCase()
          ) {
            this.addPolygonObject(layerDataArray[i], undoItemGroup);
          } else if (
            layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.LINE_STRING.toUpperCase() ||
            layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.MULTI_LINE_STRING.toUpperCase()
          ) {
            this.addLineObject(layerDataArray[i], undoItemGroup);
          } else if (
            layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.POINT.toUpperCase() ||
            layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.MULTI_POINT.toUpperCase()
          ) {
            this.addPointObject(layerDataArray[i], undoItemGroup);
          }
        }
      }
    }

    if (undoable) {
      this.undoRedoManager.pushToUndoStack(undoItemGroup);
    }
  }

  clearLayerData(skipLayercode = "") {
    for (let i = this.generatedData.polygons.length - 1; i >= 0; i--) {
      if (this.generatedData.polygons[i].layer_code && this.generatedData.polygons[i].layer_code === skipLayercode) {
        continue;
      }
      this.deletePolygonByID(this.generatedData.polygons[i].id);
    }
    for (let i = this.generatedData.lines.length - 1; i >= 0; i--) {
      if (this.generatedData.lines[i].layer_code && this.generatedData.lines[i].layer_code === skipLayercode) {
        continue;
      }
      this.deleteLineByID(this.generatedData.lines[i].id);
    }
    for (let i = this.generatedData.points.length - 1; i >= 0; i--) {
      if (this.generatedData.points[i].layer_code && this.generatedData.points[i].layer_code === skipLayercode) {
        continue;
      }
      this.deletePointByID(this.generatedData.points[i].id);
    }
    this.undoRedoManager.clearStack();
  }

  //ADD RAW DATA

  addRawData(object, data, lockEdit) {
    switch (object) {
      case CONSTANTS.DATA_OBJECTS.POINT: {
        let index = this.generatedData.points.push(data);
        let geometry = this._wktFormat.readGeometry(data.geom);

        // let ptFeature = new Feature({
        //   geometry: geometry,
        //   name: data.name,
        //   //category: data.type,
        //   id: data.id,
        //   // data_index: (index - 1),
        //   layer_code: data.layer_code,
        //   lock_edit: lockEdit,
        // });

        let attr1 = {
          geometry: geometry,
          name: data.name,
          //category: data.type,
          id: data.id,
          // data_index: (index - 1),
          layer_code: data.layer_code,
          lock_edit: lockEdit,
        };
        let attr = { ...data.attrs, ...attr1 };
        let ptFeature = new Feature(attr);

        ptFeature.setId(data.id);

        this.vectorPoints.getSource().addFeature(ptFeature);
        break;
      }

      case CONSTANTS.DATA_OBJECTS.LINE: {
        let index = this.generatedData.lines.push(data);
        let geometry = this._wktFormat.readGeometry(data.geom);

        // let lnFeature = new Feature({
        //   geometry: geometry,
        //   id: data.id,
        //   name: data.name,
        //   //category: data.type,
        //   // data_index: (index - 1),
        //   layer_code: data.layer_code,
        // });

        let attr1 = {
          geometry: geometry,
          id: data.id,
          name: data.name,
          //category: data.type,
          // data_index: (index - 1),
          layer_code: data.layer_code,
        };
        let attr = { ...data.attrs, ...attr1 };

        let lnFeature = new Feature(attr);

        lnFeature.setId(data.id);

        this.vectorLines.getSource().addFeature(lnFeature);
        break;
      }

      case CONSTANTS.DATA_OBJECTS.POLYGON: {
        let geometry = this._wktFormat.readGeometry(data.geom);

        let index = this.generatedData.polygons.push(data);

        // let polyFeature = new Feature({
        //   geometry: geometry,
        //   id: data.id,
        //   name: data.name,
        //   //category: data.type,
        //   // data_index: (index - 1),
        //   layer_code: data.layer_code,
        // });
        let attr1 = {
          geometry: geometry,
          id: data.id,
          name: data.name,
          //category: data.type,
          // data_index: (index - 1),
          layer_code: data.layer_code,
        };
        let attr = { ...data.attrs, ...attr1 };
        let polyFeature = new Feature(attr);
        polyFeature.setId(data.id);
        this.vectorPolygon.getSource().addFeature(polyFeature);

        break;
      }

      // case CONSTANTS.DATA_OBJECTS.LAYER:

      //     {
      //         let geometry = this._wktFormat.readGeometry(data.geom);

      //         let index = this.generatedData.layers.push(data);

      //         let feature = new Feature({
      //             geometry: geometry,
      //             id: data.id,
      //             layer_code: data.layer_code,
      //             data_index: (index - 1),
      //             geom_type: data.geom_type,
      //             layer_type: CONSTANTS.LAYERTYPE.CUSTOM
      //         });
      //         feature.setId(data.id);
      //         this.customLayer.getSource().addFeature(feature);

      //         break;
      //     }
    }
  }

  deleteRawData(object, id) {
    switch (object) {
      case CONSTANTS.DATA_OBJECTS.POINT: {
        let event = this.getDataById("points", id);
        if (event.index >= 0) {
          this.generatedData.points.splice(event.index, 1);
          let ft = this.vectorPoints.getSource().getFeatureById(event.data.id);
          if (ft) {
            this.vectorPoints.getSource().removeFeature(ft);
          }
        }
        return event;
      }

      case CONSTANTS.DATA_OBJECTS.LINE: {
        let event = this.getDataById("lines", id);
        if (event.index >= 0) {
          this.generatedData.lines.splice(event.index, 1);
          let ft = this.vectorLines.getSource().getFeatureById(event.data.id);
          if (ft) {
            this.vectorLines.getSource().removeFeature(ft);
          }
        }
        return event;
      }

      case CONSTANTS.DATA_OBJECTS.POLYGON: {
        let event = this.getDataById("polygons", id);
        if (event.index >= 0) {
          this.generatedData.polygons.splice(event.index, 1);
          let ft = this.vectorPolygon.getSource().getFeatureById(event.data.id);
          if (ft) {
            this.vectorPolygon.getSource().removeFeature(ft);
          }
        }
        return event;
      }
    }
  }

  editRawData(object, data_current, data_new) {
    let event = this.deleteRawData(object, data_current.id);
    if (event.index >= 0) {
      this.addRawData(object, data_new);
      return true;
    }
    return false;
  }

  editAttributes(object, id, attrs, undoItemGroup) {
    let self = this;
    let event = this.getDataById(object, id);

    if (event.index >= 0) {
      let dataNew = { ...event.data };
      dataNew.attrs = attrs;

      if (self.editRawData(object, event.data, dataNew)) {
        if (undoItemGroup) {
          undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: object, DATA: dataNew, DATA_OLD: event.data }]);
        }
      }
    }
  }

  //POINT FUNCTIONS //

  addPoint(id, ptName, coordinate, undoItemGroup, lockEdit, layer_code = "") {
    if (coordinate && coordinate.length > 1 && !isNaN(coordinate[0]) && !isNaN(coordinate[1])) {
      // if (Object.values(CONSTANTS.POINTTYPE).includes(pointType)) {
      if (!ptName) {
        ptName = "P" + (this.generatedData.points.length + 1);
      }
      if (isStringEmpty(id)) {
        id = this.createID();
      }
      var pt = new Point([coordinate[0], coordinate[1]]);
      //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));
      let wktPt = this._wktFormat.writeGeometry(pt);
      let pointData = { id: id, name: ptName, geom: wktPt };
      if (layer_code !== "") {
        pointData.layer_code = layer_code;
      }
      return this.addPointObject(pointData, undoItemGroup, lockEdit);

      // }
    }
  }

  addPointWkt(id, ptName, wktPoint, undoItemGroup) {
    // if (Object.values(CONSTANTS.POINTTYPE).includes(pointType)) {
    if (!ptName) {
      ptName = "P" + (this.generatedData.points.length + 1);
    }
    if (isStringEmpty(id)) {
      id = this.createID();
    }
    // var pt = new Point([coordinates[0], coordinates[1]]);
    // //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));
    // let wktPt = self._wktFormat.writeGeometry(pt);
    let pointData = { id: id, name: ptName, geom: wktPoint };
    return this.addPointObject(pointData, undoItemGroup);

    // }
  }

  addPointObject(pointData, undoItemGroup, lockEdit) {
    let self = this;
    let geometry = this._wktFormat.readGeometry(pointData.geom);
    let c = geometry.getCoordinates();
    let pointExists = false;
    var layerMasterData;

    if (!pointData.layer_code) {
      let selectedLayerCode = this.uiBuilder.getSelectedLayerCode();
      if (!selectedLayerCode) {
        pointData.layer_code = CONSTANTS.PREDEFINED_LAYERS.DEFAULT_POINT;
      }
      layerMasterData = this.getLayerMasterDataFromCode(selectedLayerCode);
      if (
        !layerMasterData ||
        !(
          layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.POINT.toUpperCase() ||
          layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.MULTI_POINT.toUpperCase()
        )
      ) {
        pointData.layer_code = CONSTANTS.PREDEFINED_LAYERS.DEFAULT_POINT;
      } else {
        pointData.layer_code = selectedLayerCode;
      }
    } else {
      layerMasterData = this.getLayerMasterDataFromCode(pointData.layer_code);
    }
    if ("attrs" in layerMasterData && !("attrs" in pointData)) {
      pointData["attrs"] = {};
      for (let i = 0; i < layerMasterData.attrs.length; i++) {
        pointData.attrs[layerMasterData.attrs[i].key] = "";
      }
    }

    self.vectorPoints.getSource().forEachFeatureInExtent(geometry.getExtent(), function (feature) {
      let coordinates = feature.getGeometry().getCoordinates();
      //for (let i = 0; i < coordinates.length; i++) {
      //let c = coordinates[i];

      if (arrayEquals(c, coordinates)) {
        pointExists = true;
      }

      // }
    });

    if (!pointExists) {
      // this.generatedData.points.push(pointData);

      // let ptFeature = new Feature({
      //     geometry: geometry,
      //     name: pointData.name,
      //     category: pointData.type,
      //     id: pointData.id,
      //     layer_type: CONSTANTS.LAYERTYPE.POINTS
      // });

      // ptFeature.setId(pointData.id);

      // this.vectorPoints.getSource().addFeature(ptFeature);

      let jstsGeometry = this._jstsReader.read(pointData.geom);
      jstsGeometry = jstsGeometry.getGeometryN(0);
      pointData.geom = this._jstsWriter.write(jstsGeometry);

      this.addRawData(CONSTANTS.DATA_OBJECTS.POINT, pointData, lockEdit);

      if (undoItemGroup) {
        undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.A, OBJECT: CONSTANTS.DATA_OBJECTS.POINT, DATA: pointData }]);
      }
      return pointData;
    } else {
      return false;
    }
  }

  addPointObjectArray(pointsData, undoItemGroup) {
    for (let i = 0; i < pointsData.length; i++) {
      this.addPointObject(pointsData[i], undoItemGroup);
    }

    // this.generatedData.points.push(...pointsData);

    // let features = [];
    // let ids = [];
    // for (let i = 0; i < pointsData.length; i++) {
    //     console.log(pointsData[i]);
    //     let ptFeature = new Feature({
    //         geometry: this._wktFormat.readGeometry(pointsData[i].geom),
    //         name: pointsData[i].name,
    //         category: pointsData[i].type,
    //         id: pointsData[i].id,
    //         layer_type: CONSTANTS.LAYERTYPE.POINTS
    //     });
    //     ptFeature.setId(pointsData[i].id);
    //     features.push(ptFeature);
    //     ids.push(pointsData[i].id);
    // }
    // this.vectorPoints.getSource().addFeatures(features);

    // if (undoable) {
    //     this.undoStack.push({ ACTION: CONSTANTS.UNDO_ACTIONS.DELETE_POINT_BY_ID, DATA: ids });
    // }
    this.map.getView().fit(this.vectorPoints.getSource().getExtent(), this.map.getSize());
  }

  deletePointByID(id, undoItemGroup) {
    let event = this.deleteRawData(CONSTANTS.DATA_OBJECTS.POINT, id);
    if (event.index >= 0) {
      if (undoItemGroup) {
        undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.D, OBJECT: CONSTANTS.DATA_OBJECTS.POINT, DATA: event.data }]);
      }
    }
  }

  changePointCoordinate(oldCoordinate, newCoordinate, undoItemGroup) {
    var self = this;
    let olPt = new Point(oldCoordinate);
    let wktPoint = self._wktFormat.writeGeometry(olPt);
    let jtsPt = self._jstsReader.read(wktPoint);
    let bufferedGeom = BufferOp.bufferOp(jtsPt, 0.1); //jtsPt.buffer(0.1);
    let olExtent = self._wktFormat.readGeometry(self._jstsWriter.write(bufferedGeom)).getExtent();

    // let anyEditDone = false;
    self.vectorPolygon.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      for (let i = 0; i < coordinates.length; i++) {
        for (let j = 0; j < coordinates[i].length; j++) {
          let c = coordinates[i][j];
          if (arrayEquals(c, oldCoordinate)) {
            edited = true;
            coordinates[i][j] = newCoordinate;
          }
        }
      }
      if (edited) {
        let data = self.getDataById("polygons", feature.get("id"));
        if (data.index >= 0) {
          let dataNew = { ...data.data };
          let ftClone = feature.clone();

          ftClone.getGeometry().setCoordinates(coordinates);

          dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());
          dataNew.hash = self.getHash(dataNew.geom);

          if (self.editRawData(CONSTANTS.DATA_OBJECTS.POLYGON, data.data, dataNew)) {
            if (undoItemGroup) {
              undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.POLYGON, DATA: dataNew, DATA_OLD: data.data }]);
            }
          }
        }
      }
    });
    self.vectorLines.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      for (let i = 0; i < coordinates.length; i++) {
        let c = coordinates[i];
        if (arrayEquals(c, oldCoordinate)) {
          edited = true;
          coordinates[i] = newCoordinate;
        }
      }
      if (edited) {
        let data = self.getDataById("lines", feature.get("id"));
        if (data.index >= 0) {
          let dataNew = { ...data.data };
          let ftClone = feature.clone();

          ftClone.getGeometry().setCoordinates(coordinates);

          dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());

          if (self.editRawData(CONSTANTS.DATA_OBJECTS.LINE, data.data, dataNew)) {
            if (undoItemGroup) {
              undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: dataNew, DATA_OLD: data.data }]);
            }
          }
        }
      }
    });
    // self.vectorLineSegments.getSource().forEachFeatureInExtent(olExtent, function (feature) {
    //     let edited = false;
    //     let coordinates = feature.getGeometry().getCoordinates();
    //     for (let i = 0; i < coordinates.length; i++) {
    //         let c = coordinates[i];
    //         if (self.arrayEquals(c, olCoordinate)) {
    //             edited = true;
    //             coordinates[i] = pointObj.coord;
    //         }
    //     }
    //     if (edited) {
    //         feature.getGeometry().setCoordinates(coordinates);
    //         let data = self.getDataById("linesegments", feature.get("id"));
    //         data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
    //         anyEditDone = true;
    //     }
    // });
    self.vectorPoints.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      if (arrayEquals(coordinates, oldCoordinate)) {
        edited = true;
        coordinates = newCoordinate;
      }
      if (edited) {
        let data = self.getDataById("points", feature.get("id"));
        if (data.index >= 0) {
          let dataNew = { ...data.data };
          let ftClone = feature.clone();

          ftClone.getGeometry().setCoordinates(coordinates);

          dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());
          dataNew.X = coordinates[0];
          dataNew.Y = coordinates[1];

          if (self.editRawData(CONSTANTS.DATA_OBJECTS.POINT, data.data, dataNew)) {
            if (undoItemGroup) {
              undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.POINT, DATA: dataNew, DATA_OLD: data.data }]);
            }
          }

          // feature.getGeometry().setCoordinates(coordinates);

          // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
          // data.data.X = feature.getGeometry().getCoordinates()[0];
          // data.data.Y = feature.getGeometry().getCoordinates()[1];
          // anyEditDone = true;
          // self.generatedData.points[data.index] = data.data;
        }
      }
    });

    //TODO
    // self.customLayer.getSource().forEachFeatureInExtent(olExtent, function (feature) {
    //     let edited = false;
    //     let coordinates;
    //     if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.POINT) {
    //         coordinates = feature.getGeometry().getCoordinates();
    //         if (arrayEquals(coordinates, oldCoordinate)) {
    //             edited = true;
    //             coordinates = newCoordinate;
    //         }
    //     }
    //     else if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.LINE_STRING) {
    //         coordinates = feature.getGeometry().getCoordinates();
    //         for (let i = 0; i < coordinates.length; i++) {
    //             let c = coordinates[i];
    //             if (arrayEquals(c, oldCoordinate)) {
    //                 edited = true;
    //                 coordinates[i] = newCoordinate;
    //             }
    //         }
    //         //feature.getGeometry().setCoordinates(coordinates);
    //     }
    //     else if (feature.get("geom_type") === CONSTANTS.GEOMETRY_TYPE.POLYGON) {
    //         coordinates = feature.getGeometry().getCoordinates();
    //         for (let i = 0; i < coordinates.length; i++) {
    //             for (let j = 0; j < coordinates[i].length; j++) {
    //                 let c = coordinates[i][j];
    //                 if (arrayEquals(c, oldCoordinate)) {
    //                     edited = true;
    //                     coordinates[i][j] = newCoordinate;
    //                 }
    //             }
    //         }
    //         // feature.getGeometry().setCoordinates(coordinates);
    //     }
    //     if (edited) {

    //         let data = self.getDataById(CONSTANTS.DATA_OBJECTS.LAYER, feature.get("id"));
    //         if (data.index >= 0) {

    //             let dataNew = { ...data.data };
    //             let ftClone = feature.clone();

    //             ftClone.getGeometry().setCoordinates(coordinates);

    //             dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());
    //             ;

    //             if (self.editRawData(CONSTANTS.DATA_OBJECTS.LAYER, data.data, dataNew)) {
    //                 if (undoItemGroup) {
    //                     undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LAYER, DATA: dataNew, DATA_OLD: data.data }]);
    //                 }
    //             }

    //         }
    //         // feature.getGeometry().setCoordinates(coordinates);
    //         // let data = self.getDataFromGenLayers(feature.get("id"));
    //         // data.geometry = self._wktFormat.writeGeometry(feature.getGeometry());
    //         // anyEditDone = true;
    //     }
    // });
    // if (undoable) {
    //     this.undoStack.push({ ACTION: CONSTANTS.UNDO_ACTIONS.EDIT_POINT, DATA: [{ old: newCoordinate, new: oldCoordinate }] });
    // }
  }

  changePointCoordinateTemp(oldCoordinate, newCoordinate) {
    var self = this;
    // self.tempLayer.getSource().clear();
    //  let olCoordinate = pointObj.old_coord;
    self.tempLayer.getSource().clear();

    let olPt = new Point(oldCoordinate);
    let wktPoint = self._wktFormat.writeGeometry(olPt);
    let jtsPt = self._jstsReader.read(wktPoint);
    let bufferedGeom = BufferOp.bufferOp(jtsPt, 0.1);
    let olExtent = self._wktFormat.readGeometry(self._jstsWriter.write(bufferedGeom)).getExtent();
    self.vectorPolygon.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      for (let i = 0; i < coordinates.length; i++) {
        for (let j = 0; j < coordinates[i].length; j++) {
          let c = coordinates[i][j];
          if (arrayEquals(c, oldCoordinate)) {
            edited = true;
            coordinates[i][j] = newCoordinate;
          }
        }
      }
      if (edited) {
        let feature1 = feature.clone();

        feature1.getGeometry().setCoordinates(coordinates);
        feature1.set("label", "" + feature1.getGeometry().getArea().toFixed(2));
        feature1.setStyle(self.styleBuilder.createPolygonAreaStyle(feature1, {}, 0, "", {}, true, true));
        self.tempLayer.getSource().addFeature(feature1);
      }
    });
    self.vectorLines.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      for (let i = 0; i < coordinates.length; i++) {
        let c = coordinates[i];
        if (arrayEquals(c, oldCoordinate)) {
          edited = true;
          coordinates[i] = newCoordinate;
        }
      }
      if (edited) {
        let feature1 = feature.clone();
        feature1.getGeometry().setCoordinates(coordinates);
        feature1.getGeometry().forEachSegment(function (a, b) {
          if (arrayEquals(a, newCoordinate) || arrayEquals(b, newCoordinate)) {
            const segment = new LineString([a, b]);
            const label = segment.getLength().toFixed(2);
            var featureX = new Feature({
              geometry: segment,
              label: label,
              name: "",
            });

            featureX.setStyle(self.styleBuilder.createLineStyle(featureX, {}, 0, "", {}, true));
            self.tempLayer.getSource().addFeature(featureX);
          }
          //  segStyle.setGeometry(segment);
          // styles.push(segStyle);
        });

        // feature1.set("label", "" + feature1.getGeometry().getLength().toFixed(2));
        // self.tempLayer.getSource().addFeature(feature1);
      }
    });

    self.vectorPoints.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let c = feature.getGeometry().getCoordinates();
      if (arrayEquals(c, oldCoordinate)) {
        edited = true;
        c = newCoordinate;
      }
      if (edited) {
        let feature1 = feature.clone();
        feature1.getGeometry().setCoordinates(c);
        self.tempLayer.getSource().addFeature(feature1);
      }
    });
  }

  insertPointInSegment(cordStart, cordEnd, cordIn, undoItemGroup) {
    var self = this;
    let olPt = new Point(cordStart);
    let olPt1 = new Point(cordIn);
    let olPt2 = new Point(cordEnd);

    let wktPoint = self._wktFormat.writeGeometry(olPt);
    let wktPoint1 = self._wktFormat.writeGeometry(olPt1);
    let wktPoint2 = self._wktFormat.writeGeometry(olPt2);

    // console.log(wktPoint);
    let jtsPt = self._jstsReader.read(wktPoint);
    let jtsPt1 = self._jstsReader.read(wktPoint1);
    let jtsPt2 = self._jstsReader.read(wktPoint2);

    jtsPt = UnionOp.union(jtsPt, jtsPt1);
    jtsPt = UnionOp.union(jtsPt, jtsPt2);

    let bufferedGeom = BufferOp.bufferOp(jtsPt, 0.1);

    let olExtent = self._wktFormat.readGeometry(self._jstsWriter.write(bufferedGeom)).getExtent();

    let anyEditDone = false;
    self.vectorPolygon.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      // console.log(coordinates);
      for (let i = 0; i < coordinates.length; i++) {
        for (let j = coordinates[i].length - 2; j >= 0; j--) {
          let c1 = coordinates[i][j + 1];
          let c2 = coordinates[i][j];
          if ((arrayEquals(c1, cordStart) && arrayEquals(c2, cordEnd)) || (arrayEquals(c1, cordEnd) && arrayEquals(c2, cordStart))) {
            edited = true;
            coordinates[i].splice(j + 1, 0, cordIn);
          }
        }
      }
      if (edited) {
        let data = self.getDataById("polygons", feature.get("id"));
        if (data.index >= 0) {
          let dataNew = { ...data.data };
          let ftClone = feature.clone();

          ftClone.getGeometry().setCoordinates(coordinates);

          dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());
          dataNew.hash = self.getHash(dataNew.geom);

          if (self.editRawData(CONSTANTS.DATA_OBJECTS.POLYGON, data.data, dataNew)) {
            if (undoItemGroup) {
              undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.POLYGON, DATA: dataNew, DATA_OLD: data.data }]);
            }
          }

          // feature.getGeometry().setCoordinates(coordinates);

          // console.log(data);
          // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
          // data.data.hash = self.getHash(data.data.geom);
          // self.generatedData.polygons[data.index] = data.data;
          // console.log(data);
          // anyEditDone = true;
        }
      }
    });
    self.vectorLines.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      for (let j = coordinates.length - 2; j >= 0; j--) {
        let c1 = coordinates[j + 1];
        let c2 = coordinates[j];
        if ((arrayEquals(c1, cordStart) && arrayEquals(c2, cordEnd)) || (arrayEquals(c1, cordEnd) && arrayEquals(c2, cordStart))) {
          edited = true;
          coordinates.splice(j + 1, 0, cordIn);
        }
      }
      if (edited) {
        // console.log(coordinates);
        let data = self.getDataById("lines", feature.get("id"));
        if (data.index >= 0) {
          let dataNew = { ...data.data };
          let ftClone = feature.clone();

          ftClone.getGeometry().setCoordinates(coordinates);

          dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());

          if (self.editRawData(CONSTANTS.DATA_OBJECTS.LINE, data.data, dataNew)) {
            if (undoItemGroup) {
              undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: dataNew, DATA_OLD: data.data }]);
            }
          }

          // feature.getGeometry().setCoordinates(coordinates);

          // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
          // self.generatedData.lines[data.index] = data.data;
          // anyEditDone = true;
        }
      }
    });

    this.addPoint("", "", cordIn, undoItemGroup);

    //TODO
    // self.customLayer.getSource().forEachFeatureInExtent(olExtent, function (feature) {
    //     let edited = false;
    //     let coordinates;
    //     if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.LINE_STRING) {
    //         edited = false;
    //         coordinates = feature.getGeometry().getCoordinates();
    //         for (let j = coordinates.length - 2; j >= 0; j--) {

    //             let c1 = coordinates[j + 1];
    //             let c2 = coordinates[j];
    //             if (( arrayEquals(c1, cordStart) &&  arrayEquals(c2, cordEnd)) || ( arrayEquals(c1, cordEnd) &&  arrayEquals(c2, cordStart))) {
    //                 edited = true;
    //                 coordinates.splice(j + 1, 0, cordIn);

    //             }
    //         }

    //     }
    //     if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.POLYGON) {
    //         coordinates = feature.getGeometry().getCoordinates();

    //         for (let i = 0; i < coordinates.length; i++) {
    //             for (let j = coordinates[i].length - 2; j >= 0; j--) {
    //                 let c1 = coordinates[i][j + 1];
    //                 let c2 = coordinates[i][j];
    //                 if (( arrayEquals(c1, cordStart) &&  arrayEquals(c2, cordEnd)) || ( arrayEquals(c1, cordEnd) &&  arrayEquals(c2, cordStart))) {
    //                     edited = true;
    //                     coordinates[i].splice(j + 1, 0, cordIn);

    //                 }
    //             }
    //         }

    //     }
    //     if (edited) {

    //         let data = self.getDataById(CONSTANTS.DATA_OBJECTS.LAYER, feature.get("id"));
    //         if (data.index >= 0) {

    //             let dataNew = { ...data.data };
    //             let ftClone = feature.clone();

    //             ftClone.getGeometry().setCoordinates(coordinates);

    //             dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());
    //             dataNew.hash = self.getHash(dataNew.geom);

    //             if (self.editRawData(CONSTANTS.DATA_OBJECTS.LAYER, data.data, dataNew)) {
    //                 if (undoItemGroup) {
    //                     undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LAYER, DATA: dataNew, DATA_OLD: data.data }]);
    //                 }
    //             }

    //         }

    //     }
    // });
    // if (!isUndoRedoOp && anyEditDone) {
    //     this.logActions({ "undoOp": pointObj, "redoOp": pointObj, "type": Gis.OPERATION.EDITPOINT });
    // }
  }

  //LINE FUNCTIONS
  addLine(id, lineName, coordinates, undoItemGroup, layer_code) {
    let self = this;
    if (coordinates && coordinates.length >= 2) {
      // if (Object.values(CONSTANTS.LINETYPE).includes(lineType)) {
      if (!lineName) {
        lineName = "L" + (this.generatedData.lines.length + 1);
      }
      if (isStringEmpty(id)) {
        id = this.createID();
      }
      var line = new LineString(coordinates);
      //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));
      let wktLine = self._wktFormat.writeGeometry(line);
      let lineData = { id: id, name: lineName, geom: wktLine };
      if (layer_code) {
        lineData.layer_code = layer_code;
      }
      return this.addLineObject(lineData, undoItemGroup);

      // }
    }
  }

  addLineWkt(id, lineName, wktLine, undoItemGroup) {
    // if (Object.values(CONSTANTS.LINETYPE).includes(lineType)) {
    if (!lineName) {
      lineName = "L" + (this.generatedData.lines.length + 1)();
    }
    if (isStringEmpty(id)) {
      id = this.createID();
    }
    // var line = new LineString(coordinates);
    // //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));
    // let wktLine = self._wktFormat.writeGeometry(line);

    let lineData = { id: id, name: lineName, geom: wktLine };
    return this.addLineObject(lineData, undoItemGroup);

    // }
  }

  addLineObject(lineData, undoItemGroup) {
    // this.generatedData.lines.push(lineData);
    // let geometry = this._wktFormat.readGeometry(lineData.geom);
    // let lnFeature = new Feature({
    //     geometry: geometry,
    //     id: lineData.id,
    //     name: lineData.name,
    //     category: lineData.type,
    //     layer_type: CONSTANTS.LAYERTYPE.LINES
    // });
    // lnFeature.setId(lineData.id);
    // this.vectorLines.getSource().addFeature(lnFeature);
    let layerMasterData;
    if (!lineData.layer_code) {
      let selectedLayerCode = this.uiBuilder.getSelectedLayerCode();
      if (!selectedLayerCode) {
        lineData.layer_code = CONSTANTS.PREDEFINED_LAYERS.DEFAULT_LINE;
      }
      layerMasterData = this.getLayerMasterDataFromCode(selectedLayerCode);
      if (
        !layerMasterData ||
        !(
          layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.LINE_STRING.toUpperCase() ||
          layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.MULTI_LINE_STRING.toUpperCase()
        )
      ) {
        lineData.layer_code = CONSTANTS.PREDEFINED_LAYERS.DEFAULT_LINE;
      } else {
        lineData.layer_code = selectedLayerCode;
      }
    } else {
      layerMasterData = this.getLayerMasterDataFromCode(lineData.layer_code);
    }
    if ("attrs" in layerMasterData && !("attrs" in lineData)) {
      lineData["attrs"] = {};
      for (let i = 0; i < layerMasterData.attrs.length; i++) {
        lineData.attrs[layerMasterData.attrs[i].key] = "";
      }
    }

    let jstsGeometry = this._jstsReader.read(lineData.geom);
    jstsGeometry = jstsGeometry.getGeometryN(0);
    lineData.geom = this._jstsWriter.write(jstsGeometry);

    let geometry = this._wktFormat.readGeometry(lineData.geom);

    this.addRawData(CONSTANTS.DATA_OBJECTS.LINE, lineData);

    //// Finding coordinates to be inserted in points array.

    // self.vectorPoints.getSource().forEachFeatureInExtent(geometry.getExtent(), function (feature) {
    //     let coordinates = feature.getGeometry().getCoordinates();
    //     for (let i = 0; i < coordinates.length; i++) {
    //         let c = coordinates[i];

    //         for (let j = lineCordsNotinPoints.length-1; j >=0 ; j--) {

    //             if (self.arrayEquals(c, lineCordsNotinPoints[j])) {
    //                 lineCordsNotinPoints.splice(j,1);

    //                // coordinates[i][j] = pointObj.coord;
    //             }
    //         }
    //     }

    // });

    if (undoItemGroup) {
      undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.A, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: lineData }]);
    }

    if (true === layerMasterData.ignore_points) {
    } else {
      let lineCordsNotinPoints = [...geometry.getCoordinates()];
      for (let i = 0; i < lineCordsNotinPoints.length; i++) {
        this.addPoint("", "", lineCordsNotinPoints[i], undoItemGroup, "_DEFAULT_POINT_");
        // if (ptData) {
        // undoActions.push({ ACTION: CONSTANTS.UNDO_ACTIONS.DELETE_POINT_BY_ID, DATA: [ptData.id] });
        // }
      }
    }

    ////
    // if (undoable) {
    //     this.undoStack.push(undoActions);
    // }
  }

  deleteLineByID(id, undoItemGroup) {
    let event = this.deleteRawData(CONSTANTS.DATA_OBJECTS.LINE, id);
    if (event.index >= 0) {
      if (undoItemGroup) {
        undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.D, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: event.data }]);
      }
    }

    // let event = this.getDataById("lines", id);
    // if (event.index >= 0) {
    //     let ft = this.vectorLines.getSource().getFeatureById(event.data.id);
    //     if (ft) {
    //         this.generatedData.lines.splice(event.index, 1);
    //         this.vectorLines.getSource().removeFeature(ft);
    //         if (undoable) {
    //             this.undoStack.push({ ACTION: CONSTANTS.UNDO_ACTIONS.ADD_LINE_DATA, DATA: [event.data] });
    //         }
    //     }
    // }
  }

  //POLYGON FUNCTIONS

  addPolygon(id, polygonName, coordinates, undoItemGroup, layer_code) {
    let self = this;

    if (coordinates) {
      // if (Object.values(CONSTANTS.POLYGONTYPE).includes(polygonType)) {
      if (!polygonName) {
        polygonName = "P" + (this.generatedData.polygons.length + 1);
      }
      if (isStringEmpty(id)) {
        id = this.createID();
      }
      var polygon = new Polygon(coordinates);
      //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));
      let wktPolygon = self._wktFormat.writeGeometry(polygon);
      let polygonData = { id: id, name: polygonName, geom: wktPolygon };
      if (layer_code) {
        polygonData.layer_code = layer_code;
      }
      this.addPolygonObject(polygonData, undoItemGroup);
      return true;
      // }
    }
  }

  addPolygonWkt(id, polygonName, wktPolygon, undoItemGroup) {
    if (coordinates) {
      // if (Object.values(CONSTANTS.POLYGONTYPE).includes(polygonType)) {
      if (!polygonName) {
        polygonName = "P" + (this.generatedData.polygons.length + 1);
      }
      if (isStringEmpty(id)) {
        id = this.createID();
      }
      // var polygon = new Polygon(coordinates);
      //var pt = self.snapPointToBoundary(new Point([coordinates[0], coordinates[1]]));
      //let wktPolygon = self._wktFormat.writeGeometry(polygon);
      let polygonData = { id: id, name: polygonName, geom: wktPolygon };
      this.addPolygonObject(polygonData, undoItemGroup);
      return true;
      // }
    }
  }

  addPolygonObject(polygonData, undoItemGroup) {
    let self = this;
    let layerMasterData;
    if (!polygonData.layer_code) {
      let selectedLayerCode = this.uiBuilder.getSelectedLayerCode();
      if (!selectedLayerCode) {
        polygonData.layer_code = CONSTANTS.PREDEFINED_LAYERS.DEFAULT_POLYGON;
      }
      layerMasterData = this.getLayerMasterDataFromCode(selectedLayerCode);
      if (
        !layerMasterData ||
        !(
          layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.POLYGON.toUpperCase() ||
          layerMasterData.geom_type.toUpperCase() === CONSTANTS.GEOMETRY_TYPE.MULTI_POLYGON.toUpperCase()
        )
      ) {
        polygonData.layer_code = CONSTANTS.PREDEFINED_LAYERS.DEFAULT_POLYGON;
      } else {
        polygonData.layer_code = selectedLayerCode;
      }
    } else {
      layerMasterData = this.getLayerMasterDataFromCode(polygonData.layer_code);
    }
    if ("attrs" in layerMasterData && !("attrs" in polygonData)) {
      polygonData["attrs"] = {};
      for (let i = 0; i < layerMasterData.attrs.length; i++) {
        polygonData.attrs[layerMasterData.attrs[i].key] = "";
      }
    }

    let polyHash = this.getHash(polygonData.geom);
    // if (self.checkPolygonAlreadyExist(polyHash, polygonData.layer_code)) {
    //     //self.uiBuilder.showcustomAlert("Skipped polygon creation.", Gis.ALERTS.WARNING);
    //     return false;
    // }

    polygonData.hash = polyHash;
    if (!polygonData.name) {
      polygonData.name = "P" + this.generatedData.polygons.length + 1;
    }

    if (!polygonData.id || isStringEmpty(polygonData.id)) {
      polygonData.id = this.createID();
    }

    // let geometry = self._wktFormat.readGeometry(polygonData.geom);

    // this.generatedData.polygons.push(polygonData);

    // let polyFeature = new Feature({
    //     geometry: geometry,
    //     id: polygonData.id,
    //     name: polygonData.name,
    //     category: polygonData.type,
    //     layer_type: CONSTANTS.LAYERTYPE.LINES
    // });
    // polyFeature.setId(polygonData.id);
    // self.vectorPolygon.getSource().addFeature(polyFeature);

    let jstsGeometry = this._jstsReader.read(polygonData.geom);
    if (layerMasterData.hide_from_list && layerMasterData.hide_from_list == true) {
    } else {
      jstsGeometry = jstsGeometry.getGeometryN(0);
    }
    polygonData.geom = this._jstsWriter.write(jstsGeometry);

    this.addRawData(CONSTANTS.DATA_OBJECTS.POLYGON, polygonData);

    if (undoItemGroup) {
      undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.A, OBJECT: CONSTANTS.DATA_OBJECTS.POLYGON, DATA: polygonData }]);
    }

    //let layerMasterData = this.getLayerMasterDataFromCode(polygonData.layer_code);
    let geometry = this._wktFormat.readGeometry(polygonData.geom);
    let lineCordsNotinPoints = [...geometry.getCoordinates()];
    if (true === layerMasterData.ignore_points) {
    } else {
      for (let i = 0; i < lineCordsNotinPoints.length; i++) {
        for (let j = 0; j < lineCordsNotinPoints[i].length; j++) {
          this.addPoint("", "", lineCordsNotinPoints[i][j], undoItemGroup, polygonData.lock_verices, "_DEFAULT_POINT_");
          // if (ptData) {
          // undoActions.push({ ACTION: CONSTANTS.UNDO_ACTIONS.DELETE_POINT_BY_ID, DATA: [ptData.id] });
          // }
        }
      }
    }
    // if (undoable) {
    //     this.undoStack.push(undoActions);
    // }
    return polygonData;
  }

  deletePointCoordinate(olCoordinate, undoItemGroup) {
    var self = this;
    // self.tempLayer.getSource().clear();
    // let olCoordinate = pointObj.old_coord;
    let anyEditDone = false;
    let olPt = new Point(olCoordinate);
    let wktPoint = self._wktFormat.writeGeometry(olPt);
    let jtsPt = self._jstsReader.read(wktPoint);
    let bufferedGeom = BufferOp.bufferOp(jtsPt, 0.1);
    let modified = { lines: [], polygons: [] };
    let deleted = { points: [], lines: [], polygons: [] };
    let olExtent = self._wktFormat.readGeometry(self._jstsWriter.write(bufferedGeom)).getExtent();
    self.vectorPolygon.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      // console.log(JSON.stringify(coordinates));
      for (let i = coordinates.length - 1; i >= 0; i--) {
        for (let j = coordinates[i].length - 1; j >= 0; j--) {
          let c = coordinates[i][j];
          if (arrayEquals(c, olCoordinate)) {
            edited = true;
            coordinates[i].splice(j, 1);

            // coordinates[i][j] = pointObj.coord;
          }
        }
        //     for (let j = coordinates[i].length-1; j >=0 ; j--) {
        if (!arrayEquals(coordinates[i][0], coordinates[i][coordinates[i].length - 1])) {
          coordinates[i].push(coordinates[i][0]);
        }
        if (coordinates[i].length <= 3) {
          coordinates.splice(i, 1);
        }

        //   }
      }
      // console.log(JSON.stringify(coordinates));
      if (edited) {
        if (coordinates.length > 0) {
          //  let feature1 = feature.clone();
          //  feature.getGeometry().setCoordinates(coordinates);
          //TO HANDLE UNDO
          let data = self.getDataById("polygons", feature.get("id"));
          if (data.index >= 0) {
            let dataNew = { ...data.data };
            let ftClone = feature.clone();

            ftClone.getGeometry().setCoordinates(coordinates);

            dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());
            dataNew.hash = self.getHash(dataNew.geom);

            if (self.editRawData(CONSTANTS.DATA_OBJECTS.POLYGON, data.data, dataNew)) {
              if (undoItemGroup) {
                undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.POLYGON, DATA: dataNew, DATA_OLD: data.data }]);
              }
            }
            modified.polygons.push(dataNew.id);

            // feature.getGeometry().setCoordinates(coordinates);

            // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
            // data.data.hash = self.getHash(data.data.geom);
            // self.generatedData.polygons[data.index] = data.data;
            // anyEditDone = true;
          }
          //feature.set("label", "" + feature1.getGeometry().getArea().toFixed(2));
          //self.tempLayer.getSource().addFeature(feature1);
        } else {
          //TO HANDLE UNDO
          let id = feature.get("id");
          self.deletePolygonByID(id, undoItemGroup);
          deleted.polygons.push(id);
        }
      }
    });
    self.vectorLines.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      let isRing = false;
      if (arrayEquals(coordinates[0], coordinates[coordinates.length - 1])) {
        isRing = true;
      }
      for (let i = coordinates.length - 1; i >= 0; i--) {
        let c = coordinates[i];
        if (arrayEquals(c, olCoordinate)) {
          edited = true;
          coordinates.splice(i, 1);
        }
      }
      if (edited) {
        if (isRing) {
          if (!arrayEquals(coordinates[0], coordinates[coordinates.length - 1])) {
            coordinates.push(coordinates[0]);
          }
        }
        if ((!isRing && coordinates.length > 1) || (isRing && coordinates.length > 2)) {
          //let feature1 = feature.clone();

          let data = self.getDataById("lines", feature.get("id"));
          if (data.index >= 0) {
            let dataNew = { ...data.data };
            let ftClone = feature.clone();

            ftClone.getGeometry().setCoordinates(coordinates);

            dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());

            if (self.editRawData(CONSTANTS.DATA_OBJECTS.LINE, data.data, dataNew)) {
              if (undoItemGroup) {
                undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: dataNew, DATA_OLD: data.data }]);
              }
            }
            modified.lines.push(dataNew.id);
            // feature.getGeometry().setCoordinates(coordinates);

            // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
            // self.generatedData.polygons[data.index] = data.data;
            // anyEditDone = true;
          }

          //self.tempLayer.getSource().addFeature(feature1);
        } else {
          //TO HANDLE UNDO
          let id = feature.get("id");
          self.deleteLineByID(id, undoItemGroup);
          deleted.lines.push(id);
        }
      }
    });
    // self.vectorLineSegments.getSource().forEachFeatureInExtent(olExtent, function (feature) {
    //     let edited = false;
    //     let coordinates = feature.getGeometry().getCoordinates();
    //     let isRing = false;

    //     for (let i = 0; i < coordinates.length; i++) {
    //         let c = coordinates[i];
    //         if (self.arrayEquals(c, olCoordinate)) {
    //             edited = true;
    //             coordinates[i] = pointObj.coord;
    //         }
    //     }
    //     if (edited) {
    //         // let feature1 = feature.clone();
    //         // feature1.getGeometry().setCoordinates(coordinates);
    //         // feature1.set("label", "" + feature1.getGeometry().getLength().toFixed(2));
    //         // self.tempLayer.getSource().addFeature(feature1);
    //         self.vectorLineSegments.getSource().removeFeature(feature);
    //     }
    // });
    self.vectorPoints.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let c = feature.getGeometry().getCoordinates();
      if (arrayEquals(c, olCoordinate)) {
        edited = true;
        c = olCoordinate;
      }
      if (edited) {
        // let feature1 = feature.clone();
        // feature1.getGeometry().setCoordinates(c);
        // self.tempLayer.getSource().addFeature(feature1);
        //self.vectorPoints.getSource().removeFeature(feature);
        //TO HANDLE UNDO
        let id = feature.get("id");
        self.deletePointByID(id, undoItemGroup);
        deleted.points.push(id);
      }
    });

    let toDelete = [];
    // self.customLayer.getSource().forEachFeatureInExtent(olExtent, function (feature) {
    //     if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.POINT) {
    //         for (let i = 0; i < deleted.points.length; i++) {
    //             if (feature.get("reference_id") === deleted.points[i]) {
    //                 toDelete.push(feature.get("id"));

    //             }
    //         }
    //     }
    //     else if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.LINE) {
    //         for (let i = 0; i < deleted.lines.length; i++) {
    //             if (feature.get("reference_id") === deleted.lines[i]) {
    //                 toDelete.push(feature.get("id"));
    //             }
    //         }

    //         for (let i = 0; i < modified.lines.length; i++) {

    //             if (feature.get("reference_id") === modified.lines[i]) {
    //                 let data = self.getDataById(CONSTANTS.DATA_OBJECTS.LINE, feature.get("reference_id"));
    //                 if (data.index >= 0) {

    //                     let currentData = self.getDataById(CONSTANTS.DATA_OBJECTS.LAYER, feature.get("id"));
    //                     let dataNew = { ...currentData };

    //                     dataNew.geom = self._wktFormat.writeGeometry(data.data.geom);

    //                     if (self.editRawData(CONSTANTS.DATA_OBJECTS.LAYER, currentData, dataNew)) {
    //                         if (undoItemGroup) {
    //                             undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LAYER, DATA: dataNew, DATA_OLD: currentData }]);
    //                         }
    //                     }

    //                 }
    //             }
    //         }
    //     }
    //     else if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.POLYGON) {
    //         for (let i = 0; i < deleted.polygons.length; i++) {
    //             if (feature.get("reference_id") === deleted.polygons[i]) {
    //                 toDelete.push(feature.get("id"));
    //             }
    //         }

    //         for (let i = 0; i < modified.polygons.length; i++) {

    //             if (feature.get("reference_id") === modified.polygons[i]) {
    //                 let data = self.getDataById(CONSTANTS.DATA_OBJECTS.POLYGON, feature.get("reference_id"));
    //                 if (data.index >= 0) {

    //                     let currentData = self.getDataById(CONSTANTS.DATA_OBJECTS.LAYER, feature.get("id"));
    //                     let dataNew = { ...currentData };

    //                     dataNew.geom = self._wktFormat.writeGeometry(data.data.geom);

    //                     if (self.editRawData(CONSTANTS.DATA_OBJECTS.LAYER, currentData, dataNew)) {
    //                         if (undoItemGroup) {
    //                             undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LAYER, DATA: dataNew, DATA_OLD: currentData }]);
    //                         }
    //                     }

    //                 }
    //             }
    //         }
    //     }

    // });

    if (toDelete.length > 0) {
      for (let i = 0; i < toDelete.length; i++) {
        self.deleteLayerByID(toDelete[i], undoItemGroup);
      }
    }
  }

  deleteSegment(cordStart, cordEnd, undoItemGroup) {
    var self = this;
    let olPt = new Point(cordStart);
    let olPt2 = new Point(cordEnd);

    let wktPoint = self._wktFormat.writeGeometry(olPt);
    let wktPoint2 = self._wktFormat.writeGeometry(olPt2);

    let modified = { lines: [] };
    let deleted = { lines: [] };

    let jtsPt = self._jstsReader.read(wktPoint);
    let jtsPt2 = self._jstsReader.read(wktPoint2);

    jtsPt = UnionOp.union(jtsPt, jtsPt2);

    let bufferedGeom = BufferOp.bufferOp(jtsPt, 0.1);

    let olExtent = self._wktFormat.readGeometry(self._jstsWriter.write(bufferedGeom)).getExtent();

    self.vectorLines.getSource().forEachFeatureInExtent(olExtent, function (feature) {
      let edited = false;
      let coordinates = feature.getGeometry().getCoordinates();
      let coordsLeft, coordsRight;
      let anyEditDone = false;
      for (let j = coordinates.length - 2; j >= 0; j--) {
        let c1 = coordinates[j + 1];
        let c2 = coordinates[j];
        if ((arrayEquals(c1, cordStart) && arrayEquals(c2, cordEnd)) || (arrayEquals(c1, cordEnd) && arrayEquals(c2, cordStart))) {
          edited = true;
          // coordinates.splice(j + 1, 0, cordIn);
          coordsLeft = coordinates.slice(0, j + 1);
          coordsRight = coordinates.slice(j + 1);
          break;
        }
      }
      if (edited) {
        //Handle UNDO
        if (coordsLeft.length >= 2 && coordsRight.length >= 2) {
          let data = self.getDataById("lines", feature.get("id"));
          if (data.index >= 0) {
            let dataNew = { ...data.data };
            let ftClone = feature.clone();

            ftClone.getGeometry().setCoordinates(coordsLeft);

            dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());

            if (self.editRawData(CONSTANTS.DATA_OBJECTS.LINE, data.data, dataNew)) {
              if (undoItemGroup) {
                undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: dataNew, DATA_OLD: data.data }]);
              }
            }
            modified.lines.push(dataNew.id);
            // feature.getGeometry().setCoordinates(coordsLeft);

            // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
            // self.generatedData.lines[data.index] = data.data;

            self.addLine("", "", coordsRight, undoItemGroup);

            // anyEditDone = true;
          }
        } else if (coordsLeft.length >= 2) {
          let data = self.getDataById("lines", feature.get("id"));
          if (data.index >= 0) {
            let dataNew = { ...data.data };
            let ftClone = feature.clone();

            ftClone.getGeometry().setCoordinates(coordsLeft);

            dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());

            if (self.editRawData(CONSTANTS.DATA_OBJECTS.LINE, data.data, dataNew)) {
              if (undoItemGroup) {
                undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: dataNew, DATA_OLD: data.data }]);
              }
            }
            modified.lines.push(dataNew.id);
            // feature.getGeometry().setCoordinates(coordsLeft);

            // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
            // self.generatedData.lines[data.index] = data.data;
            // anyEditDone = true;
          }
        } else if (coordsRight.length >= 2) {
          let data = self.getDataById("lines", feature.get("id"));
          if (data.index >= 0) {
            let dataNew = { ...data.data };
            let ftClone = feature.clone();

            ftClone.getGeometry().setCoordinates(coordsRight);

            dataNew.geom = self._wktFormat.writeGeometry(ftClone.getGeometry());

            if (self.editRawData(CONSTANTS.DATA_OBJECTS.LINE, data.data, dataNew)) {
              if (undoItemGroup) {
                undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LINE, DATA: dataNew, DATA_OLD: data.data }]);
              }
            }
            modified.lines.push(dataNew.id);
            // feature.getGeometry().setCoordinates(coordsRight);

            // data.data.geom = self._wktFormat.writeGeometry(feature.getGeometry());
            // self.generatedData.lines[data.index] = data.data;
            // anyEditDone = true;
          }
        } else {
          self.deleteLineByID(feature.get("id"), undoItemGroup);
          // deleted.lines.push(feature.get("id"));
        }
      }
    });

    let toDelete = [];
    // self.customLayer.getSource().forEachFeatureInExtent(olExtent, function (feature) {

    //     if (feature.getGeometry().getType() === CONSTANTS.GEOMETRY_TYPE.LINE) {
    //         for (let i = 0; i < deleted.lines.length; i++) {
    //             if (feature.get("reference_id") === deleted.lines[i]) {
    //                 toDelete.push(feature.get("id"));
    //             }
    //         }

    //         for (let i = 0; i < modified.lines.length; i++) {

    //             if (feature.get("reference_id") === modified.lines[i]) {
    //                 let data = self.getDataById(CONSTANTS.DATA_OBJECTS.LINE, feature.get("reference_id"));
    //                 if (data.index >= 0) {

    //                     let currentData = self.getDataById(CONSTANTS.DATA_OBJECTS.LAYER, feature.get("id"));
    //                     let dataNew = { ...currentData };

    //                     dataNew.geom = self._wktFormat.writeGeometry(data.data.geom);

    //                     if (self.editRawData(CONSTANTS.DATA_OBJECTS.LAYER, currentData, dataNew)) {
    //                         if (undoItemGroup) {
    //                             undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.E, OBJECT: CONSTANTS.DATA_OBJECTS.LAYER, DATA: dataNew, DATA_OLD: currentData }]);
    //                         }
    //                     }

    //                 }
    //             }
    //         }
    //     }

    // });

    if (toDelete.length > 0) {
      for (let i = 0; i < toDelete.length; i++) {
        self.deleteLayerByID(toDelete[i], undoItemGroup);
      }
    }
  }

  deletePolygonByID(id, undoItemGroup) {
    let event = this.deleteRawData(CONSTANTS.DATA_OBJECTS.POLYGON, id);
    if (event.index >= 0) {
      if (undoItemGroup) {
        undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.D, OBJECT: CONSTANTS.DATA_OBJECTS.POLYGON, DATA: event.data }]);
      }
    }

    // let event = this.getDataById("polygons", id);
    // if (event.index >= 0) {
    //     let ft = this.vectorPolygon.getSource().getFeatureById(event.data.id);
    //     if (ft) {
    //         this.generatedData.polygons.splice(event.index, 1);
    //         this.vectorPolygon.getSource().removeFeature(ft);
    //         if (undoable) {
    //             this.undoStack.push({ ACTION: CONSTANTS.UNDO_ACTIONS.ADD_POLYGON_DATA, DATA: [event.data] });
    //         }
    //     }
    // }
  }

  polyGonizeLine(undoItemGroup) {
    var self = this;
    // var boundaryArray = [];
    // if (this.initialData.initial_polygon.length > 0) {
    //     for (var er = 0; er < this.initialData.initial_polygon.length; er++) {
    //         let a = this._jstsReader.read(this.initialData.initial_polygon[er]);
    //         boundaryArray.push(a);
    //     }
    // }
    // if (this.generatedData.lines.length > 1) {
    var unionData;
    var initial = this._jstsReader.read(this.generatedData.lines[0].geom);
    unionData = initial;
    for (var i = 1; i < this.generatedData.lines.length; i++) {
      if (this._wktFormat.readGeometry(this.generatedData.lines[i].geom).getCoordinates().length > 1) {
        var current = this._jstsReader.read(this.generatedData.lines[i].geom);
        unionData = UnionOp.union(unionData, current);
      }
    }
    // this.clearGeneratedPolygons();
    var polygonizer = new Polygonizer();
    polygonizer.add(unionData);
    var polygons = polygonizer.getPolygons();
    for (var i = polygons.iterator(); i.hasNext(); ) {
      var polygon = i.next();
      // for (var ind = 0; ind < boundaryArray.length; ind++) {
      //     if (boundaryArray[ind].contains(polygon.getInteriorPoint())) {
      // var xui;
      let pOL = self._wktFormat.readGeometry(self._jstsWriter.write(polygon));
      let exists = false;
      self.vectorPolygon.getSource().forEachFeatureInExtent(pOL.getExtent(), function (feature) {
        let pwkt = self._wktFormat.writeGeometry(feature.getGeometry());
        let p1 = self._jstsReader.read(pwkt);
        if (p1.equalsTopo(polygon)) {
          exists = true;
        }
      });

      if (!exists && polygon.getArea() > 1) {
        // var data = {};
        // var attr = this.initialData.attributes;
        // for (var t = 0; t < attr.length; t++) {
        //     if (attr[t].visible && attr[t].editable && this.initialData.output.attributes && this.initialData.output.attributes.length > 0) {
        //         data[attr[t].field] = this.initialData.output.attributes[0][attr[t].field];
        //     }
        // }
        // let polyHash = this.getHash(polygon.toText());
        // if (self.checkPolygonAlreadyExist(polyHash)) {
        //     //self.uiBuilder.showcustomAlert("Skipped polygon creation.", Gis.ALERTS.WARNING);
        //     continue;
        // }
        let polyData = { name: "P" + (self.generatedData.polygons.length + 1), geom: self._jstsWriter.write(polygon) };
        self.addPolygonObject(polyData, undoItemGroup);
      }
      //     }
      // }
    }
    // self.refreshPolygonsInDataDisplay();
    // }
  }

  splitMultiplePolygons() {
    let self = this;
    let splitted = false;
    if (self.mergeList.length > 0) {
      let undoItemGroup = [];
      for (let k = 0; k < self.mergeList.length; k++) {
        let res = self.splitPolygon(self.mergeList[k].id, undoItemGroup);
        if (res === true) {
          splitted = true;
        }
      }
      this.undoRedoManager.pushToUndoStack(undoItemGroup);
      self.clearSplitLines();
      self.clearTempGeom();
      self.mergeList = [];
    }
    return splitted;
  }

  splitPolygonTemp(id, lineGeom) {
    var self = this;

    var a;
    var ring;
    var b;
    let factory = self._geometryFactory;

    let initialPolygon = null;
    for (let i = 0; i < this.generatedData.polygons.length; i++) {
      if (this.generatedData.polygons[i].id === id) {
        initialPolygon = this.generatedData.polygons[i];
      }
    }

    var boundaryArray = [];
    if (initialPolygon) {
      // for (var er = 0; er < initialPolygon.length; er++) {
      a = this._jstsReader.read(initialPolygon.geom);
      b = a.getGeometryN(0).getBoundary();
      boundaryArray.push(a);
      //if (er === 0) {
      ring = b;
    }
    // if(this.generatedData.polygons.length>0){

    var union = ring;
    union = UnionOp.union(union, this._jstsReader.read(lineGeom));

    for (var j = 0; j < this.generatedData.lines.length; j++) {
      if (this.generatedData.lines[j].layer_code === CONSTANTS.PREDEFINED_LAYERS.SPLIT_LINE) {
        let b = this._jstsReader.read(this.generatedData.lines[j].geom);
        union = UnionOp.union(union, b);
      }
    }

    if (union) {
      let splitted = false;
      var polygonizer = new Polygonizer();
      polygonizer.add(union);

      var polygons = polygonizer.getPolygons();
      //this.clearGeneratedPolygons();
      // self.clearTempGeom();
      for (var i = polygons.iterator(); i.hasNext(); ) {
        var polygon = i.next();
        for (var ind = 0; ind < boundaryArray.length; ind++) {
          console.log(self._jstsWriter.write(boundaryArray[ind]));
          self._geometryFactory.createPoint();
          if (RelateOp.contains(boundaryArray[ind], self._geometryFactory.createPoint(InteriorPointArea.getInteriorPoint(polygon)))) {
            var xui;
            if (polygon.getArea() > 1) {
              splitted = true;

              self.showTempGeom([self._wktFormat.readGeometry(self._jstsWriter.write(polygon))], polygon.getArea().toFixed(2), "P");
              // console.log(polygon.toText());
              // let polyData = { "name": "P" + (self.generatedData.polygons.length + 1), "geom": polygon.toText()  };
              // if("attrs" in initialPolygon ){
              //     polyData.attrs = initialPolygon.attrs;
              // }
              // self.addPolygonObject(polyData, undoItemGroup);
            }
          }
        }
      }
    }
  }

  splitPolygon(id, undoItemGroup) {
    var self = this;
    let splitted = false;
    var a;
    var ring;
    var b;
    let factory = self._geometryFactory;

    let initialPolygon = null;
    for (let i = 0; i < this.generatedData.polygons.length; i++) {
      if (this.generatedData.polygons[i].id === id) {
        initialPolygon = this.generatedData.polygons[i];
      }
    }

    var boundaryArray = [];
    if (initialPolygon) {
      // for (var er = 0; er < initialPolygon.length; er++) {
      a = this._jstsReader.read(initialPolygon.geom);
      b = a.getGeometryN(0).getBoundary();
      boundaryArray.push(a);
      //if (er === 0) {
      ring = b;
      //}
      //ring = ring.union(b);
      // }
    }
    // if(this.generatedData.polygons.length>0){
    //     for(var er=0;er<this.generatedData.polygons.length;er++){
    //         a = this._jstsReader.read(this.generatedData.polygons[er].geom);
    //         b = a.getGeometryN(0).getBoundary();
    //         boundaryArray.push(a);
    //         if (er === 0) {
    //             ring = b;
    //         }
    //         ring = ring.union(b);
    //     }
    // }
    var union = ring;

    for (var j = 0; j < this.generatedData.lines.length; j++) {
      if (this.generatedData.lines[j].layer_code === CONSTANTS.PREDEFINED_LAYERS.SPLIT_LINE) {
        let b = this._jstsReader.read(this.generatedData.lines[j].geom);
        union = UnionOp.union(union, b);
        // let lineCords = b.getCoordinates();
        // let newStart = lineCords[0];
        // let testP = this._jstsReader.read("POINT (" + newStart.getX() + " " + newStart.getY() + ")");
        // for (var index = 0; index < boundaryArray.length; index++) {

        //     if (ring.distance(b) <= 0.01) {
        //         if (testP.distance(ring) < 0.0001) {
        //             newStart = testP.buffer(0.0001).difference(boundaryArray[index]).getCentroid().getCoordinate();
        //             lineCords[0] = newStart;
        //         }

        //         let newEnd = lineCords[lineCords.length - 1];
        //         testP = this._jstsReader.read("POINT (" + newEnd.getX() + " " + newEnd.getY() + ")");
        //         if (testP.distance(ring) < 0.0001) {
        //             newEnd = testP.buffer(0.0001).difference(boundaryArray[index]).getCentroid().getCoordinate();
        //             lineCords[lineCords.length - 1] = newEnd;
        //         }

        //         b = factory.createLineString(lineCords);
        //         if (j === 0 || !union) {
        //             union = b;
        //         }
        //         union = union.union(b);
        //     }
        // }
      }
    }
    if (union) {
      var polygonizer = new Polygonizer();
      polygonizer.add(union);

      var polygons = polygonizer.getPolygons();
      //this.clearGeneratedPolygons();

      for (var i = polygons.iterator(); i.hasNext(); ) {
        var polygon = i.next();
        for (var ind = 0; ind < boundaryArray.length; ind++) {
          if (RelateOp.contains(boundaryArray[ind], self._geometryFactory.createPoint(InteriorPointArea.getInteriorPoint(polygon)))) {
            var xui;
            if (polygon.getArea() > 1) {
              splitted = true;

              let polyData = { name: "P" + (self.generatedData.polygons.length + 1), geom: self._jstsWriter.write(polygon) };
              if ("attrs" in initialPolygon) {
                polyData.attrs = initialPolygon.attrs;
              }
              self.addPolygonObject(polyData, undoItemGroup);
            }
          }
        }
      }
      if (splitted) {
        self.deletePolygonByID(id, undoItemGroup);
      }
    }
    return splitted;
  }

  mergePolygons(list, undoItemGroup) {
    let self = this;

    let union;
    let attrs;
    for (let i = 0; i < list.length; i++) {
      let polyObj = this.getDataById("polygons", list[i].id);
      // console.log(polyObj);
      if (!attrs) {
        attrs = polyObj.data.attrs;
      }
      if (i === 0) {
        union = this._jstsReader.read(polyObj.data.geom);
      } else {
        union = UnionOp.union(union, this._jstsReader.read(polyObj.data.geom));
      }
    }
    let undoDel = [],
      undoAdd = [];
    if (union.getNumGeometries() == 1) {
      for (let i = 0; i < list.length; i++) {
        self.deletePolygonByID(list[i].id, undoDel);
      }

      let polyData = { layer_code: list[0].layer_code, name: "P" + (self.generatedData.polygons.length + 1), geom: self._jstsWriter.write(union) };
      if (attrs) {
        polyData.attrs = attrs;
      }
      self.addPolygonObject(polyData, undoAdd);
    }
    undoItemGroup.push(...undoAdd, ...undoDel);
  }

  // clearGeneratedPolygons() {

  //     this.generatedData.polygons = this.removeFromArrayByAttr(this.generatedData.polygons, "category", Gis.POLYGONTYPE.CREATED);
  //     this.gridData.polygon = this.removeFromArrayByAttr(this.gridData.polygon, "category", Gis.POLYGONTYPE.CREATED);
  //     let features = this.vectorPolygon.getSource().getFeatures();

  //     for (var i = features.length - 1; i >= 0; i--) {
  //         if (features[i].get("category") === Gis.POLYGONTYPE.CREATED) {
  //             this.vectorPolygon.getSource().removeFeature(features[i]);
  //         }
  //     }
  //     this.refreshPolygonsInDataDisplay();
  // }

  clearMergeList() {
    if (this.mergeList) {
      this.mergeList = [];
    }
  }

  clearSplitLines(undoItemGroup) {
    let self = this;
    let splitLines = self.getLayerDataFromCode("_SPLIT_LINE_");
    // console.log(splitLines);
    for (let i = splitLines.length - 1; i >= 0; i--) {
      self.deleteLineByID(splitLines[i].id, undoItemGroup);
    }
  }

  mergeSelected() {
    if (this.mergeList && this.mergeList.length > 1) {
      let undoItemGroup = [];
      this.mergePolygons(this.mergeList, undoItemGroup);
      this.undoRedoManager.pushToUndoStack(undoItemGroup);
      this.mergeList = [];
    }
  }

  deleteLayerByID(id, undoItemGroup) {
    let event = this.deleteRawData(CONSTANTS.DATA_OBJECTS.LAYER, id);
    if (event.index >= 0) {
      if (undoItemGroup) {
        undoItemGroup.push([{ ACTION: CONSTANTS.UNDO_ACTIONS.D, OBJECT: CONSTANTS.DATA_OBJECTS.LAYER, DATA: event.data }]);
      }
    }
  }

  getDataById(type, id) {
    // let layer ;
    // if(type===CONSTANTS.DATA_OBJECTS.POINT){

    //    layer = this.vectorPoints;
    // }
    // else if(type===CONSTANTS.DATA_OBJECTS.LINE){
    //     layer = this.vectorLines;
    // }
    // else if(type===CONSTANTS.DATA_OBJECTS.POLYGON){
    //     layer = this.vectorPolygon;
    // }
    // else if(type===CONSTANTS.DATA_OBJECTS.LAYER){
    //     layer = this.customLayer;
    // }

    // let ft = layer.getSource().getFeatureById(id);
    // if(ft){
    //    let  index = ft.get("data_index");
    //    if(index) {

    //        return {index: index, data: this.generatedData[type][index] }
    //    }
    // }

    ///May remove

    let i = this.generatedData[type].findIndex(function (obj) {
      return obj.id === id;
    });

    if (i >= 0) {
      return { index: i, data: this.generatedData[type][i] };
    }

    return { index: -1 };
  }

  getLayerMasterDataFromCode(layerCode) {
    let layerMasterData = this.options.layer_master.find(function (data) {
      return data.layer_code === layerCode;
    });
    return layerMasterData;
  }

  getLayerDataFromCode(layerCode) {
    let layerMaster = this.getLayerMasterDataFromCode(layerCode);
    let dataArray;
    switch (layerMaster.geom_type.toUpperCase()) {
      case "POINT":
      case "MULTIPOINT":
        dataArray = this.generatedData.points;
        break;
      case "LINESTRING":
      case "MULTILINESTRING":
        dataArray = this.generatedData.lines;
        break;
      case "POLYGON":
      case "MULTIPOLYGON":
        dataArray = this.generatedData.polygons;
        break;
    }

    return dataArray.filter(function (data) {
      return data.layer_code === layerCode;
    });
    // return dataArray;
  }

  getVectorLayer(layerCode) {
    let layerMaster = this.getLayerMasterDataFromCode(layerCode);
    let dataArray;
    switch (layerMaster.geom_type.toUpperCase()) {
      case "POINT":
      case "MULTIPOINT":
        dataArray = this.vectorPoints;
        break;
      case "LINESTRING":
      case "MULTILINESTRING":
        dataArray = this.vectorLines;
        break;
      case "POLYGON":
      case "MULTIPOLYGON":
        dataArray = this.vectorPolygon;
        break;
    }

    return dataArray;
  }

  getDataObjectsForGeom(geomType) {
    if (geomType.toLowerCase().indexOf("point") !== -1) {
      return CONSTANTS.DATA_OBJECTS.POINT;
    } else if (geomType.toLowerCase().indexOf("line") !== -1) {
      return CONSTANTS.DATA_OBJECTS.LINE;
    } else if (geomType.toLowerCase().indexOf("polygon") !== -1) {
      return CONSTANTS.DATA_OBJECTS.POLYGON;
    }
  }

  getDataFromGenLayers(id, geomid) {
    let retData = [];
    let reqData = this.generatedLayers;
    if (id) {
      for (const d of reqData) {
        if (d.id === id) {
          return d;
        }
      }
    } else if (geomid) {
      for (const d of reqData) {
        if (d.geom_id === geomid) {
          retData.push(d);
        }
      }
      return retData;
    }
  }

  getHash(str) {
    str = str.replace(/\s+/g, "");
    var hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  checkPolygonAlreadyExist(hash, layerCode) {
    //let reqData = this.getAllData("polygons");
    return this.generatedData.polygons.find(function (data) {
      return data.layer_code === layerCode && data.hash === hash;
    });

    // for (const rd of this.generatedData.polygons) {
    //     if (rd.hash === hash) {
    //         return true
    //     }
    // }
    // return false
  }

  addActionButton(btObj) {
    let self = this;
    switch (btObj.action) {
      case CONSTANTS.ACTION_BUTTON_ACTIONS.PICK_COORDINATE:
        this.uiBuilder.addActionButton(btObj.icon, btObj.text, btObj.style, function () {
          self.interactionManager.removeCurrentInteraction();
          self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PICK_COORDINATE, {
            event_source: "EXTERNAL_ACTION_BUTTON",
            target_function: btObj.on_complete,
          });
        });
        break;

      case CONSTANTS.ACTION_BUTTON_ACTIONS.PICK_COORDINATE_MULTI:
        this.uiBuilder.addActionButton(btObj.icon, btObj.text, btObj.style, function () {
          self.interactionManager.removeCurrentInteraction();
          self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PICK_COORDINATE_MULTI, {
            event_source: "EXTERNAL_ACTION_BUTTON",
            target_function: btObj.on_complete,
          });
        });
        break;

      case CONSTANTS.ACTION_BUTTON_ACTIONS.DRAW_POLYGON:
        this.uiBuilder.addActionButton(btObj.icon, btObj.text, btObj.style, function () {
          self.interactionManager.removeCurrentInteraction();
          self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_POLYGON, {
            event_source: "EXTERNAL_ACTION_BUTTON",
            target_function: btObj.on_complete,
          });
        });
        break;

      case CONSTANTS.ACTION_BUTTON_ACTIONS.DRAW_LINE:
        this.uiBuilder.addActionButton(btObj.icon, btObj.text, btObj.style, function () {
          self.interactionManager.removeCurrentInteraction();
          self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, {
            event_source: "EXTERNAL_ACTION_BUTTON",
            target_function: btObj.on_complete,
          });
        });
        break;

      case CONSTANTS.ACTION_BUTTON_ACTIONS.PICK_FEATURE:
        this.uiBuilder.addActionButton(btObj.icon, btObj.text, btObj.style, function () {
          let options = { event_source: "EXTERNAL_ACTION_BUTTON", target_function: btObj.on_complete };
          if (btObj.options && btObj.options.layer_code) {
            options.layer_code = btObj.options.layer_code;
          } else {
            options.layer_code = self.uiBuilder.getSelectedLayerCode();
          }
          self.interactionManager.removeCurrentInteraction();
          self.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, options);
        });
        break;

      default:
        this.uiBuilder.addActionButton(btObj.icon, btObj.text, btObj.style, btObj.on_complete);
        break;
    }
  }

  createTempLine(pt1ID, pt2ID, category) {
    var self = this;

    let pt1 = self.vectorPoints.getSource().getFeatureById(pt1ID);
    let pt2 = self.vectorPoints.getSource().getFeatureById(pt2ID);

    var c1 = pt1.getGeometry().getCoordinates();
    var c2 = pt2.getGeometry().getCoordinates();

    var featureC = new Feature({
      geometry: new LineString([c1, c2]),
      name: "",
      category: category,
    });
    self.tempLayer.getSource().addFeature(featureC);
  }

  createAnglePoint(ptDistance, ptAngleDegree, pt1ID, pt2ID) {
    var c, pt1, pt2;
    var accDistance = 0;
    var mulFactor = 1;

    pt1 = this.getDataById("points", pt1ID);
    pt2 = this.getDataById("points", pt2ID);
    var p1 = this._jstsReader.read(pt1.data.geom);
    var p2 = this._jstsReader.read(pt2.data.geom);
    if (accDistance + DistanceOp.distance(p1, p2) * mulFactor < ptDistance) {
      accDistance += DistanceOp.distance(p1, p2) * mulFactor;
      p1 = p2;
    } else if (accDistance == ptDistance) {
      c = p2;
    } else {
      var r1 = ptDistance - accDistance;
      var r2 = DistanceOp.distance(p1, p2) * mulFactor - r1;
      var x1 = (r1 * p2.getX() + r2 * p1.getX()) / (r1 + r2);
      var y1 = (r1 * p2.getY() + r2 * p1.getY()) / (r1 + r2);
      c = [x1, y1];
    }
    var ptAngle = Number(ptAngleDegree) * (Math.PI / 180);
    var slope = (p2.getY() - p1.getY()) / (p2.getX() - p1.getX());
    if (p1.getX() > p2.getX()) {
      ptAngle = (Number(ptAngleDegree) + 180) * (Math.PI / 180);
    }
    var initalAngle = Math.atan(slope);
    var cX = p1.getX() + Number(ptDistance) * Math.cos(Number(ptAngle + initalAngle));
    var cY = p1.getY() + Number(ptDistance) * Math.sin(Number(ptAngle + initalAngle));
    //working is based on maths sin and cos function

    let pt = new Point([cX, cY]);
    this.showTempGeom([pt], document.getElementById("anglePointName").value, "PT");

    let ln2 = new LineString([
      [p1.getX(), p1.getY()],
      [cX, cY],
    ]);

    this.showTempGeom([ln2], "", "");
    // if (generate) {
    //     let wktPt = this._wktFormat.writeGeometry(pt);
    //     let pointData = { "name": (document.getElementById('anglePointName').value), "geom": wktPt, "type": Gis.POINTTYPE.CREATED, "idx": 1 };
    //     this.preCreatePoint(pointData);
    //     this.clearTempGeom();
    //     this.anglePointUI.close();
    //     this.anglePointUI = null;
    // }
  }

  createOffsetPoint(pt1ID, pt2ID, ladderDistance, offsetDistance, ptName, direction = "LEFT") {
    let self = this;
    var c, pt1, pt2;
    var accDistance = 0;
    var mulFactor = 1;
    let ft1 = self.vectorPoints.getSource().getFeatureById(pt1ID);
    let ft2 = self.vectorPoints.getSource().getFeatureById(pt2ID);
    let pt1y = ft1.getGeometry().getCoordinates()[1];
    let pt2y = ft2.getGeometry().getCoordinates()[1];

    if (direction.toUpperCase() === "LEFT") {
      offsetDistance = offsetDistance * 1;
    } else {
      offsetDistance = offsetDistance * -1;
    }

    // if (pt1y < pt2y) {

    pt1 = this.getDataById("points", pt1ID);
    pt2 = this.getDataById("points", pt2ID);

    // } else {
    //     pt1 = this.getDataById("points", pt2ID);
    //     pt2 = this.getDataById("points", pt1ID);
    // }
    var p1 = this._jstsReader.read(pt1.data.geom);
    var p2 = this._jstsReader.read(pt2.data.geom);
    // while (accDistance < ladderDistance) {
    // if (accDistance + p1.distance(p2) * mulFactor < ladderDistance) {
    //     accDistance += p1.distance(p2) * mulFactor;
    //     p1 = p2;
    // } else if (accDistance == ladderDistance) {
    //     c = p2;
    //     // break;
    // } else {
    //         var r1 = ladderDistance; //(ladderDistance - accDistance);
    //         var r2 = p1.distance(p2) * mulFactor - r1;
    //         var x1 = (r1 * p2.getX() + r2 * p1.getX()) / (r1 + r2);
    //         var y1 = (r1 * p2.getY() + r2 * p1.getY()) / (r1 + r2);
    //         c = [x1, y1];
    //         // break;
    //     // }
    // // }
    // let pt = new Point(c);
    // let newNode = this._jstsReader.read(this._wktFormat.writeGeometry(new Point(c)));
    // if ((p1.distance(newNode)) < ((p1.distance(p2)) - 2)) {
    //     this.genTempLine();
    //this.showTempGeom([pt], (document.getElementById('offsetPointDistance').value), "l");
    let ptOnline = self.pointAlongLine(p1.getX(), p1.getY(), p2.getX(), p2.getY(), ladderDistance);

    var tmpSeg = new LineSegment(p1.getX(), p1.getY(), ptOnline[0], ptOnline[1]);
    var cr = tmpSeg.pointAlongOffset(1.0, offsetDistance);
    let fet = new Point([cr.x, cr.y]);
    let line1 = new LineString([
      [p1.getX(), p1.getY()],
      [p2.getX(), p2.getY()],
      [ptOnline[0], ptOnline[1]],
      [cr.x, cr.y],
    ]);
    this.showTempGeom([line1], "L");
    this.showTempGeom([fet], ptName, "PT");

    // if (generate) {
    //     let wktPt = this._wktFormat.writeGeometry(fet);
    //     let pointData = { "name": (document.getElementById('offsetPointDistance').value), "geom": wktPt, "type": Gis.POINTTYPE.CREATED, "idx": 1 };
    //     this.preCreatePoint(pointData);
    //     this.clearTempGeom();
    //     this.ladderPointUI.close();
    //     this.ladderPointUI = null;
    // }
    // } else {
    //     alert("Point cannot created.")
    // }
  }

  showTempGeom(geoms, tpName, category, id = "", styleDef = undefined, visible = false) {
    const self = this;
    let style;
    if (styleDef) {
      if (styleDef.geom_type?.toUpperCase() === "POLYGON" || styleDef.geom_type?.toUpperCase() === "MULTIPOLYGON") {
        style = function (feature, resolution) {
          return self.styleBuilder.createPolygonAreaStyle(feature, { attrs: feature.getProperties() }, resolution, styleDef.label, { ...styleDef.style_def });
        };
      } else if (styleDef.geom_type?.toUpperCase() === "LINESTRING" || styleDef.geom_type?.toUpperCase() === "MULTILINESTRING") {
        style = function (feature, resolution) {
          return self.styleBuilder.createLineStyle(feature, { attrs: feature.getProperties() }, resolution, styleDef.label, { ...styleDef.style_def });
        };
      }
      // else if (layerDef.style.geom_type?.toUpperCase() === "POINT" || layerDef.style.geom_type?.toUpperCase() === "MULTIPOINT"){
      //     style = function (feature, resolution) {

      //         self.styleBuilder.createSimpleStyle(feature, feature.getProperties(), resolution, layerDef.style.label, { ...layerDef.style.style_def });
      //     };
      // }
    }

    let features = [];
    geoms.forEach(function (geom) {
      let feature = new Feature({
        geometry: geom,
        name: tpName,
        label: tpName,
        category: category,
        id: id,
      });
      if (style) {
        feature.setStyle(style);
      }
      features.push(feature);
    });
    this.tempLayer.getSource().addFeatures(features);
    if (visible) {
      this.fitToGeometry(geoms[0]);
    }
  }

  showTempGeomWkt(geomwKT, tpName = "", category = "", id = "", styleDef = undefined, focus = false) {
    let self = this;
    let geom = self._wktFormat.readGeometry(geomwKT);
    self.showTempGeom([geom], tpName, category, id, styleDef , focus);
  }

  pointAlongLine(x1, y1, x2, y2, d) {
    var x3 = x2 - x1;
    var y3 = y2 - y1;
    var d3 = Math.sqrt(x3 * x3 + y3 * y3);
    x3 /= d3;
    y3 /= d3;
    x3 *= d;
    y3 *= d;
    return [x1 + x3, y1 + y3];
  }

  snapPointToSegment(ptCoords, segmentCoords) {
    let undoItemGroup = [];

    let l1 = new LineString(segmentCoords);
    let closestPt = l1.getClosestPoint(ptCoords);
    this.changePointCoordinate(ptCoords, closestPt, undoItemGroup);
    this.insertPointInSegment(segmentCoords[0], segmentCoords[1], closestPt, undoItemGroup);

    this.undoRedoManager.pushToUndoStack(undoItemGroup);
  }

  //    trimLine( sourceSegment ,destSegment){

  //     let l1 = new LineString(sourceSegment);
  //     let l2 = new LineString(destSegment);

  //     let jstsLine1 = this._jstsReader.read(this._wktFormat.writeGeometry(l1));
  //     let jstsLine2 = this._jstsReader.read(this._wktFormat.writeGeometry(l2));
  //     if(jstsLine1.intersects(jstsLine2)){
  //         let intersection = jstsLine1.intersection(jstsLine2).getCoordinate();
  //         let closestPt = sourceSegment[0];
  //         if(intersection.distance(new jsts.geom.Coordinate(sourceSegment[0][0],sourceSegment[0][1])) > intersection.distance(new jsts.geom.Coordinate(sourceSegment[1][0],sourceSegment[1][1])) ){
  //             closestPt = sourceSegment[1];
  //         }

  //         let undoItemGroup = [];
  //         this.changePointCoordinate(closestPt,[intersection.x, intersection.y],undoItemGroup);
  //         this.insertPointInSegment(destSegment[0], destSegment[1], [intersection.x, intersection.y], undoItemGroup);
  //         this.undoRedoManager.pushToUndoStack(undoItemGroup);

  //     }

  //    }

  trimLine(sourceSegment, destSegment) {
    let l1 = new LineSegment(sourceSegment[0][0], sourceSegment[0][1], sourceSegment[1][0], sourceSegment[1][1]);
    let l2 = new LineSegment(destSegment[0][0], destSegment[0][1], destSegment[1][0], destSegment[1][1]);

    let intersection = l1.lineIntersection(l2);
    if (intersection) {
      let closestPt = sourceSegment[0];
      if (
        intersection.distance(new Coordinate(sourceSegment[0][0], sourceSegment[0][1])) >
        intersection.distance(new Coordinate(sourceSegment[1][0], sourceSegment[1][1]))
      ) {
        closestPt = sourceSegment[1];
      }

      let undoItemGroup = [];
      this.changePointCoordinate(closestPt, [intersection.x, intersection.y], undoItemGroup);
      this.insertPointInSegment(destSegment[0], destSegment[1], [intersection.x, intersection.y], undoItemGroup);
      this.undoRedoManager.pushToUndoStack(undoItemGroup);
    }
  }
  //extent should be in a array format [xmin,ymin,xmax,ymax]
  setExtent(extent) {
    this.map.getView().fit(extent, this.map.getSize());
  }

  fitToGeometry(geoms) {
    let self = this;
    if (Array.isArray(geoms)) {
      if (geoms.length == 1) {
        let mapHeight = self.getMap().getSize()[1] / 2;
        let mapWidth = self.getMap().getSize()[0] / 2;
        this.map.getView().fit(geoms[0], { padding: [mapHeight - 250, mapWidth - 500, mapHeight - 250, mapWidth - 500], duration: 900 });
      } else {
        this.map.getView().fit(new GeometryCollection(geoms).getExtent(), { duration: 900 });
      }
    } else {
      let mapHeight = self.getMap().getSize()[1] / 2;
      let mapWidth = self.getMap().getSize()[0] / 2;
      this.map.getView().fit(geoms, { padding: [mapHeight - 250, mapWidth - 500, mapHeight - 250, mapWidth - 500], duration: 900 });

      // this.map.getView().setCenter(geoms.getFirstCoordinate());
    }
  }

  //parms - attributes configured in the layer, layerName -> layer_id of inp data ouputLayers
  updateParmsInLayer(parms, layerName) {
    let layer = this.map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("name") == layerName);
    if (layer) {
      layer.getSource().updateParams(parms);
    }
  }

  //Okay to use first time, but for proper map updations, should use updateParmsInLayer
  replaceParmsInLayer(parms, layerName) {
    let layer = this.map
      .getLayers()
      .getArray()
      .find((layer) => layer.get("name") == layerName);
    if (layer) {
      layer.getSource().params_ = parms;
    }
  }

  refreshLayers() {
    this.map.getLayers().forEach((layer) => layer.getSource().refresh());
  }

  findLayerById(id) {
    return this.map
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (layer.get("id") && layer.get("id") === id) {
          return layer;
        }
      });
  }

  removeLayer(layerId) {
    const self = this;
    this.map
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (layer && layer.get("id") && layer.get("id") === layerId) {
          self.getMap().removeLayer(layer);
        }
      });
  }

  showFeatureProperties(layerId) {
    const self = this;
    const data = [];
    this.map
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (
          [CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_FGB, CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GML, CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GEOJSON].includes(
            layer.get("layer_type")
          ) &&
          layer.get("id") &&
          layer.get("id") === layerId
        ) {
          let id = 1;
          layer.getSource().forEachFeature(function (feature) {
            let dataCopy = Object.assign({}, { __id: feature.getId(), ...feature.getProperties() });
            delete dataCopy.geometry;
            data.push(dataCopy);
            id++;
          });
        }
      });
    if (data.length > 0) {
      self.uiBuilder.showFeatureProperties(layerId, data);
    }
    return data.length;
  }

  focusToLayerFeature(layerId, featureId) {
    const self = this;
    self.clearTempGeom();
    this.map
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (
          [CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_FGB, CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GML, CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GEOJSON].includes(
            layer.get("layer_type")
          ) &&
          layer.get("id") &&
          layer.get("id") === layerId
        ) {
          let feature = layer.getSource().getFeatureById(featureId);
          if (feature) {
            if (feature.getGeometry().getFirstCoordinate().length > 0) {
              self.getMap().getView().setCenter(feature.getGeometry().getFirstCoordinate());
              self.showTempGeom([feature.getGeometry()], "", "", "", {
                style_def: {
                  show_segment_length: false,
                  show_area: false,
                  text_overflow: false,
                  fill_color: "rgba( 211, 47, 47 , 0.4)",
                  stroke_color: "rgba( 211, 47, 47 , 0.7)",
                },
                geom_type: feature.getGeometry().getType(),
              });
            }
          }
        }
      });
  }

  focusToLayer(layerId) {
    const self = this;
    self.clearTempGeom();
    this.map
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (
          [CONSTANTS.REFERENCE_MAP_TYPE.BHUNAKSHA_FGB, CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GML, CONSTANTS.REFERENCE_MAP_TYPE.VECTOR_GEOJSON].includes(
            layer.get("layer_type")
          ) &&
          layer.get("id") &&
          layer.get("id") === layerId
        ) {
          self.getMap().getView().fit(layer.getSource().getExtent());
        }
      });
  }

  // createStyle(options) {
  //     return new Style(options);
  // }
  // createStyleStroke(options) {
  //     return new Stroke(options);
  // }
  // createStyleText(options) {
  //     return new Text(options);
  // }
  // createStyleFill(options) {
  //     return new Fill(options);
  // }
  // createStyleCircle(options) {
  //     return new Circle(options);
  // }
  exportLayerAsGeoJson(layerCode) {
    let self = this;
    let dataArray = this.getLayerDataFromCode(layerCode);
    let features = [];
    dataArray.forEach(function (element) {
      let geometry = self._wktFormat.readGeometry(element.geom);

      let attr = { ...element.attrs };
      attr.geometry = geometry;

      let ftr = new Feature(attr);
      // ftr.setId(attr.id);
      features.push(ftr);
    });

    var json = new GeoJSON().writeFeatures(features, {
      dataProjection: self.options.output_proj,
      featureProjection: self.options.output_proj,
    });

    return json;
  }

  showInfoPanel(header, content, options = {}) {
    this.uiBuilder.showInfoPanel(header, content, options);
  }

  download(content, fileName, contentType) {
    var a = document.createElement("a");

    var file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);

    a.download = fileName;

    a.click();
  }

  locateMe() {
    let self = this;
    if (!this.geoTracker) {
      this.geoTracker = new GeoTracker(this);
    }
    this.geoTracker.startTracking();
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //             console.log(position);
    //             let lat = position.coords.latitude;
    //             let lon = position.coords.longitude;
    //             console.log(lat);
    //             console.log(lon);

    //             //  lon = 76.7777;
    //             //  lat = 9.2306;

    //             self.setCenter(lon, lat);

    //         });
    // }
  }

  downloadSelectedLayerAsGeoJson() {
    let layercode = this.uiBuilder.getSelectedLayerCode();
    this.download(this.exportLayerAsGeoJson(layercode), layercode + ".geojson", "text/plain");
  }

  getGPKGReader() {
    if (!this.gpkgReader) {
      this.gpkgReader = new GPKGReader(this);
    }
    return this.gpkgReader;
  }

  getGPKGWriter() {
    if (!this.gpkgWriter) {
      this.gpkgWriter = new GPKGWriter(this);
    }
    return this.gpkgWriter;
  }

  createVectorSource() {
    return new VectorSource();
  }

  setTouchMode(mode = false) {
    this.touchMode = mode;
    this.interactionManager.removeCurrentInteraction();
  }

  getTouchMode() {
    return this.touchMode;
  }

  hasMouse() {
    return matchMedia("(pointer:fine)").matches;
  }
  version() {
    return Digitizer.VERSION;
  }

  ////
}

export default Digitizer;
