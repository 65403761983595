import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

class GeoTracker {


    constructor(digitizer) {
        this.digitizer = digitizer;
        this.options = digitizer.options;

    }

    startTracking() {
        let self = this;

        if (!this.geolocation) {

            this.geolocation = new Geolocation({

                trackingOptions: {
                    enableHighAccuracy: true,
                },
                projection: self.digitizer.getMap().getView().getProjection(),
            });
            this.accuracyFeature = new Feature();
            this.geolocation.on('change:accuracyGeometry', function () {
                self.accuracyFeature.setGeometry(self.geolocation.getAccuracyGeometry());
            });

            this.positionFeature = new Feature();
            this.positionFeature.setStyle(
                new Style({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({
                            color: '#3399CC',
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                    }),
                })
            );

            this.geolocation.on('change:position', function () {
                const coordinates = self.geolocation.getPosition();
                self.positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
                if (coordinates) {


                    var visibleExtent = self.digitizer.getMap().getView().calculateExtent();

                    if (!self.positionFeature.getGeometry().intersectsExtent(visibleExtent)) {
                        self.digitizer.getMap().getView().setCenter(coordinates);
                    }

                }
            });

            this.vectorLayer = new VectorLayer({
                map: self.digitizer.getMap(),
                source: new VectorSource({
                    features: [self.accuracyFeature, self.positionFeature],
                }),
            });

            this.geolocation.setTracking(true);

        }
        else {

            if (this.geolocation.getTracking()) {
                this.geolocation.setTracking(false);
                self.vectorLayer.setVisible(false);
            }

            else {
                this.geolocation.setTracking(true);
                self.vectorLayer.setVisible(true);
            }


        }
    }



}

export { GeoTracker }