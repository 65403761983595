import { CONSTANTS } from "./Constants";
import Map from "ol/Map";
import View from "ol/View";
import GeoImageSource from "ol-ext/source/GeoImage";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import ImageLayer from "ol/layer/Image";
import { Fill, Stroke, Style, Circle, Text, Icon } from "ol/style";
import Feature from "ol/Feature";
import { Helmert } from "./Helmert";
import { Draw, Snap, Modify, Select } from "ol/interaction";
import { Point } from "ol/geom";
import { jsPanel } from "jspanel4";
class ImageLayerManager {
  constructor(digitizer) {
    this.digitizer = digitizer;
    this.id = null;
  }

  openMap2Popup(id) {
    var self = this;
    let vPh = window.innerHeight - 400;
    jsPanel.ziBase = 101;
    self.id = id;

    if (this.panelLocalFile && this.panelLocalFile !== null) {
      this.panelLocalFile.close();
      this.panelLocalFile = null;
    }

    self.panelLocalFile = jsPanel.create({
      headerTitle: "Open File",
      headerToolbar: `<span>Browse Image.</span><div class="flexDisplay fullWidth"><input type="file" class="form-control" id="_image_upload" data-browse-on-zone-click="true"/>
                `,
      footerToolbar: ` <button id="_add_geo_point_id" type="button" class=" customcontrol " title="Create" style="margin-left: 3px;">
                <img alt="" src="${self.digitizer.options.service_path + "assets"}/img/node-plus.svg" />
            </button>
            <button id="_crop_geom" type="button" class=" customcontrol " title="Crop Area" style="margin-left: 3px;">
            <img alt="" src="${self.digitizer.options.service_path + "assets"}/img/draw_polygon.svg" />
        </button>
            `,
      contentFetch: {
        resource: self.digitizer.uiBuilder.servicePathPanel + "_open_image.html",
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("_image_upload").addEventListener("change", function () {
            const file = document.getElementById("_image_upload").files[0];
            const reader = new FileReader();
            reader.fileName = file.name;
            reader.addEventListener(
              "load",
              function (evt) {
                // convert image file to base64 string
                //preview.src = reader.result;
                id = self.showMap2("_image_map_view", reader.result, id);
                self.id = id;
                self.geoTransformLayer(id, evt.target.fileName);
                self.refreshImageLayerTable();
              },
              false
            );

            if (file) {
              reader.readAsDataURL(file);
            }
            ``;
          });

          document.getElementById("_add_geo_point_id").addEventListener("click", function () {
            if (self.id) {
              self.removeCropPolygonInteraction();
              self.removeGeorefInteractions();

              self.addGeorefInteractions(self.id);

              self.fillGeorefPoints(self.id);
            }

            // self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACE_AND_MODIFY_POINT,{source:self.digitizer.tempLayer.getSource(),id:document.getElementById("_georef_map_id").value });
            // self.digitizer.interactionManager.addGeoRefInteraction(document.getElementById("_georef_map_id").value );
          });

          document.getElementById("_crop_geom").addEventListener("click", function () {
            self.removeCropPolygonInteraction();
            self.removeGeorefInteractions();
            self.addCropPolygonInteraction(self.id);
          });
          //self.refreshetsPoints();

          if (id) {
            self.showMap2("_image_map_view", "", id);
          }
        },
      },
      position: "right-top -40 120",
      contentSize: "460 " + vPh,
      borderRadius: ".5rem",
      theme: "dark",
      headerControls: {
        maximize: "remove",
        //close: 'remove'
      },
      resizeit: {
        stop: () => {
          if (self.map2) {
            self.map2.updateSize();
          }
        },
      },
      onbeforeclose: function (panel) {
        self.cleanup();
        return true;
      },
      onclosed: function (panel, closedByUser) {
        if (self.panelLocalFile) {
          //self.panelLocalFile.close();
          self.panelLocalFile = null;
        }
      },
    });
  }

  showMap2(target, url, id) {
    let self = this;

    if (id) {
      let layer = this.findLayerOfTypeById(id, CONSTANTS.LAYERTYPE.IMAGE_LAYER);
      if (layer) {
        url = layer.getSource().getUrl();
      }
    } else {
      id = this.digitizer.createID();
      console.log("ID " + id);
    }

    if (this.map2) {
      this.map2.setTarget(null);
      this.mp2 = null;
    }

    this.map2 = new Map({
      target: target,
      view: new View({
        zoom: 14,
        center: [0, 0],
        projection: self.digitizer.options.output_proj,
      }),
    });

    var geoimg = new ImageLayer({
      name: "Georef",
      layer_type: CONSTANTS.LAYERTYPE.IMAGE_LAYER,
      //opacity: .7,
      id: id,
      source: new GeoImageSource({
        url: url,
        imageCenter: [0, 0],
        imageScale: [1, 1],
        // imageCrop: [xmin,ymin,xmax,ymax],
        //imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
        imageRotate: 0,
        projection: self.digitizer.options.output_proj,
      }),
    });

    //   let osm = new TileLayer({
    //     name: "layerDef.layer_id",
    //     source: new OSM()
    // });

    this.map2.addLayer(geoimg);

    let georefLayer = new VectorLayer({
      name: CONSTANTS.LAYER_NAMES.GEOREF_LAYER,
      layer_type: CONSTANTS.LAYERTYPE.GEOREF_LAYER,
      id: id,
      source: new VectorSource(),
      style: function (feature, resolution) {
        var styles = [
          new Style({
            stroke: new Stroke({
              color: "rgba(0, 153, 255, 0.9)",
              width: 1,
            }),
            fill: new Fill({
              color: "rgba(234, 128, 252, 0.1)",
            }),
            image: new Circle({
              radius: 12,
              fill: new Fill({ color: "rgba(61, 90, 254, 0.1)" }),
              stroke: new Stroke({
                color: "rgba(0, 153, 255, 0.9)",
                width: 1,
              }),
            }),
            text: new Text({
              placement: feature.getGeometry().getType() === "LineString" ? "line" : "middle",
              textBaseline: feature.getGeometry().getType() === "LineString" ? "bottom" : "middle",
              textAlign: "center",
              // textBaseline: 'middle',
              text: feature.get("label"),
              fill: new Fill({ color: "rgba(0, 153, 255, 0.9)" }),
              offsetX: 2,
              offsetY: 2,
            }),
          }),
        ];
        return styles;
      },
    });

    georefLayer.getSource().on("addfeature", function (event) {
      event.feature.set("label", "" + georefLayer.getSource().getFeatures().length);
      self.geoTransformLayer(id);
    });

    georefLayer.getSource().on("changefeature", function (event) {
      self.geoTransformLayer(id);
    });

    this.map2.addLayer(georefLayer);

    let cropLayer = new VectorLayer({
      name: CONSTANTS.LAYER_NAMES.CROP_LAYER,
      layer_type: CONSTANTS.LAYERTYPE.CROP_LAYER,
      id: id,
      source: new VectorSource(),
      style: function (feature, resolution) {
        var styles = [
          new Style({
            stroke: new Stroke({
              color: "rgba(0, 153, 255, 0.9)",
              width: 1,
            }),
            fill: new Fill({
              color: "rgba(234, 128, 252, 0.1)",
            }),
            image: new Circle({
              radius: 12,
              fill: new Fill({ color: "rgba(61, 90, 254, 0.1)" }),
              stroke: new Stroke({
                color: "rgba(0, 153, 255, 0.9)",
                width: 1,
              }),
            }),
            text: new Text({
              placement: feature.getGeometry().getType() === "LineString" ? "line" : "middle",
              textBaseline: feature.getGeometry().getType() === "LineString" ? "bottom" : "middle",
              textAlign: "center",
              // textBaseline: 'middle',
              text: feature.get("label"),
              fill: new Fill({ color: "rgba(0, 153, 255, 0.9)" }),
              offsetX: 2,
              offsetY: 2,
            }),
          }),
        ];
        return styles;
      },
    });

    this.map2.addLayer(cropLayer);

    return id;
  }

  fillGeorefPoints(id) {
    if (id) {
      let refLayer = this.findLayerOfTypeById(id, CONSTANTS.LAYERTYPE.IMAGE_LAYER);
      if (refLayer) {
        let destPoints = refLayer.get("geo_dest");
        if (destPoints) {
          this.geoRefPointsLayer1.getSource().clear();
          for (let i = 0; i < destPoints.length; i++) {
            var featureC = new Feature({
              geometry: new Point(destPoints[i]),
              label: i + 1 + "",
            });
            this.geoRefPointsLayer1.getSource().addFeature(featureC);
          }
        }

        let origLayer = this.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.GEOREF_LAYER);
        if (origLayer) {
          let destPoints = refLayer.get("geo_src");
          if (destPoints) {
            origLayer.getSource().clear();
            for (let i = 0; i < destPoints.length; i++) {
              var featureC = new Feature({
                geometry: new Point(destPoints[i]),
                label: i + 1 + "",
              });
              origLayer.getSource().addFeature(featureC);
            }
          }
        }

        let cropLayer = this.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.CROP_LAYER);
        if (cropLayer) {
          let cropGeom = refLayer.get("crop");
          if (cropGeom) {
            var featureC = new Feature({
              geometry: cropGeom,
            });
            cropLayer.getSource().addFeature(featureC);
          }
        }
      }
    }
  }

  geoTransformLayer(id, fileName) {
    let self = this;
    let layer = this.findLayerOfTypeById(id, CONSTANTS.LAYERTYPE.IMAGE_LAYER);
    fileName = fileName ? fileName : "Georef";
    if (!layer) {
      let layer2 = this.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.IMAGE_LAYER);

      if (layer2) {
        layer = new ImageLayer({
          name: fileName,
          id: id,
          layer_type: CONSTANTS.LAYERTYPE.IMAGE_LAYER,
          //opacity: .7,
          zIndex: 40,
          source: new GeoImageSource({
            url: layer2.getSource().getGeoImage().src,
            imageCenter: [0, 0],
            imageScale: [1, 1],
            // imageCrop: [xmin,ymin,xmax,ymax],
            //imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
            imageRotate: 0,
            projection: self.digitizer.options.output_proj,
          }),
        });

        this.digitizer.getMap().addLayer(layer);
      }
    }

    if (layer) {
      let srcGeorefLayer = self.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.GEOREF_LAYER);

      let xy = [],
        gxy = [];
      if (srcGeorefLayer && this.geoRefPointsLayer1) {
        this.geoRefPointsLayer1.getSource().forEachFeature(function (feature) {
          let feature2 = srcGeorefLayer
            .getSource()
            .getFeatures()
            .find(function (f) {
              if (f.get("label") === feature.get("label")) {
                return true;
              }
            });
          if (feature2) {
            xy.push(feature2.getGeometry().getCoordinates());
            gxy.push(feature.getGeometry().getCoordinates());
          }
        });
      }

      if (xy.length > 1) {
        let helmerttransform = new Helmert({});
        helmerttransform.setControlPoints(xy, gxy);
        var sc = helmerttransform.getScale();
        var a = helmerttransform.getRotation();
        var t = helmerttransform.getTranslation();

        layer.getSource().setCenter(t);
        layer.getSource().setRotation(a);
        layer.getSource().setScale(sc);

        layer.set("geo_src", xy);
        layer.set("geo_dest", gxy);

        let cropLayer = this.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.CROP_LAYER);

        if (cropLayer && cropLayer.getSource().getFeatures().length > 0) {
          let c = cropLayer.getSource().getFeatures()[0].getGeometry().getCoordinates()[0];
          layer.set("crop", cropLayer.getSource().getFeatures()[0].getGeometry());
          for (var i = 0; i < c.length; i++) {
            c[i] = helmerttransform.transform(c[i]);
          }
          layer.getSource().setMask(c);
        }
      }
    }
  }

  addGeorefInteractions(id) {
    let self = this;

    if (!this.geoRefPointsLayer1) {
      this.geoRefPointsLayer1 = new VectorLayer({
        name: CONSTANTS.LAYER_NAMES.GEOREF_LAYER,
        layer_type: CONSTANTS.LAYERTYPE.GEOREF_LAYER,
        id: id,
        zIndex: 80,
        source: new VectorSource(),
        style: function (feature, resolution) {
          var styles = [
            new Style({
              stroke: new Stroke({
                color: "rgba(0, 153, 255, 0.9)",
                width: 1,
              }),
              fill: new Fill({
                color: "rgba(234, 128, 252, 0.1)",
              }),
              image: new Circle({
                radius: 12,
                fill: new Fill({ color: "rgba(61, 90, 254, 0.1)" }),
                stroke: new Stroke({
                  color: "rgba(0, 153, 255, 0.9)",
                  width: 1,
                }),
              }),
              text: new Text({
                placement: feature.getGeometry().getType() === "LineString" ? "line" : "middle",
                textBaseline: feature.getGeometry().getType() === "LineString" ? "bottom" : "middle",
                textAlign: "center",
                // textBaseline: 'middle',
                text: feature.get("label"),
                fill: new Fill({ color: "rgba(0, 153, 255, 0.9)" }),
                offsetX: 2,
                offsetY: 2,
              }),
            }),
          ];
          return styles;
        },
      });

      this.digitizer.getMap().addLayer(this.geoRefPointsLayer1);
    }
    let source = this.geoRefPointsLayer1.getSource();

    if (!this.pointDrawInteractionMap1) {
      this.pointDrawInteractionMap1 = new Draw({
        source: source,
        type: "Point",

        condition: function (event) {
          return true;
        },
      });

      this.pointDrawInteractionMap1.on("drawend", function (event) {
        event.feature.set("label", `${source.getFeatures().length + 1}`);
        self.geoTransformLayer(id);
      });
      this.pointMoveInteractionMap1 = new Modify({
        source: source,
        type: "Point",

        condition: function (event) {
          return true;
        },
      });

      this.pointMoveInteractionMap1.on("modifyend", function (event) {
        self.geoTransformLayer(id);
      });
    }

    if (!this.snapPointInteractionMap1) {
      this.snapPointInteractionMap1 = new Snap({
        source: source,
      });
    }

    // if (!this.snapLineInteraction) {
    //     this.snapLineInteraction = new Snap({
    //         source: source
    //     });

    // }

    this.digitizer.getMap().addInteraction(this.pointDrawInteractionMap1);
    this.digitizer.getMap().addInteraction(this.pointMoveInteractionMap1);
    // this.digitizer.getMap().addInteraction(this.snapLineInteraction);
    this.digitizer.getMap().addInteraction(this.snapPointInteractionMap1);

    let georefPointsLayer2 = this.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.GEOREF_LAYER);

    if (georefPointsLayer2) {
      if (!this.pointDrawInteractionMap2) {
        this.pointDrawInteractionMap2 = new Draw({
          source: georefPointsLayer2.getSource(),
          type: "Point",

          condition: function (event) {
            return true;
          },
        });

        this.pointMoveInteractionMap2 = new Modify({
          source: georefPointsLayer2.getSource(),
          type: "Point",

          condition: function (event) {
            return true;
          },
        });

        this.map2.addInteraction(this.pointDrawInteractionMap2);
        this.map2.addInteraction(this.pointMoveInteractionMap2);
      }
    }
  }

  removeGeorefInteractions() {
    if (this.pointDrawInteractionMap1) {
      this.digitizer.getMap().removeInteraction(this.pointDrawInteractionMap1);
    }

    if (this.pointMoveInteractionMap1) {
      this.digitizer.getMap().removeInteraction(this.pointMoveInteractionMap1);
    }
    if (this.snapPointInteractionMap1) {
      this.digitizer.getMap().removeInteraction(this.snapPointInteractionMap1);
    }
    if (this.pointDrawInteractionMap2) {
      this.map2.removeInteraction(this.pointDrawInteractionMap2);
    }
    if (this.pointMoveInteractionMap2) {
      this.map2.removeInteraction(this.pointMoveInteractionMap2);
    }

    this.pointDrawInteractionMap1 = null;
    this.pointMoveInteractionMap1 = null;
    this.snapPointInteractionMap1 = null;
    this.pointDrawInteractionMap2 = null;
    this.pointMoveInteractionMap2 = null;
  }

  addCropPolygonInteraction(id) {
    let self = this;
    this.removeGeorefInteractions();
    let cropLayer = this.findLayerOfTypeByIdMap2(id, CONSTANTS.LAYERTYPE.CROP_LAYER);

    //cropLayer.getSource().clear();

    if (!this.drawCropPolygonInteraction) {
      this.drawCropPolygonInteraction = new Draw({
        source: cropLayer.getSource(),
        type: "Polygon",
        // geometryFunction: createBox(),
        condition: function (event) {
          if (cropLayer.getSource().getFeatures().length > 0) {
            return false;
          } else {
            return true;
          }
        },
      });
    }

    if (!this.modifyCropPolygonInteraction) {
      this.modifyCropPolygonInteraction = new Modify({
        source: cropLayer.getSource(),
        type: "Polygon",
        // geometryFunction: createBox(),
        condition: function (event) {
          return true;
        },
      });
    }

    // cropLayer.getSource().on("addfeature", function (event) {

    //     self.digitizer.georefSetCrop(id);
    // });

    // this.digitizer.getMap().addLayer(this.interactionLayer);

    this.map2.addInteraction(this.drawCropPolygonInteraction);
    this.map2.addInteraction(this.modifyCropPolygonInteraction);

    // this.setCursor("crosshair");
  }

  removeCropPolygonInteraction() {
    if (this.drawCropPolygonInteraction) {
      this.map2.removeInteraction(this.drawCropPolygonInteraction);
    }
    if (this.modifyCropPolygonInteraction) {
      this.map2.removeInteraction(this.modifyCropPolygonInteraction);
    }
    this.drawCropPolygonInteraction = null;
    this.modifyCropPolygonInteraction = null;
  }

  refreshImageLayerTable() {
    var self = this;

    if (document.getElementById("imagelayer_table_info").classList.contains("DN")) {
      document.getElementById("imagelayer_table_info").classList.toggle("DN");
    }
    let dest = document.getElementById("imagelayer_table").getElementsByTagName("tbody")[0];
    dest.innerHTML = ""; //if delete needs to be implemented, then we can maintain state
    self.findLayerByTypeMap(CONSTANTS.LAYERTYPE.IMAGE_LAYER).forEach(function (layer) {
      let trElm = document.createElement("tr");

      let tdELm = document.createElement("td");
      tdELm.classList.add("mapiconappend");
      tdELm.innerHTML = layer.get("name");
      trElm.appendChild(tdELm);

      tdELm = document.createElement("td");
      tdELm.setAttribute("style", "padding-right:1rem;");
      tdELm.classList.add("mapiconappend");
      let buttonELm = document.createElement("button");
      buttonELm.setAttribute("layerid", layer.get("id"));
      buttonELm.setAttribute("style", "float:right");
      buttonELm.classList.add("btn");
      buttonELm.classList.add("btn-outline-info");
      buttonELm.innerHTML = "View";
      buttonELm.addEventListener("click", function () {
        self.openMap2Popup(this.getAttribute("layerid"));
      });
      tdELm.appendChild(buttonELm);
      trElm.appendChild(tdELm);

      dest.appendChild(trElm);
    });
  }

  findLayerByTypeMap(layerType) {
    return this.digitizer
      .getMap()
      .getLayers()
      .getArray()
      .filter(function (layer) {
        return layer.get("layer_type") && layer.get("layer_type") === layerType;
      });
  }

  findLayerOfTypeByIdMap2(id, layerType) {
    return this.map2
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (layer.get("id") && layer.get("id") === id && layer.get("layer_type") && layer.get("layer_type") === layerType) {
          return true;
        }
      });
  }

  findLayerOfTypeById(id, layerType) {
    return this.digitizer
      .getMap()
      .getLayers()
      .getArray()
      .find(function (layer) {
        if (layer.get("id") && layer.get("id") === id && layer.get("layer_type") && layer.get("layer_type") === layerType) {
          return true;
        }
      });
  }

  cleanup() {
    this.removeCropPolygonInteraction();
    this.removeGeorefInteractions();
    if (this.map2) {
      this.map2.setTarget(null);
      this.map2 = null;
    }
    if (this.geoRefPointsLayer1) {
      this.digitizer.getMap().removeLayer(this.geoRefPointsLayer1);
      this.geoRefPointsLayer1 = null;
    }
  }
}

export { ImageLayerManager };
