const defaultOptions = {

    service_path: "digitization/",
    location_code: "",
    map_extent: [698821, 960922.0145209, 699772.9850077, 961555],
    map_center: [699772.9850077, 961555],
    proj_defs: [
        ['EPSG:4326', '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
        ['EPSG:32642', '+proj=utm +zone=42 +datum=WGS84 +units=m +no_defs'],
        ['EPSG:32643', '+proj=utm +zone=43 +datum=WGS84 +units=m +no_defs']
    ],
    unit: "m",
    config: {
        "view_only": false,
        "attributes_display": "combo"
    },
    output_proj: 'EPSG:32643',
    output_layers: [
        {
            layer_id: "bing_aerial",
            name: "Bing",
            base_map: true,
            map_type: 'BING',
            visible: false,
            source_parms: {
                key: "AubA5Q_W9vDgaBL-KaNAwAX9kQFQyEyLB5Z-UUSR_3nKtvP7gnFBLLJzgJCeeaQz",
                imagerySet: "AerialWithLabelsOnDemand"
            }
        },
        {
            layer_id: "bhuvan_satellite",
            name: "Bhuvan",
            base_map: true,
            map_type: 'BHUVAN',
            visible: false,
            source_parms: {
                url: 'https://bhuvan-ras2.nrsc.gov.in/tilecache/tilecache.py',
                params: {
                    'VERSION': '1.1.1',
                    'LAYERS': 'bhuvan_imagery',
                    'FORMAT': 'image/jpeg',
                    'TILED': true
                },
                attributions: {
                    html: 'Source: Bhuvan  Â© <a href="http://bhuvan.nrsc.gov.in/" target="_blank">Bhuvan</a>'
                },
                projection: 'EPSG:4326'
            }
        },
        {
            layer_id: "osm",
            name: "Open Street Maps",
            base_map: true,
            visible: false,
            map_type: 'OSM',
            source_parms: {}
        },

    ],
    scaleFactor: 1,
    
    layer_master: [


        {
            layer_code: "_DEFAULT_POINT_",
            layer_name: "Default Point Layer",
            geom_type: "Point",
            user_layer: false,
            label: '{name}',
            hide_from_list: true,
            style_def: {image: "circle", size: 12, fill_color: 'rgba(240, 98, 146, 0.7)', text_color: 'rgba(33, 33, 33, 0.8)'},
            //attrs: [{ "key": "name", "name": "Name", "name_en": "name", "type": "String" }]
            
        },
        {
            layer_code: "_DEFAULT_LINE_",
            layer_name: "Default Line Layer",
            geom_type: "LineString",
            user_layer: false,
            label: '{name}',
            hide_from_list: true,
            style_def: {show_segment_length: true, stroke_width: 1, stroke_color:  [237, 212, 0, 0.8]},
            //attrs: [{ "key": "name", "name": "Name", "name_en": "name", "type": "String" }]
        },
        {
            layer_code: "_DEFAULT_POLYGON_",
            layer_name: "Default Polygon",
            geom_type: "Polygon",
            user_layer: false,
            label: '{name}',
            hide_from_list: true,
            style_def: {show_segment_length: true, show_area: true, fill_color: 'rgba(0, 146, 0, 0.2)', },
            //attrs: [{ "key": "name", "name": "Name", "name_en": "name", "type": "String" }]
        },
        // {
        //     layer_code: "_POLYGONIZER_LINE_",
        //     layer_name: "Polygonizer Line",
        //     geom_type: "LineString",
        //     user_layer: false,
        //     style_def: {show_segment_length: true, stroke_width: 1, stroke_color:  [237, 212, 0, 0.8]},
            
        // },

        {
            layer_code: "_SPLIT_LINE_",
            layer_name: "Split Line",
            geom_type: "LineString",
            user_layer: false,
            label: '{name}',
            hide_from_list: true,
            ignore_points: false,
            style_def: {show_segment_length: true, stroke_width: 1, stroke_color:  'rgba(240, 0, 0, 0.7)' },
            
        },

        {
            layer_code: "_LINE_MEASUREMENT_",
            layer_name: "Linear Measurement",
            geom_type: "LineString",
            user_layer: false,
            ignore_points: true,
            label: '{name}',
            hide_from_list: true,
            style_def: {show_segment_length: true, stroke_width: 1, stroke_color:  'rgba(240, 240, 0, 0.7)'  },
           
        },

        {
            layer_code: "_AREA_MEASUREMENT_",
            layer_name: "Area Measurement",
            geom_type: "Polygon",
            user_layer: false,
            ignore_points: true,
            label: '{name}',
            hide_from_list: true,
            style_def: {show_segment_length: true, show_area: true, fill_color: 'rgba(0, 0, 146, 0.2)', },
           
        },

    ],

    action_buttons: [
        {action:"PICK_COORDINATE", icon: 'img/node-plus.svg', text: "Load Data for Edit", on_complete: function(coordinate){
            console.log(coordinate);
        }, options:{}}
    ],
    search_button_function: undefined,
    locate_me_button: true,
    full_screen_button: true,
    hide_toolbar: false,
    default_click_function: function(coordinate){
        console.log(coordinate);
    },

};

export { defaultOptions };