import { LineString, MultiPoint } from "ol/geom";
import { Circle, Fill, Stroke, Style, Text } from "ol/style";
import { Strings } from './utils';


class StyleBuilder {

    createPointCircleStyle(feature, layerData, resolution, labelField = '', options = {}) {

        const defaultOptions = {
            radius: 12,
            fill_color: 'rgba(198, 255, 0, 0.9)',
            text_align: 'center',
            text_base_line: 'middle',
            text_color: 'rgba(33, 33, 33, 0.8)',
            offset_x: 2,
            offset_y: 2
        }

        const newOptions = Object.assign(defaultOptions, options);


        let style = new Style({
            image: new Circle({
                radius: newOptions.radius,
                fill: new Fill({ color: newOptions.fill_color }),
            }),

        });

        if (labelField && labelField !== '') {

            let label = Strings.substitute(labelField, { ...layerData.attrs, "name": feature.get("name") })
            let text = new Text({
                textAlign: newOptions.text_align,
                textBaseline: newOptions.text_base_line,
                text: label,
                fill: new Fill({ color: newOptions.text_color }),
                offsetX: newOptions.offset_x,
                offsetY: newOptions.offset_y
            });

            style.setText(text);

        }

        return style;

    }

    createLineMeasurementStyle(feature, resolution, options = {}) {

        const defaultOptions = {
            stroke_width: 1,
            stroke_color: [237, 212, 0, 0.8],
            text_placement: 'line',
            text_base_line: 'bottom',
            text_color: 'rgba(0, 0, 0, 0.7)',
            text_stroke_color: '#fff',
            text_stroke_width: 3
        }

        const newOptions = Object.assign(defaultOptions, options);


        let st = new Style({
            stroke: new Stroke({
                width: newOptions.stroke_width,
                color: newOptions.stroke_color,
            }),

            text: new Text({
                placement: newOptions.text_placement,
                textBaseline: newOptions.text_base_line,
                text: 'A',
                fill: new Fill({ color: newOptions.text_color }),
                stroke: new Stroke({
                    color: newOptions.text_stroke_color,
                    width: newOptions.text_stroke_width
                })
            })
        });

        let styles = [];
        // let style = new Style({
        //     geometry: feature.getGeometry(),
        //     stroke: new Stroke({
        //         width: 1,
        //         color: [255, 160, 0, 1], //237, 212, 0, 0.8
        //     }),
        //     text: new Text({
        //         placement: 'line',
        //         textBaseline: 'bottom',
        //         text: feature.get('length'),
        //         fill: new Fill({ color: 'rgba(0, 0, 0, 0.7)' }),
        //     })
        // });

        // styles.push(style);

        feature.getGeometry().forEachSegment(function (a, b) {

            const segment = new LineString([a, b]);
            const label = segment.getLength().toFixed(2);
            let st1 = st.clone();
            st1.setGeometry(segment);
            st1.getText().setText(label);
            styles.push(st1);
            //  segStyle.setGeometry(segment);
            // styles.push(segStyle);
        });


        return styles;

    }

    createLineStyle(feature, layerData, resolution, labelField = '', options = {}, showSegmentLength = false) {

        const defaultOptions = {
            stroke_width: 1,
            stroke_color: [237, 212, 0, 0.8],
            text_placement: 'line',
            text_base_line: 'bottom',
            text_color: 'rgba(0, 0, 0, 0.7)',
            text_stroke_color: '#fff',
            text_stroke_width: 3,
            text_overflow: true,
        }

        const newOptions = Object.assign(defaultOptions, options);

        if (newOptions.show_segment_length) {
            showSegmentLength = newOptions.show_segment_length;
        }

        let styles = [];
        let style = new Style({
            geometry: feature.getGeometry(),
            stroke: new Stroke({
                width: newOptions.stroke_width,
                color: newOptions.stroke_color, //237, 212, 0, 0.8
            }),

        });

        // styles.push(style);

        if (labelField && labelField !== '') {
            let label = Strings.substitute(labelField, { ...layerData.attrs, "name": feature.get("name") })

            let textStyle = new Text({
                overflow: newOptions.text_overflow,
                placement: newOptions.text_placement,
                textBaseline: newOptions.text_base_line,
                text: label,
                fill: new Fill({ color: newOptions.text_color }),
            });
            style.setText(textStyle);
        }
        styles.push(style);

        if (showSegmentLength === true) {

            const circleStyle = new Style({
                image: new Circle({
                    radius: newOptions.stroke_width + 1,
                    fill: new Fill({
                        color: newOptions.stroke_color,
                    }),
                })
            });

            let st = new Style({
                stroke: new Stroke({
                    width: newOptions.stroke_width,
                    color: newOptions.stroke_color,
                }),

                text: new Text({
                    placement: newOptions.text_placement,
                    textBaseline: newOptions.text_base_line,
                    text: 'A',
                    fill: new Fill({ color: newOptions.text_color }),
                    stroke: new Stroke({
                        color: newOptions.text_stroke_color,
                        width: newOptions.text_stroke_width
                    })
                })
            });

            feature.getGeometry().forEachSegment(function (a, b) {

                const segment = new LineString([a, b]);
                const label = segment.getLength().toFixed(2);
                let st1 = st.clone();
                st1.setGeometry(segment);
                st1.getText().setText(label);
                styles.push(st1);
                //  segStyle.setGeometry(segment);
                // styles.push(segStyle);
            });

            const coordinates = (feature.getGeometry().getType() === 'LineString') ? feature.getGeometry().getCoordinates() : feature.getGeometry().getCoordinates()[0];

            const st2 = circleStyle.clone();

            const geom = new MultiPoint(coordinates);
            st2.setGeometry(geom);
            styles.push(st2);
        }
        return styles;

    }


    createPolygonAreaStyle(feature, layerData, resolution, labelField = '', options = {}, showArea = false, showSegmentLength = false) {

        const defaultOptions = {
            stroke_width: 2,
            stroke_color: 'rgba(0, 200, 0, 0.2)',
            fill_color: 'rgba(0, 200, 0, 0.1)',
            text_color: '#000',
            text_stroke_color: '#fff',
            text_stroke_width: 3,
            text_overflow: true,

            measurement_text_placement: 'line',
            measurement_text_base_line: 'bottom',
        }

        const newOptions = Object.assign(defaultOptions, options);

        if (newOptions.show_segment_length) {
            showSegmentLength = newOptions.show_segment_length;
        }

        if (newOptions.show_area) {
            showArea = newOptions.show_area;
        }

        let styles = [];
        let style = new Style({
            stroke: new Stroke({
                color: newOptions.stroke_color,
                width: newOptions.stroke_width,
            }),
            fill: new Fill({
                color: newOptions.fill_color,
            }),


        });

        if ((labelField && labelField !== '') || (showArea && showArea === true)) {


            let label = '';
            if ((labelField && labelField !== '')) {
                label = Strings.substitute(labelField, { ...layerData.attrs, "name": feature.get("name") }) + "\n";
            }
            if (showArea && showArea === true && feature.getGeometry().getArea()) {
                label += feature.getGeometry().getArea().toFixed(2);
            }
            let textStyle = new Text({
                overflow: newOptions.text_overflow,
                fill: new Fill({
                    color: newOptions.text_color,
                }),
                text: label,
                stroke: new Stroke({
                    color: newOptions.text_stroke_color,
                    width: newOptions.text_stroke_width
                })
            });
            style.setText(textStyle);
        }

        if (showSegmentLength) {
            if (feature.getGeometry().getType() === 'Polygon' || feature.getGeometry().getType() === 'MultiPolygon') {
                const circleStyle = new Style({
                    image: new Circle({
                        radius: newOptions.stroke_width + 1,
                        fill: new Fill({
                            color: newOptions.stroke_color,
                        }),
                    })
                });
                const st = new Style({
                    stroke: new Stroke({
                        width: newOptions.stroke_width,
                        color: newOptions.stroke_color,
                    }),

                    text: new Text({
                        placement: newOptions.measurement_text_placement,
                        textBaseline: newOptions.measurement_text_base_line,
                        text: 'A',
                        fill: new Fill({ color: newOptions.text_color }),
                        stroke: new Stroke({
                            color: newOptions.text_stroke_color,
                            width: newOptions.text_stroke_width
                        })
                    })
                });

                const coordinates = (feature.getGeometry().getType() === 'Polygon') ? feature.getGeometry().getCoordinates() : feature.getGeometry().getCoordinates()[0];


                for (let i = 0; i < coordinates.length; i++) {
                    for (let j = 0; j < coordinates[i].length - 1; j++) {
                        let a = coordinates[i][j];
                        let b = coordinates[i][j + 1];

                        const segment = new LineString([a, b]);
                        const label = segment.getLength().toFixed(2);
                        const st1 = st.clone();
                        st1.setGeometry(segment);
                        st1.getText().setText(label);
                        styles.push(st1);
                    }

                    let a = coordinates[i][0];
                    let b = coordinates[i][coordinates[i].length - 1];

                    const segment = new LineString([a, b]);
                    if (segment.getLength() > 0) {
                        const label = segment.getLength().toFixed(2);
                        const st1 = st.clone();
                        st1.setGeometry(segment);
                        st1.getText().setText(label);
                        st1.getStroke().setColor('#f48fb1');
                        styles.push(st1);


                    }
                    const st2 = circleStyle.clone();

                    const geom = new MultiPoint(coordinates[i]);
                    st2.setGeometry(geom);
                    styles.push(st2);

                }


            }
        }
        styles.push(style)

        return styles;
    }

    createSimpleStyle() {
        var style_simple = new Style({
            fill: new Fill({
                color: '#ADD8E6'
            }),
            stroke: new Stroke({
                color: '#880000',
                width: 1
            })
        });

        return function (feature) {
            return style_simple;
        }
    }

    createCustomStyle(styledef){
        var style_simple = new Style({
            fill: new Fill({
                color: styledef.fill_color
            }),
            stroke: new Stroke({
                color: styledef.stroke_color,
                width: 1
            })
        });

        if (styledef.label) {
            const newOptions = {
                stroke_width: 2,
                stroke_color: 'rgba(0, 200, 0, 0.2)',
                fill_color: 'rgba(0, 200, 0, 0.1)',
                text_color: '#000',
                text_stroke_color: '#fff',
                text_stroke_width: 3,
                text_overflow: true,
    
                measurement_text_placement: 'line',
                measurement_text_base_line: 'bottom',
            }
            
            let label = styledef.label + "\n";
            let textStyle = new Text({
                overflow: newOptions.text_overflow,
                fill: new Fill({
                    color: newOptions.text_color,
                }),
                text: label,
                stroke: new Stroke({
                    color: newOptions.text_stroke_color,
                    width: newOptions.text_stroke_width
                })
            });
            style_simple.setText(textStyle);
        }

        return function () {
            return style_simple;
        }
    }


}


export { StyleBuilder }