
var coordinateDistance = function (c1, c2) {
  const dx = c1[0] - c2[0];
  const dy = c1[1] - c2[1];
  return Math.sqrt(dx * dx + dy * dy);
};

var pointToSegment = function (p, A, B) {
  if (A[0] === B[0] && A[1] === B[1]) return coordinateDistance(p, A)
  const len2 = (B[0] - A[0]) * (B[0] - A[0]) + (B[1] - A[1]) * (B[1] - A[1])
  const r = ((p[0] - A[0]) * (B[0] - A[0]) + (p[1] - A[1]) * (B[1] - A[1])) / len2
  if (r <= 0.0) return coordinateDistance(p, A)
  if (r >= 1.0) return coordinateDistance(p, B)
  const s = ((A[1] - p[1]) * (B[0] - A[0]) - (A[0] - p[0]) * (B[1] - A[1])) / len2
  return Math.abs(s) * Math.sqrt(len2)
};

var ol_coordinate_findClosestSegment = function (pt, coords, geomType) {
  var len = -1;
  var ps0, ps1;
  if (geomType && geomType === 'Polygon') {
    for (var i = 0; i < coords.length; i++) {
      for (var j = 0; j < coords[i].length - 1; j++) {

        var p0 = coords[i][j];
        var p1 = coords[i][j + 1];

        var lt = pointToSegment(pt, p0, p1);
        if (len === -1 || lt < len) {
          ps0 = p0;
          ps1 = p1;
          len = lt;
        }
      }
    }
  }
  else {
    for (var i = 0; i < coords.length - 1; i++) {
      var p0 = coords[i];
      var p1 = coords[i + 1];

      var lt = pointToSegment(pt, p0, p1);
      if (len === -1 || lt < len) {
        ps0 = p0;
        ps1 = p1;
        len = lt;
      }

    }
  }

  return { index: 1, segment: [ps0, ps1] };
};


var isStringEmpty = function (str) {
  return (!str || str.length === 0 || !str.trim());
}

var circleIntersection = function (x0, y0, r0, x1, y1, r1) {
  var a, dx, dy, d, h, rx, ry;
  var x2, y2;
  dx = x1 - x0;
  dy = y1 - y0;
  d = Math.sqrt((dy * dy) + (dx * dx));
  if (d > (r0 + r1)) {
    return false;
  }
  if (d < Math.abs(r0 - r1)) {
    return false;
  }
  a = ((r0 * r0) - (r1 * r1) + (d * d)) / (2.0 * d);
  x2 = x0 + (dx * a / d);
  y2 = y0 + (dy * a / d);
  h = Math.sqrt((r0 * r0) - (a * a));
  rx = -dy * (h / d);
  ry = dx * (h / d);
  var xi = x2 + rx;
  var xi_prime = x2 - rx;
  var yi = y2 + ry;
  var yi_prime = y2 - ry;
  return [xi, xi_prime, yi, yi_prime];
};

var arrayEquals = function (a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

var deepMerge = function (source, target) {
  for (const [key, val] of Object.entries(source)) {
    if (val !== null && typeof val === `object`) {
      if (target[key] === undefined) {
        target[key] = new val.__proto__.constructor();
      }
      deepMerge(val, target[key]);
    } else {
      target[key] = val;
    }
  }
  return target; // we're replacing in-situ, so this is more for chaining than anything else
}

var Strings = {
  substitute: (function () {
    var regexp = /{([^{]+)}/g;

    return function (str, o) {
      return str.replace(regexp, function (ignore, key) {
        return (key = o[key]) == null ? '' : key;
      });
    }
  })()
};

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())


export { coordinateDistance, circleIntersection, pointToSegment, ol_coordinate_findClosestSegment, isStringEmpty, arrayEquals, deepMerge, Strings , titleCase}