import "../styles/bhugis.css";
import "../styles/jspanel.min.css";
import "../styles/ol-ext.min.css";
import "../styles/ol.css";
import "../styles/tui-grid.css";
import "../styles/font-gis.css";
import "../styles/bhu-bootstrap.css";

import { jsPanel } from "jspanel4";
import { CONSTANTS } from "./Constants";
import { coordinateDistance, titleCase } from "./utils";
import { AreaDivision } from "./AreaDivision";
import { jsPDF } from "jspdf/dist/jspdf.umd";
import Grid from "tui-grid";

class UIBuilder {
  constructor(targetDiv, digitizer) {
    this.panelSettings = null;
    this.digitizer = digitizer;
    this.options = digitizer.options;
    this.gridSpaceing = 10;
    this.targetDiv = targetDiv;
    this.servicePathPanel = this.options.service_path + "assets/templates/";

    jsPanel.ziBase = 101;
  }

  loadUI() {
    let self = this;
    var newStyle = document.createElement("style");
    newStyle.appendChild(
      document.createTextNode(
        "\
@font-face {\
    font-family: \"font-gis\";\
    src: url('_ASSETS_/font-gis/font-gis.eot?t=1661164438225');\
    src: url('_ASSETS_/font-gis/font-gis.eot?#iefix') format('eot'),\
      url('_ASSETS_/font-gis/font-gis.woff2?t=1661164438225') format('woff2'),\
      url('_ASSETS_/font-gis/font-gis.woff?t=1661164438225') format('woff'),\
      url('_ASSETS_/font-gis/font-gis.ttf?t=1661164438225') format('truetype'),\
      url('_ASSETS_/font-gis/font-gis.svg#font-gis') format('svg');\
    font-weight: normal;\
    font-style: normal;\
  }\
".replaceAll("_ASSETS_", this.options.service_path + "assets")
      )
    );

    document.head.appendChild(newStyle);
    fetch(this.options.service_path + "assets/templates/baseui.html?v=" + self.digitizer.version())
      .then((data) => {
        return data.text();
      })
      .then((data) => {
        data = data.replaceAll("_ASSETS_", this.options.service_path + "assets");

        document.getElementById(this.targetDiv).innerHTML = data;
        this.initGrids();

        this.loadToolBar();
      });
  }

  initGrids() {
    var self = this;
    var griddiv = document.getElementById("grid");
    griddiv.innerHTML = "";

    var griddivline = document.getElementById("linesData");
    griddivline.innerHTML = "";
    this.linegrid = new Grid({
      usageStatistics: false,
      el: document.getElementById("linesData"),
      data: this.digitizer.gridData.lines,
      rowHeaders: ["rowNum", "checkbox"],
      scrollX: false,
      scrollY: false,
      columns: [
        {
          header: "Actions",
          name: "actions",
        },
        {
          header: "Name",
          name: "name",
          // },
          // {
          //     header: 'Details',
          //     name: 'details'
        },
      ],
    });
    this.linegrid.on("checkAll", (ev) => {
      digitizer.doHighlight(undefined, "lines", true);
    });
    this.linegrid.on("uncheckAll", (ev) => {
      digitizer.doHighlight(undefined, "lines", false);
    });
    this.linegrid.on("check", (ev) => {
      digitizer.doHighlight(this.gridData.lines[ev["rowKey"]].id, "lines", true);
    });
    this.linegrid.on("uncheck", (ev) => {
      digitizer.doHighlight(this.gridData.lines[ev["rowKey"]].id, "lines", false);
    });
    this.linegrid.on("focusChange", (ev) => {
      //console.log('change focused cell!', ev);
    });
    // this.refreshPolygonsInDataDisplay(true);
    //var griddivpoly = document.getElementById('PolygonData');
    //griddivpoly.innerHTML = "";

    // this.polygrid = new tui.Grid({
    // usageStatistics:false,
    //     el: document.getElementById('PolygonData'),
    //     data: this.gridData.polygon,
    //     rowHeaders: ['rowNum', 'checkbox'],
    //     scrollX: false,
    //     scrollY: false,
    //     columns: [
    //         {
    //             header: 'Actions',
    //             name: 'actions'
    //         },
    //         {
    //             header: 'Name',
    //             name: 'name'
    //         },
    //         {
    //             header: 'Details',
    //             name: 'details'
    //         }
    //     ]
    // });
    // this.polygrid.on('checkAll', ev => {
    //     this.doHighlight(undefined, 'polygons', true);
    // });
    // this.polygrid.on('uncheckAll', ev => {
    //     this.doHighlight(undefined, 'polygons', false);
    // });
    // this.polygrid.on('check', ev => {
    //     this.doHighlight(this.gridData.polygon[ev["rowKey"]].id, 'polygons', true);
    // });
    // this.polygrid.on('uncheck', ev => {
    //     this.doHighlight(this.gridData.polygon[ev["rowKey"]].id, 'polygons', false);
    // });
    // this.polygrid.on('focusChange', ev => {
    //     //console.log('change focused cell!', ev);
    // });

    // this.initLayerMaster();

    // this.initTools();
  }

  constructAndOpenAttributePanel(data) {
    var self = this;
    if (self.attributePanel && self.attributePanel !== null) {
      self.attributePanel.close(() => {
        self.attributePanel = null;
      });
    }
    self.attributePanel = jsPanel.create({
      id: "attribute-panel",
      closeOnEscape: true,
      headerTitle: "Attributes",
      footerToolbar: '<button id="doneEditing" class="btn btn-primary cardAction-btn">OK</button>',
      onmaximized: () => {
        self.attributegrid.refreshLayout();
      },
      resizeit: {
        stop: () => {
          self.attributegrid.refreshLayout();
        },
      },
      contentFetch: {
        resource: self.servicePathPanel + "_attribute_mapping.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          let panelWrapperID = document.getElementById("attribute-panel");
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          let selectedLayerCode = self.getSelectedLayerCode();
          let toMapLayer = self.digitizer.getLayerMasterDataFromCode(selectedLayerCode);

          panelWrapperID.querySelector("#doneEditing").addEventListener("click", function () {
            self.attributegrid.finishEditing();
            let modifiedRows = self.attributegrid.getModifiedRows().updatedRows;
            let undoItemGroup = [];
            for (const row of modifiedRows) {
              let attrs = {};
              for (const at of toMapLayer.attrs) {
                attrs[at.key] = row[at.key];
              }
              self.digitizer.editAttributes(self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type), row.id, attrs, undoItemGroup);
            }
            if (undoItemGroup.length >= 1) {
              self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
            }
            self.attributePanel.close();
          });

          let attrMeta = toMapLayer.attrs;

          const dataforAttributeGrid = [];
          let columnLabels = [
            {
              header: "ID",
              name: "id",
            },
          ];
          if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.POLYGON) {
            columnLabels.push({
              header: "Area",
              name: "areaSystem",
              disabled: true,
            });
          } else if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.LINE) {
            columnLabels.push({
              header: "Length",
              name: "lengthSystem",
              disabled: true,
            });
          }
          for (const at of attrMeta) {
            columnLabels.push({
              header: at.name,
              name: at.key,
              editor: "text",
            });
          }
          let dataForTable;
          if (data) {
            dataForTable = [self.digitizer.getDataById(self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type), data.id).data];
          } else {
            dataForTable = self.digitizer.getLayerDataFromCode(selectedLayerCode);
          }
          for (const cld of dataForTable) {
            let consolidatedData = { id: cld.id };
            for (const atKey in cld.attrs) {
              consolidatedData[atKey] = cld.attrs[atKey];
            }
            let layerGeom = self.digitizer.getVectorLayer(selectedLayerCode).getSource().getFeatureById(cld.id).getGeometry();
            if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.POLYGON) {
              let calcArea = layerGeom.getArea();
              calcArea = Math.round((Number(calcArea) / self.options.scaleFactor) * 100) / 100;
              consolidatedData["areaSystem"] = calcArea;
            } else if (self.digitizer.getDataObjectsForGeom(toMapLayer.geom_type) === CONSTANTS.DATA_OBJECTS.LINE) {
              let calcLength = layerGeom.getLength();
              calcLength = Math.round((Number(calcLength) / self.options.scaleFactor) * 100) / 100;
              consolidatedData["lengthSystem"] = calcLength;
            }
            dataforAttributeGrid.push(consolidatedData);
          }
          let attributeGridConfig = {
            usageStatistics: false,
            el: document.getElementById("attributeMapping"),
            data: dataforAttributeGrid,
            columns: columnLabels,
            rowHeaders: ["checkbox"],
          };
          self.attributegrid = new Grid(attributeGridConfig);
          self.attributegrid.hideColumn("id");
          self.attributegrid.on("checkAll", (ev) => {
            self.doHighlightCustomLayer(undefined, true);
          });
          self.attributegrid.on("uncheckAll", (ev) => {
            self.doHighlightCustomLayer(undefined, false);
          });
          self.attributegrid.on("check", (ev) => {
            debugger;
            self.doHighlightCustomLayer(self.attributegrid.getRowAt(ev.rowKey).id, true);
          });
          self.attributegrid.on("uncheck", (ev) => {
            debugger;
            self.doHighlightCustomLayer(self.attributegrid.getRowAt(ev.rowKey).id, false);
          });
        },
      },

      theme: "dark ",
      borderRadius: ".5rem",
      container: "div.map",
      position: "center-top",
      contentSize: {
        width: () => window.innerWidth * 0.8,
        height: "50vh",
      },
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.attributePanel = null;
        if (self.attributegrid) {
          self.attributegrid.destroy();
        }
        self.attributegrid = null;
      },
    });
  }

  refreshMappingListGrid() {
    let genLayerCopy = JSON.parse(JSON.stringify(this.generatedLayers));
    let genLayerCodeArray = [];
    for (const glc of genLayerCopy) {
      genLayerCodeArray.push(glc.layer_code);
    }
    genLayerCodeArray = [...new Set(genLayerCodeArray)];
    let reducedGenLayers = [];
    for (const glca of genLayerCodeArray) {
      reducedGenLayers.push({
        layer_code: glca,
        layer_name: Gis.CustomLayerMasterMin["getName"][glca],
      });
    }
    this.mappinglistgrid.resetData(reducedGenLayers);
    this.digitizer.clearInteractions();
  }

  loadToolBar() {
    let self = this;
    if (self.options.hide_toolbar === true) {
      self.digitizer.loadInitialMap();
      if (self.digitizer.options.control_buttons && self.digitizer.options.control_buttons.length > 0) {
        for (let i = 0; i < self.digitizer.options.control_buttons.length; i++) {
          let btObj = self.options.control_buttons[i];
          self.addControlButton(btObj.icon, btObj.text, btObj.style, btObj.on_complete);
        }
      }
      self.constructLayerInSideBar();
      //copied here as it was only present in toolbar load
      let tayertypes = document.getElementById("tayertypes");
      tayertypes.addEventListener("click", function (evt) {
        self.layerShow();
      });
      document.getElementById("closeongenerateddata").addEventListener("click", function (evt) {
        self.layerShow();
      });
      return;
    }

    // if (self.tollbarPanel && self.tollbarPanel !== null) {
    //     try {
    //         self.tollbarPanel.close();
    //         self.tollbarPanel = null;
    //     } catch (err) {

    //     }
    // }
    self.tollbarPanel = jsPanel.create({
      headerTitle: "Toolbar",
      contentFetch: {
        resource: self.servicePathPanel + "_toolbar.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          self.initTools();
          if (self.digitizer.options.action_buttons && self.digitizer.options.action_buttons.length > 0) {
            for (let i = 0; i < self.digitizer.options.action_buttons.length; i++) {
              self.digitizer.addActionButton(self.options.action_buttons[i]);
            }
          }

          //

          self.digitizer.loadInitialMap();
          if (self.digitizer.options.control_buttons && self.digitizer.options.control_buttons.length > 0) {
            for (let i = 0; i < self.digitizer.options.control_buttons.length; i++) {
              let btObj = self.options.control_buttons[i];
              self.addControlButton(btObj.icon, btObj.text, btObj.style, btObj.on_complete);
            }
          }
          // self.initLayerMaster();
        },
      },
      position: "right-bottom -5 -50",
      contentSize: "132 410",
      container: "div.map",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#181c32",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },
      // headerControls: 'xs', // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelSettings = null;
      },
      // bgFooter:"#181c32",
      footerToolbar: "<span>&nbsp;</span>",
      headerControls: {
        maximize: "remove",
        close: "remove",
        size: "xs",
      },
    });
  }

  showSetings() {
    let self = this;
    if (self.panelSettings && self.panelSettings !== null) {
      try {
        self.panelSettings.close();
        self.panelSettings = null;
      } catch (err) {}
    }
    self.panelSettings = jsPanel.create({
      headerTitle: "Settings",
      contentFetch: {
        resource: self.servicePathPanel + "_settings.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          document.getElementById("_settings_touch_mode").checked = self.digitizer.getTouchMode();
          document.getElementById("_settings_touch_mode").addEventListener("click", function (evt) {
            self.digitizer.setTouchMode(document.getElementById("_settings_touch_mode").checked);
          });
        },
      },
      position: "right-bottom -5 -50",
      contentSize: "250 320",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.map",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelSettings = null;
        self.digitizer.clearTempGeom();
      },
    });
  }

  exportPDF() {
    let self = this;
    if (self.panelPdf && self.panelPdf !== null) {
      try {
        self.panelPdf.close();
        self.panelPdf = null;
      } catch (err) {}
    }
    self.panelPdf = jsPanel.create({
      headerTitle: "Export PDF",
      contentFetch: {
        resource: self.servicePathPanel + "_export_pdf.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          document.getElementById("do_export_pdf").addEventListener(
            "click",
            function (evt) {
              const dims = {
                a0: [1189, 841],
                a1: [841, 594],
                a2: [594, 420],
                a3: [420, 297],
                a4: [297, 210],
                a5: [210, 148],
              };

              const format = document.getElementById("pdf_paper_size").value;
              const resolution = document.getElementById("pdf_resolution").value;
              const dim = dims[format];
              const width = Math.round((dim[0] * resolution) / 25.4);
              const height = Math.round((dim[1] * resolution) / 25.4);
              const map = self.digitizer.getMap();
              const size = map.getSize();
              const viewResolution = map.getView().getResolution();

              map.once("rendercomplete", function () {
                const mapCanvas = document.createElement("canvas");
                mapCanvas.width = width;
                mapCanvas.height = height;
                const mapContext = mapCanvas.getContext("2d");
                Array.prototype.forEach.call(document.querySelectorAll(".ol-layer canvas"), function (canvas) {
                  if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                    const transform = canvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    const matrix = transform
                      .match(/^matrix\(([^\(]*)\)$/)[1]
                      .split(",")
                      .map(Number);
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                    mapContext.drawImage(canvas, 0, 0);
                  }
                });
                try {
                  mapContext.globalAlpha = 1;
                  mapContext.setTransform(1, 0, 0, 1, 0, 0);
                  const pdf = new jsPDF("landscape", undefined, format);
                  // pdf.text("Hello world!", 1, 1);
                  pdf.addImage(mapCanvas.toDataURL("image/png"), "PNG", 0, 0, dim[0], dim[1]);
                  pdf.save("map.pdf");
                } catch (ex) {
                  console.log(ex);
                  alert("Some layers does not allow exporting.");
                }
                // Reset original map size
                map.setSize(size);
                map.getView().setResolution(viewResolution);
                // exportButton.disabled = false;
                document.body.style.cursor = "auto";
              });

              // Set print size
              const printSize = [width, height];
              map.setSize(printSize);
              const scaling = Math.min(width / size[0], height / size[1]);
              map.getView().setResolution(viewResolution / scaling);
            },
            false
          );
        },
      },
      position: "right-bottom -5 -50",
      contentSize: "250 320",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.map",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelPdf = null;
        self.digitizer.clearTempGeom();
      },
    });
  }
  togglemenuBar() {
    this.toggleclasses("menubarPane", "dotoggle");
    // this.toggleclasses('attributeHamberger', 'content-to-bottom');
    this.toggleclasses("tayertypes", "content-to-bottom");
    this.digitizer.getMap().updateSize();
  }

  toggleclasses(elmt, clss) {
    var ele = document.getElementById(elmt);
    var templist = ele.className;
    var classArr = templist.split(/\s+/);
    var eleflag = classArr.includes(clss);
    if (eleflag) {
      ele.classList.remove(clss);
    } else {
      ele.classList.add(clss);
    }
  }

  initLayerMaster() {
    let layerMaster = this.options.layer_master;
    let uniqueNames = [];
    let samplearray = [];
    let lineTabHtml = "";
    let xarray = [];
    for (var x = 0; x < layerMaster.length; x++) {
      let gtype = layerMaster[x].geom_type;
      let ltype = layerMaster[x].geom_type; //CONSTANTS.returnLayerForGeom(gtype).split("vector")[1];
      uniqueNames.push(ltype);
    }
    xarray = [...new Set(uniqueNames)]; //not required, need to rethink and remove
    let i = 0;
    lineTabHtml = '<select name="sample" id="dropdownListType" class="d-inline-block selection addDataFromETSpoints marginleft-16">';

    for (i = 0; i < xarray.length; i++) {
      lineTabHtml += '<optgroup label="' + xarray[i] + '">';
      for (var xc = 0; xc < layerMaster.length; xc++) {
        if (layerMaster[xc].hide_from_list && layerMaster[xc].hide_from_list == true) {
          continue;
        }
        let gtype = layerMaster[xc].geom_type;
        let ltype = gtype; //CONSTANTS.returnLayerForGeom(gtype).split("vector")[1];
        if (xarray[i] === ltype && !samplearray.includes(layerMaster[xc].layer_name)) {
          lineTabHtml += '<option name="' + xarray[i] + '" value=' + layerMaster[xc].layer_code + ">" + layerMaster[xc].layer_name + "</option>";
          samplearray.push(layerMaster[xc].layer_code);
        }
      }
      lineTabHtml += "</optgroup>";
    }
    lineTabHtml += "</select>";
    var customdiv = document.createElement("span");
    customdiv.innerHTML = lineTabHtml;
    if (this.options.hide_toolbar === true) {
      document.getElementById("datalistdropdown1").append(customdiv);
    } else {
      document.getElementById("datalistdropdown").append(customdiv);
    }
    // let selectData = this.options.attributes;
    // let contentDiv = document.getElementById('locationinfodistrictClass');
    // let blockdiv = document.getElementById('locationinfoblockClass');
    // for (let i = 0; i < selectData.length; i++) {
    //     if (selectData[i].visible && selectData[i].text) {
    //         var temdiv = document.createElement('div');
    //         temdiv.className = 'locationDetailscard';
    //         var childdiv1 = document.createElement('div');
    //         childdiv1.className = 'h6 small clr-hdg';
    //         childdiv1.innerHTML = selectData[i].label;

    //         var childdiv2 = document.createElement('div');
    //         childdiv2.className = 'h6 ';
    //         childdiv2.innerHTML = selectData[i].value;

    //         temdiv.appendChild(childdiv1);
    //         temdiv.appendChild(childdiv2);

    //         contentDiv.appendChild(temdiv);
    //     }
    // }
    // for (let i = 0; i < selectData.length; i++) {
    //     if (selectData[i].visible && !(selectData[i].text)) {
    //         var temdiv = document.createElement('div');
    //         temdiv.className = 'blockinfoCard';
    //         var childdiv1 = document.createElement('div');
    //         childdiv1.className = 'h6 small text-white';
    //         childdiv1.innerHTML = selectData[i].label;

    //         var childdiv2 = document.createElement('div');
    //         childdiv2.className = 'h6 logoText text-white';
    //         childdiv2.innerHTML = selectData[i].value;

    //         temdiv.appendChild(childdiv1);
    //         temdiv.appendChild(childdiv2);

    //         blockdiv.appendChild(temdiv);
    //     }
    // }
  }

  initTools() {
    var self = this;
    let footerControl = document.getElementById("footerControl");
    // settingsimg.setAttribute("id", "appSettings");
    // settingsimg.setAttribute("class", "svgIcons toggleswitch");
    // settingsimg.setAttribute("title", "Application Settings");
    // footerControl.append(settingsimg);
    // windowresizeimg.setAttribute("id", "fullscreenSwitcher");
    // windowresizeimg.setAttribute("class", "svgIcons toggleswitch");
    // windowresizeimg.setAttribute("title", "Toggle Fullscreen");
    // footerControl.append(windowresizeimg);
    // switchimg.setAttribute("id", "navbarSwitcher");
    // switchimg.setAttribute("class", "svgIcons toggleswitch");
    // switchimg.setAttribute("title", "Toggle menubar top/bottom");
    // footerControl.append(switchimg);

    // let attributeHamberger = document.getElementById("attributeHamberger");
    // attributeHamberger.addEventListener("click", function (evt) {
    //     self.attributeDataShow();
    // });
    // attributeHambergerimg.setAttribute("class", "filterInvert");
    // attributeHamberger.append(attributeHambergerimg);

    let tayertypes = document.getElementById("tayertypes");
    tayertypes.addEventListener("click", function (evt) {
      self.layerShow();
    });
    // tayertypesimg.setAttribute("width", "18");
    // tayertypes.append(tayertypesimg);

    // let locateme = document.getElementById("locateme");
    // locateme.addEventListener("click", function (evt) {
    //     //Setting hadcoded center
    //     // self.map.getView().setCenter(transform([76.7821, 9.2336], 'EPSG:4326', self.options.output_proj));
    //     // self.map.getView().setZoom(20);

    //     //Getting location
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //             console.log(position);
    //             let lat = position.coords.latitude;
    //             let lon = position.coords.longitude;
    //             console.log(lat);
    //             console.log(lon);

    //             //  lon = 76.7777;
    //             //  lat = 9.2306;

    //             self.digitizer.setCenter(lon, lat);

    //         });
    //     }
    //     else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }
    // });
    // locatemeimg.setAttribute("width", "18");
    // locateme.append(locatemeimg);

    let bulkdeletedta = document.getElementById("bulkdeletedta");
    // bulkdeletedtaimg.setAttribute("class", "accessibilitybuttonicon");
    // bulkdeletedtaimg.setAttribute("height", "10");
    // bulkdeletedta.append(bulkdeletedtaimg);

    let bulkcleardata = document.getElementById("bulkcleardata");
    // bulkcleardataimg.setAttribute("class", "accessibilitybuttonicon");
    // bulkcleardataimg.setAttribute("height", "10");
    // bulkcleardata.append(bulkcleardataimg);

    let generateddataLayers = document.getElementById("generateddataLayers");
    // generateddataLayersimg.setAttribute("class", "DN");
    // generateddataLayersimg.setAttribute("width", "25");

    let iconcloseleftbar = document.getElementById("iconcloseleftbar");
    iconcloseleftbar.addEventListener("click", function (evt) {
      self.attributeDataShow();
    });
    // iconcloseleftbar.append(iconcloseleftbarimg);

    let pickfrommap = document.getElementById("pickfrommap");
    // pickFromMapimg.setAttribute("style", "margin-left:-5px;width:28px;margin-top:-2px;");
    // pickfrommap.append(pickFromMapimg);
    pickfrommap.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {
        layer_code: self.getSelectedLayerCode(),
        event_source: "EDIT_ATTRIBUTE",
      });
      // self.layerPickFromMap();
    });
    let pickfromlist = document.getElementById("pickfromlist");
    // pickFromListimg.setAttribute("style", "width:21px");
    // pickfromlist.append(pickFromListimg);
    pickfromlist.addEventListener("click", function () {
      self.constructAndOpenAttributePanel();
    });
    // let pickandedit = document.getElementById("pickandedit");
    // // pickAndEditimg.setAttribute("style", "margin-left:1px;width:21px;margin-top:-3px;");
    // // pickandedit.append(pickAndEditimg);
    // pickandedit.addEventListener("click", function (evt) {
    //     self.chooseDataFromMapV2(true);
    // });
    let editPoint = document.getElementById("editPoint");
    // editPointimg.setAttribute("style", "width:21px");
    // editPoint.append(editPointimg);
    editPoint.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT);
    });
    let pointdatabutton = document.getElementById("pointdatabutton");
    pointdatabutton.addEventListener("click", this.openLayerpanel);
    // pointdatabutton.append(pointdatabuttonimg);

    let linedatabutton = document.getElementById("linedatabutton");
    linedatabutton.addEventListener("click", this.openLayerpanel);
    // linedatabutton.append(linedatabuttonimg);

    let polygondatabutton = document.getElementById("polygondatabutton");
    polygondatabutton.addEventListener("click", this.openLayerpanel);
    // polygondatabutton.append(polygondatabuttonimg);

    // let onscreenGenerateddatabtn = document.getElementById("onscreenGenerateddatabtn");
    // onscreenGenerateddatabtn.addEventListener("click", this.generatedDataShow);
    // onscreenGenerateddatabtn.append(onscreenGenerateddatabtnimg);

    document.getElementById("closeongenerateddata").addEventListener("click", function (evt) {
      self.layerShow();
    });
    // let saveActionbtn = document.getElementById("saveActionbtn");
    // saveActionbtn.addEventListener("click", function (evt) {
    //     self.digitizer.downloadSelectedLayerAsGeoJson();
    // });

    // let doPlaceLine = document.getElementById("doPlaceLine");
    // doPlaceLine.addEventListener("click", function (evt) {
    //     self.drawbyChoosepoints();
    // });
    // doPlaceLine.append(doPlaceLineimg);

    document.getElementById("appSettings").addEventListener("click", function (evt) {
      self.showSetings();
    });

    document.getElementById("export_pdf").addEventListener("click", function (evt) {
      self.exportPDF();
    });

    document.getElementById("export_points").addEventListener("click", function (evt) {
      self.showPointsForExport();
    });

    let clearinteractionbtn = document.getElementById("clearinteractionbtn");
    clearinteractionbtn.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.POINTER);
    });
    // clearinteractionbtn.append(clearinteractionbtnimg);

    let importETSdatabtn = document.getElementById("importETSdatabtn");
    importETSdatabtn.addEventListener("click", function (evt) {
      self.initetsPanel();
      try {
        self.map.removeInteraction(self.pinPoint);
        self.isDrawnEnabled = true;
      } catch (err) {}
    });
    // importETSdatabtn.append(importETSdatabtnimg);

    // let highligtingBtn = document.getElementById("highligtingBtn");
    // highligtingBtn.addEventListener("click", this.highlightFeature);
    // highligtingBtn.append(highligtingBtnimg);
    // document.getElementById("navbarSwitcher").addEventListener("click", function (evt) {
    //     self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.POINTER);
    //     self.togglemenuBar();
    // });
    document.getElementById("bulkcleardata").addEventListener("click", this.digitizer.clearSelectedMenu);
    //document.getElementById("bulkdeletedta").addEventListener("click", this.bulkDelete);

    let doDrawLinebtn = document.getElementById("doDrawLinebtn");
    doDrawLinebtn.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // self.drawLineTool();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      if (!self.digitizer.getTouchMode()) {
        document.getElementById("infoPanel").style.display = "block";
        var actions =
          '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/clearDrawing.svg>";
        actions +=
          '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/tickSuccess.svg>";
        document.getElementById("infoPanel").innerHTML = actions;
        document.getElementById("cancelCurrentDrawing").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.removeLastPointFromLine();
        });
        document.getElementById("finishCurrentDraw").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.finishLineDrawing();
          document.getElementById("infoPanel").style.display = "none";
        });
      }
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE);
    });

    let doDrawSplitLine = document.getElementById("doDrawSplitLine");
    doDrawSplitLine.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // self.drawLineTool();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      if (!self.digitizer.getTouchMode()) {
        document.getElementById("infoPanel").style.display = "block";
        var actions =
          '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/clearDrawing.svg>";
        actions +=
          '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/tickSuccess.svg>";
        document.getElementById("infoPanel").innerHTML = actions;
        document.getElementById("cancelCurrentDrawing").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.removeLastPointFromLine();
        });
        document.getElementById("finishCurrentDraw").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.finishLineDrawing();
          document.getElementById("infoPanel").style.display = "none";
        });
      }
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, { layer_code: "_SPLIT_LINE_" });
    });

    let doDrawLiveSplitLine = document.getElementById("drawLiveSplitLine");
    doDrawLiveSplitLine.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // self.drawLineTool();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      if (!self.digitizer.getTouchMode()) {
        document.getElementById("infoPanel").style.display = "block";
        var actions =
          '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/clearDrawing.svg>";
        actions +=
          '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/tickSuccess.svg>";
        document.getElementById("infoPanel").innerHTML = actions;
        document.getElementById("cancelCurrentDrawing").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.removeLastPointFromLine();
        });
        document.getElementById("finishCurrentDraw").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.finishLineDrawing();
          document.getElementById("infoPanel").style.display = "none";
        });
      }
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, { layer_code: "_SPLIT_LINE_", split_mode: "LIVE" });
    });

    document.getElementById("clearSplitLine").addEventListener("click", function (evt) {
      self.digitizer.interactionManager.removeCurrentInteraction();
      self.digitizer.clearSplitLines();
      self.digitizer.clearTempGeom();
      self.digitizer.mergeList = [];
    });

    document.getElementById("selectPolygons").addEventListener("click", function (evt) {
      if (self.digitizer.mergeList && self.digitizer.mergeList.length > 0) {
        //Clear Selection
        self.digitizer.interactionManager.removeCurrentInteraction();
        self.digitizer.clearTempGeom();
        self.digitizer.mergeList = [];
      } else {
        self.digitizer.interactionManager.removeCurrentInteraction();
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {
          layer_code: self.getSelectedLayerCode(),
          event_source: "MERGE_POLYGONS",
        });
      }
    });

    // document.getElementById('clearPolygonSelection').addEventListener("click", function (evt) {

    //     self.digitizer.interactionManager.removeCurrentInteraction();
    //     self.digitizer.clearTempGeom();
    //     self.digitizer.mergeList=[];

    // });

    let doSplit = document.getElementById("doSplit");
    doSplit.addEventListener("click", async function (evt) {
      self.digitizer.interactionManager.removeCurrentInteraction();
      if (self.digitizer.mergeList && self.digitizer.mergeList.length > 0) {
        // self.digitizer.interactionManager.removeCurrentInteraction();
        self.digitizer.splitMultiplePolygons();
        self.digitizer.clearSplitLines();
        self.digitizer.clearTempGeom();
      } else {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SPLIT_POLYGON);
      }
    });

    const areDivision = document.getElementById("areaDivision");
    areDivision.addEventListener("click", async function (evt) {
      if (!self.areDivisionUI) {
        self.areDivisionUI = new AreaDivision(self.digitizer);
      }
      if (!self.areDivisionUI.areaDivisionPanel) {
        self.areDivisionUI.loadUI();
      }
    });

    // let doSplit2 = document.getElementById("doSplit2");
    // doSplit2.addEventListener("click", function (evt) {
    //     // self.digitizer.clearInteractions();
    //     // self.drawLineTool();
    //     // try {
    //     //     self.map.removeInteraction(self.pinPoint);
    //     //     self.isDrawnEnabled = true;
    //     // } catch (err) {

    //     // }

    //     document.getElementById('infoPanel').style.display = "block";
    //     var actions = `  <i id="_poly_split_cancel" class="fg fg-cancel-operation cursorPointer marginleft-16 fg-lg" title="Cancel" ></i>
    //     <i id="_poly_split_select" class="fg fg-layers cursorPointer marginleft-16 fg-lg" title="Select Polygons"></i>
    //     <i id="_poly_split_draw_line" class="fg fg-draw-line cursorPointer marginleft-16 fg-lg" title="Draw Split Line"></i>
    //     <i id="_poly_split_draw_line_accept" class="fg fg-finish-line cursorPointer marginleft-16 fg-lg DN" title="Finish Line"></i>
    //     <i id="_poly_split_draw_line_clear" class="fg fg-discard-line cursorPointer marginleft-16 fg-lg DN" title="Cancel Line"></i>
    //     <i id="_poly_split_clear_line" class="fg fg-clear cursorPointer marginleft-16 fg-lg" title="Clear Operation"></i>
    //     <i id="_poly_split_accept" class="fg fg-accept-operation cursorPointer marginleft-16 fg-lg" title="Split"></i>

    //     `;

    //      document.getElementById('infoPanel').innerHTML = actions;
    //      document.getElementById('_poly_split_cancel').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();
    //         self.digitizer.mergeList=[];
    //         document.getElementById('infoPanel').style.display = "none";

    //     });
    //     document.getElementById('_poly_split_select').addEventListener("click", function (evt) {
    //         document.getElementById('_poly_split_select').classList.add("fg-selection");
    //         document.getElementById('_poly_split_draw_line').classList.remove("fg-selection");
    //         self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {layer_code:self.getSelectedLayerCode(),event_source: "MERGE_POLYGONS"});
    //     });
    //     document.getElementById('_poly_split_accept').addEventListener("click", function (evt) {
    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.splitMultiplePolygons();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();

    //         document.getElementById('infoPanel').style.display = "none";
    //     });
    //     document.getElementById('_poly_split_draw_line').addEventListener("click", function (evt) {
    //         document.getElementById('_poly_split_select').classList.remove("fg-selection");
    //         document.getElementById('_poly_split_draw_line').classList.add("fg-selection");
    //         self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, {layer_code:'_SPLIT_LINE_'});
    //         document.getElementById("_poly_split_draw_line_accept").classList.remove('DN');
    //         document.getElementById("_poly_split_draw_line_clear").classList.remove('DN');

    //     });

    //     document.getElementById('_poly_split_draw_line_accept').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.finishLineDrawing();
    //         document.getElementById("_poly_split_draw_line_accept").classList.add('DN');
    //         document.getElementById("_poly_split_draw_line_clear").classList.add('DN');
    //     });

    //     document.getElementById('_poly_split_draw_line_clear').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         document.getElementById("_poly_split_draw_line_accept").classList.add('DN');
    //         document.getElementById("_poly_split_draw_line_clear").classList.add('DN');
    //     });

    //     document.getElementById('_poly_split_clear_line').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();
    //         self.digitizer.mergeList=[];
    //         document.getElementById('_poly_split_select').classList.remove("fg-selection");
    //         document.getElementById('_poly_split_draw_line').classList.remove("fg-selection");

    //     });

    //     document.getElementById('_poly_split_cancel').addEventListener("click", function (evt) {

    //         self.digitizer.interactionManager.removeCurrentInteraction();
    //         self.digitizer.clearSplitLines();
    //         self.digitizer.clearTempGeom();
    //         self.digitizer.mergeList=[];
    //         document.getElementById('infoPanel').style.display = "none";

    //     });
    // });

    let doMeasureLength = document.getElementById("doMeasureLength");
    doMeasureLength.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // self.drawLineTool();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      if (!self.digitizer.getTouchMode()) {
        document.getElementById("infoPanel").style.display = "block";
        var actions =
          '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/clearDrawing.svg>";
        actions +=
          '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/tickSuccess.svg>";
        document.getElementById("infoPanel").innerHTML = actions;
        document.getElementById("cancelCurrentDrawing").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.removeLastPointFromLine();
        });
        document.getElementById("finishCurrentDraw").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.finishLineDrawing();
          document.getElementById("infoPanel").style.display = "none";
        });
      }

      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_LINE, { layer_code: "_LINE_MEASUREMENT_" });
    });

    let doMeasureArea = document.getElementById("doMeasureArea");
    doMeasureArea.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // self.drawLineTool();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      if (!self.digitizer.getTouchMode()) {
        document.getElementById("infoPanel").style.display = "block";
        var actions =
          '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/clearDrawing.svg>";
        actions +=
          '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/tickSuccess.svg>";
        document.getElementById("infoPanel").innerHTML = actions;
        document.getElementById("cancelCurrentDrawing").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.removeLastPointFromPolygon();
        });
        document.getElementById("finishCurrentDraw").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.finishPolygonDrawing();
          document.getElementById("infoPanel").style.display = "none";
        });
      }
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_POLYGON, { layer_code: "_AREA_MEASUREMENT_" });
    });
    // doDrawLinebtn.append(doDrawLinebtnimg);

    let doUndoBtn = document.getElementById("doUndoBtn");
    doUndoBtn.addEventListener("click", function (evt) {
      self.digitizer.undoRedoManager.undo();
    });
    // doUndoBtn.append(doUndoBtnimg);

    let doRedoBtn = document.getElementById("doRedoBtn");
    doRedoBtn.addEventListener("click", function (evt) {
      self.digitizer.undoRedoManager.redo();
    });
    // doRedoBtn.append(doRedoBtnimg);
    // document.getElementById("fullscreenSwitcher").addEventListener("click", function (evt) {
    //     self.toggleFullscreen();
    // });

    let doCircleIntersectionPoint = document.getElementById("doCircleIntersectionPoint");
    doCircleIntersectionPoint.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.removeCurrentInteraction();
      self.arcPointUI();
      try {
        self.map.removeInteraction(self.pinPoint);
        self.isDrawnEnabled = true;
      } catch (err) {}
    });
    // doCircleIntersectionPoint.append(doCircleIntersectionPointimg);

    // let doPointonLine = document.getElementById("doPointonLine");
    // doPointonLine.addEventListener("click", function (evt) {
    //     self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.INSERT_POINT_ON_LINE);
    //    // self.linePointUI();
    //     // try {
    //     //     self.map.removeInteraction(self.pinPoint);
    //     //     self.isDrawnEnabled = true;
    //     // } catch (err) {

    //     // }
    // });
    // doPointonLine.append(doPointonLineimg);

    let doLadderPoint = document.getElementById("doLadderPoint");
    doLadderPoint.addEventListener("click", function (evt) {
      self.createOffsetPointUI();
      try {
        self.map.removeInteraction(self.pinPoint);
        self.isDrawnEnabled = true;
      } catch (err) {}
    });
    // doLadderPoint.append(doLadderPointimg);

    let doAnglePoint = document.getElementById("doAnglePoint");
    doAnglePoint.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.removeCurrentInteraction();
      self.createAnglePoint();
      try {
        self.map.removeInteraction(self.pinPoint);
        self.isDrawnEnabled = true;
      } catch (err) {}
    });
    // doAnglePoint.append(doAnglePointimg);

    let doCreatePointBtn = document.getElementById("doCreatePointBtn");
    doCreatePointBtn.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACE_POINT);
    });
    // doCreatePointBtn.append(doCreatePointBtnimg);

    let doDeletePointBtn = document.getElementById("doDeletePointBtn");
    doDeletePointBtn.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      // self.deletePointfromMap();
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_POINT);
    });
    // doDeletePointBtn.append(doDeletePointBtnimg);

    let doDeleteSegmentBtn = document.getElementById("doDeleteSegmentBtn");
    doDeleteSegmentBtn.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_SEGMENT);
    });
    // doDeleteSegmentBtn.append(doDeleteSegmentBtnimg);

    let doDeleteLineBtn = document.getElementById("doDeleteLineBtn");
    doDeleteLineBtn.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_LINE);
    });

    let doDrawPolygonbtn = document.getElementById("doDrawPolygonBtn");
    doDrawPolygonbtn.addEventListener("click", function (evt) {
      // self.digitizer.clearInteractions();
      // self.drawLineTool();
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      if (!self.digitizer.getTouchMode()) {
        document.getElementById("infoPanel").style.display = "block";
        var actions =
          '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/clearDrawing.svg>";
        actions +=
          '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' +
          self.options.service_path +
          "assets/img/tickSuccess.svg>";
        document.getElementById("infoPanel").innerHTML = actions;
        document.getElementById("cancelCurrentDrawing").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.removeLastPointFromPolygon();
        });
        document.getElementById("finishCurrentDraw").addEventListener("click", function (evt) {
          self.digitizer.interactionManager.finishPolygonDrawing();
          document.getElementById("infoPanel").style.display = "none";
        });
      }
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DRAW_POLYGON);
    });

    let snapPointBtn = document.getElementById("doSnapPoint");
    snapPointBtn.addEventListener("click", function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT, { event_source: "SNAP_POINT" });
      //self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT,{event_source:"SNAP_POINT"});
    });

    let trimLineBtn = document.getElementById("doTrimLine");
    trimLineBtn.addEventListener("click", function (evt) {
      //self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT,{event_source: "SNAP_POINT"});
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT, { event_source: "TRIM_LINE" });
    });

    // let extentLineBtn = document.getElementById("doExtentLine");
    // extentLineBtn.addEventListener("click", function (evt) {
    //     //self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT,{event_source: "SNAP_POINT"});
    //     self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_SEGMENT,{event_source:"EXTENT_LINE"});

    // });

    // doDeleteLineBtn.append(doDeleteLineBtnimg);
    let doDeletePolygonBtn = document.getElementById("doDeletePolygonBtn");
    // doDeletePolygonBtn.append(doDeletePolygonimg);

    doDeletePolygonBtn.addEventListener("click", async function (evt) {
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.DELETE_POLYGON);
    });

    let doPolygonize = document.getElementById("doPolygonize");
    doPolygonize.addEventListener("click", function (evt) {
      let undoItemGroup = [];
      self.digitizer.polyGonizeLine(undoItemGroup);
      self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
    });
    // doPolygonize.append(doPolygonizeimg);

    let doMerge = document.getElementById("doMerge");
    doMerge.addEventListener("click", async function (evt) {
      self.digitizer.mergeSelected();
      self.digitizer.interactionManager.removeCurrentInteraction();
      self.digitizer.clearTempGeom();

      // self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.SELECT_CURRENT_LAYER_FEATURE, {layer_code:self.getSelectedLayerCode(),event_source: "MERGE_POLYGONS"});

      // document.getElementById('infoPanel').style.display = "block";
      // var actions = '<img id="cancelCurrentDrawing" class="cursorPointer marginleft-5" title="Cancel Drawing (Esc)" alt="" width="25" src=' + self.options.service_path + 'assets/img/clearDrawing.svg>';
      // actions += '<img id="finishCurrentDraw" class="cursorPointer marginleft-16" title="Finish Drawing (F2)" alt="" width="25" src=' + self.options.service_path + 'assets/img/tickSuccess.svg>';
      // document.getElementById('infoPanel').innerHTML = actions;
      // document.getElementById('cancelCurrentDrawing').addEventListener("click", function (evt) {
      //     self.digitizer.interactionManager.removeCurrentInteraction();
      //     self.digitizer.clearTempGeom();
      // });
      // document.getElementById('finishCurrentDraw').addEventListener("click", function (evt) {
      //     self.digitizer.mergeSelected();
      //     document.getElementById('infoPanel').style.display = "none";
      //     self.digitizer.interactionManager.removeCurrentInteraction();
      //     self.digitizer.clearTempGeom();
      // });
    });
    // doSplit.append(doSplitimg);

    let doInitGrid = document.getElementById("doInitGrid");
    doInitGrid.addEventListener("click", function (evt) {
      if (self.digitizer.interactionManager.getCurrentInteraction() !== CONSTANTS.INTERACTIONS.PLACE_GRID) {
        self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACE_GRID);
      } else {
        self.digitizer.removeGrid();
      }
    });
    // doInitGrid.append(doInitGridimg);

    let doRotateGridleft = document.getElementById("doRotateGridleft");
    doRotateGridleft.addEventListener("click", function (evt) {
      self.digitizer.rotateGridLeft();
    });
    // doRotateGridleft.append(doRotateGridleftimg);

    let doRotateGridright = document.getElementById("doRotateGridright");
    doRotateGridright.addEventListener("click", function (evt) {
      self.digitizer.rotateGridRight();
    });
    // doRotateGridright.append(doRotateGridrightimg);

    // let doremoveGrid = document.getElementById('doremoveGrid');
    // doremoveGrid.addEventListener("click", function (evt) {
    //     self.digitizer.removeGrid();

    // });
    // doremoveGrid.append(doremoveGridimg);

    let doCutSegment = document.getElementById("doCutSegment");
    doCutSegment.addEventListener("click", function (evt) {
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.CUT_SEGMENT);
    });
    // doCutSegment.append(doCutSegmentimg);

    let doCutSegment2 = document.getElementById("doCutSegment2");
    doCutSegment2.addEventListener("click", function (evt) {
      // try {
      //     self.map.removeInteraction(self.pinPoint);
      //     self.isDrawnEnabled = true;
      // } catch (err) {

      // }
      // self.cutSegment();

      self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.CUT_SEGMENT_UI);
    });

    // doCutSegment2.append(doCutSegmentimg);

    // let selectData = this.options.attributes;
    // let contentDiv = document.getElementById('locationinfodistrictClass');
    // let blockdiv = document.getElementById('locationinfoblockClass');
    // for (let i = 0; i < selectData.length; i++) {
    //     if (selectData[i].visible && !(selectData[i].editable) && !(selectData[i].text)) {
    //         var temdiv = document.createElement('div');
    //         temdiv.className = 'locationDetailscard';
    //         var childdiv1 = document.createElement('div');
    //         childdiv1.className = 'h6 small clr-hdg';
    //         childdiv1.innerHTML = selectData[i].label;
    //         var childdiv2 = document.createElement('div');
    //         childdiv2.className = 'h6 ';
    //         childdiv2.innerHTML = selectData[i].value;
    //         temdiv.appendChild(childdiv1);
    //         temdiv.appendChild(childdiv2);
    //         contentDiv.appendChild(temdiv);
    //     }
    // }
    // for (let i = 0; i < selectData.length; i++) {
    //     if (selectData[i].visible && selectData[i].text) {
    //         var temdiv = document.createElement('div');
    //         temdiv.className = 'blockinfoCard';
    //         var childdiv1 = document.createElement('div');
    //         childdiv1.className = 'h6 small text-white';
    //         childdiv1.innerHTML = selectData[i].label;

    //         var childdiv2 = document.createElement('div');
    //         childdiv2.className = 'h6 logoText text-white';
    //         childdiv2.innerHTML = selectData[i].value;
    //         temdiv.appendChild(childdiv1);
    //         temdiv.appendChild(childdiv2);
    //         blockdiv.appendChild(temdiv);
    //     }
    // }

    document.getElementById("open_file").addEventListener("click", function (evt) {
      self.digitizer.imageLayerManager.openMap2Popup();
    });

    document.getElementById("transparentDivision").addEventListener("click", function (evt) {
      try {
        document.getElementById("slide-in-data").classList.remove("show");
        document.getElementById("transparentDivision").style.display = "none";
      } catch (err) {}
    });
    if (!this.options.config.view_only) {
      // this.initbasepointPanel();
    } else {
      document.getElementById("controltoolbars").style.display = "none";
      document.getElementById("viewOnlymsg").style.display = "block";
      // document.getElementById("attributeHamberger").style.display = "none";
    }

    if (this.options.visible_tools) {
      // if(!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.SAVE in this.options.visible_tools)){
      //     document.getElementById("saveActionbtn").classList.add('DN');
      // }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.MEASUREMENT in this.options.visible_tools)) {
        document.getElementById("doMeasureLength").classList.add("DN");
        document.getElementById("doMeasureArea").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.GRID in this.options.visible_tools)) {
        document.getElementById("doInitGrid").classList.add("DN");
        document.getElementById("doRotateGridleft").classList.add("DN");
        document.getElementById("doRotateGridright").classList.add("DN");
        // document.getElementById("doremoveGrid").classList.add('DN');
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.BACKGROUND_IMAGE in this.options.visible_tools)) {
        document.getElementById("open_file").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.UNDO_REDO in this.options.visible_tools)) {
        document.getElementById("doUndoBtn").classList.add("DN");
        document.getElementById("doRedoBtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.IMPORT_ETS in this.options.visible_tools)) {
        document.getElementById("importETSdatabtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.PLACE_POINT in this.options.visible_tools)) {
        document.getElementById("doCreatePointBtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.ARC_POINT in this.options.visible_tools)) {
        document.getElementById("doCircleIntersectionPoint").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.OFFSET_POINT in this.options.visible_tools)) {
        document.getElementById("doLadderPoint").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.CUT_POINT in this.options.visible_tools)) {
        document.getElementById("doCutSegment").classList.add("DN");
        document.getElementById("doCutSegment2").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.ANGLE_POINT in this.options.visible_tools)) {
        document.getElementById("doAnglePoint").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.EDIT_POINT in this.options.visible_tools)) {
        document.getElementById("editPoint").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_POINT in this.options.visible_tools)) {
        document.getElementById("doDeletePointBtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DRAW_LINE in this.options.visible_tools)) {
        document.getElementById("doDrawLinebtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_SEGMENT in this.options.visible_tools)) {
        document.getElementById("doDeleteSegmentBtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_LINE in this.options.visible_tools)) {
        document.getElementById("doDeleteLineBtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DRAW_POLYGON in this.options.visible_tools)) {
        document.getElementById("doDrawPolygonBtn").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.POLYGONIZE_LINES in this.options.visible_tools)) {
        document.getElementById("doPolygonize").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.SELECT_POLYGON in this.options.visible_tools)) {
        document.getElementById("selectPolygons").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DELETE_POLYGON in this.options.visible_tools)) {
        document.getElementById("doDeletePolygonBtn").classList.add("DN");
      }

      // if(!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.DRAW_SPLIT_LINE in this.options.visible_tools)){
      //     document.getElementById("doDrawSplitLine").classList.add('DN');
      // }

      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.SPLIT_POLYGON in this.options.visible_tools)) {
        document.getElementById("doSplit").classList.add("DN");
        document.getElementById("doDrawSplitLine").classList.add("DN");
        document.getElementById("clearSplitLine").classList.add("DN");
        document.getElementById("drawLiveSplitLine").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.MERGE_POLYGONS in this.options.visible_tools)) {
        document.getElementById("doMerge").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.VIEW_ATTRIBUTES in this.options.visible_tools)) {
        document.getElementById("pickfrommap").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.ATTRIBUTES_LIST in this.options.visible_tools)) {
        document.getElementById("pickfromlist").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.PRINT_TOOLS in this.options.visible_tools)) {
        document.getElementById("export_pdf").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.SNAP_POINT in this.options.visible_tools)) {
        document.getElementById("doSnapPoint").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.TRIM_LINE in this.options.visible_tools)) {
        document.getElementById("doTrimLine").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.EXPORT_POINTS in this.options.visible_tools)) {
        document.getElementById("export_points").classList.add("DN");
      }
      if (!(CONSTANTS.DEFAULT_VISIBLE_TOOLS.AREA_DIVISION in this.options.visible_tools)) {
        document.getElementById("areaDivision").classList.add("DN");
      }
    }

    //TODO       /// this.preDrawData();  ////

    this.constructLayerInSideBar();
  }
  constructLayerInSideBar() {
    var self = this;
    let opLayers = self.options.output_layers;
    for (const la of opLayers) {
      if (la.base_map) {
        let imgName = self.options.service_path + "assets/img/openlayers.png";
        if (la.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BHUVAN) {
          imgName = self.options.service_path + "assets/img/bhuvan.png";
        } else if (la.map_type === CONSTANTS.REFERENCE_MAP_TYPE.BING) {
          imgName = self.options.service_path + "assets/img/bing.png";
        }
        let divOuter = document.createElement("div");
        divOuter.classList.add("cardcontent");
        let divInner = document.createElement("div");
        divInner.setAttribute("layerid", la.layer_id);
        divInner.classList.add("cardcontentImage");
        divInner.classList.add("cardContentBack");
        divInner.style = "background:url(" + imgName + ")";
        let inpElm = document.createElement("input");
        inpElm.type = "checkbox";
        inpElm.style = "margin-right: 4px;margin-top: 4px;float: right;";
        if (la.visible) {
          inpElm.setAttribute("checked", true);
        }
        divInner.appendChild(inpElm);
        divInner.addEventListener("click", function (evt) {
          let chkElm = this.getElementsByTagName("input")[0];
          let currentLayerId = this.getAttribute("layerid");
          //for self layer
          if (chkElm.checked) {
            this.classList.remove("border");
            this.classList.remove("border-primary");
            self.digitizer.toggleVisibleInLayer(currentLayerId, false);
          } else {
            this.classList.add("border");
            this.classList.add("border-primary");
            self.digitizer.toggleVisibleInLayer(currentLayerId, true);
          }
          chkElm.checked = !chkElm.checked;
          //for other layers
          let layerUis = this.parentElement.parentElement.childNodes;
          for (let lu of layerUis) {
            lu = lu.childNodes[0];
            let itdLayerId = lu.getAttribute("layerid");
            if (itdLayerId !== currentLayerId) {
              if (chkElm.checked) {
                self.digitizer.toggleVisibleInLayer(itdLayerId, false);
                lu.classList.remove("border");
                lu.classList.remove("border-primary");
                lu.getElementsByTagName("input")[0].checked = false;
              }
            }
          }
        });
        let spanElm = document.createElement("span");
        spanElm.classList.add("cardcontentLabel");
        spanElm.classList.add("h6");
        spanElm.classList.add("small");
        spanElm.innerHTML = la.name;
        divOuter.appendChild(divInner);
        divOuter.appendChild(spanElm);
        document.getElementById("bn-maplist").appendChild(divOuter);
      } else {
        if (!la.searchable) {
          let dest = document.getElementById("overlaytable").getElementsByTagName("tbody")[0];
          let trElm = document.createElement("tr");
          trElm.setAttribute("layerid", la.layer_id);
          trElm.setAttribute("style", "cursor:pointer");
          if (la.source_parms.visible) {
            trElm.innerHTML = "<td width='25px'><input type='checkbox' checked></td><td class='mapiconappend'>" + la.name + "</td>";
          } else {
            trElm.innerHTML = "<td width='25px'><input type='checkbox'></td><td class='mapiconappend'>" + la.name + "</td>";
          }
          dest.appendChild(trElm);
          trElm.addEventListener("click", function (evt) {
            let chkElm = this.getElementsByTagName("input")[0];
            let currentLayerId = this.getAttribute("layerid");
            //for self layer
            if (chkElm.checked) {
              self.digitizer.toggleVisibleInLayer(currentLayerId, false);
            } else {
              self.digitizer.toggleVisibleInLayer(currentLayerId, true);
            }
            chkElm.checked = !chkElm.checked;
          });
        }
      }
    }
  }
  toggleFullscreen() {
    var getIDV = this.targetDiv;
    let getIDVElDIV = document.getElementById(getIDV);
    let getIDVEl = document.getElementsByTagName("html")[0]; //making entire view port to full screen
    if (getIDVElDIV && getIDVElDIV.hasAttribute("style") && !getIDVElDIV.classList.contains("fullscreened")) {
      let rfs = getIDVEl.requestFullscreen || getIDVEl.webkitRequestFullScreen || getIDVEl.mozRequestFullScreen || getIDVEl.msRequestFullscreen;
      rfs.call(getIDVEl);
    }
    this.toggleclasses(getIDV, "fullscreened");
    this.digitizer.getMap().updateSize();
  }
  toggleViewmode() {
    if (!this.options.config.view_only) {
      this.options.config.view_only = !this.options.config.view_only;
      document.getElementById("controltoolbars").style.display = "none";
      document.getElementById("viewOnlymsg").style.display = "block";
      // document.getElementById("attributeHamberger").style.display = "none";
      if (document.body.contains(this.generatedDataPanel)) {
        this.generatedDataPanel.close();
        this.generatedDataPanel = null;
      }
      var sidePanel = document.getElementById("slide-in-data");
      var arraypanel = sidePanel.className;
      var classArr = arraypanel.split(/\s+/);
      var eleflag = classArr.includes("show");
      if (eleflag) {
        this.toggleclasses("slide-in-data", "show");
      }
    } else {
      this.options.config.view_only = !this.options.config.view_only;
      document.getElementById("controltoolbars").style.display = "block";
      document.getElementById("viewOnlymsg").style.display = "none";
      // document.getElementById("attributeHamberger").style.display = "block";
      this.initbasepointPanel();
    }
  }
  attributeDataShow() {
    this.toggleclasses("slide-in-data", "show");
    var ele = document.getElementById("slide-in-data");
    var tempList = ele.className;
    var classArr = tempList.split(/\s+/);
    var eleflag = classArr.includes("show");
  }
  layerShow() {
    this.toggleclasses("slide-in-dataRight", "show");
    var ele = document.getElementById("slide-in-dataRight");
    var tempList = ele.className;
    var classArr = tempList.split(/\s+/);
    var eleflag = classArr.includes("show");
  }
  initetsPanel() {
    var self = this;
    var width = window.innerWidth;
    var height = window.innerHeight;
    var importPanelHeight = height / 2;
    var importPanelWidth = width / 3;
    if (importPanelHeight > 550) {
      importPanelHeight = 550;
    } else {
      importPanelHeight = 430;
    }
    if (importPanelWidth > 400) {
      importPanelWidth = 400;
    } else {
      importPanelWidth = 350;
    }
    if (this.panelPoint && this.panelPoint !== null) {
      this.panelPoint.close();
      this.panelPoint = null;
    }
    this.panelPoint = jsPanel.create({
      //container: "#"+self.targetDiv,
      headerTitle: "Import Base Points / ETS Points",
      closeOnEscape: true,
      contentFetch: {
        resource: self.servicePathPanel + "_point.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          //console.log("cccccc " + self.servicePathPanel);
          self.toggleInputFormat();
          document.getElementById("importsavebtn").addEventListener("click", function (evt) {
            self.addETSImportedPoints();
          });
          document.getElementById("datatypeswitch").addEventListener("change", function (evt) {
            self.toggleInputFormat();
          });
          document.getElementById("addRowbtn").addEventListener("click", function (evt) {
            self.addRow();
          });
          document.getElementById("removerowBtn").addEventListener("click", function (elmt) {});
        },
      },
      position: "center",
      container: "div.map",
      theme: "dark ",
      contentSize: importPanelWidth + " " + importPanelHeight,
      borderRadius: ".5rem",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        if (self.panelPoint) {
          // self.panelPoint.close();
          self.panelPoint = null;
        }
      },
    });
  }
  // initGraticule() {
  //     var self = this;
  //     document.getElementById('map').addEventListener("click", function (e) {
  //         var mouseLoc = document.getElementById('mouse-position').textContent;
  //         var mouseLocList = mouseLoc.split(/, /);
  //         if (!self.isgraticuleshown) {
  //             self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.PLACCE_GRID);
  //             // self.digitizer.createGrid(Number(mouseLocList[0]), Number(mouseLocList[1]), self.gridSpaceing);
  //             // self.isgraticuleshown=true;
  //         }
  //     });
  // }

  openLayerpanel(evt) {
    var getID;
    if (!document.getElementById(evt)) {
      getID = evt.currentTarget.id;
    } else {
      getID = document.getElementById(evt).id;
    }

    if (getID === "pointdatabutton") {
      document.getElementById("grid").style.display = "block";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "none";
    } else if (getID === "linedatabutton") {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "block";
      document.getElementById("PolygonData").style.display = "none";
    } else {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "block";
    }
    var i, tabcontent, tablinks;
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    evt.currentTarget.className += " active";
  }

  initbasepointPanel() {
    var self = this;
    let vPh = window.innerHeight - 400;
    jsPanel.ziBase = 101;
    self.generatedDataPanel = jsPanel.create({
      headerTitle: "Imported ETS Points / Base Points",
      contentFetch: {
        resource: self.servicePathPanel + "_generateddata.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("rawptallchkbox").addEventListener("change", function () {
            self.doHighlight(undefined, "raw");
          });
          document.getElementById("rawptDeleteBtn").addEventListener("click", function () {
            self.bulkDelete("rawpoints");
          });
          //self.refreshetsPoints();
        },
      },
      position: "right-top -40 120",
      contentSize: "460 " + vPh,
      borderRadius: ".5rem",
      container: "div.map",
      theme: "dark",
      headerControls: {
        maximize: "remove",
        close: "remove",
        size: "xs",
      },
    });
  }

  showcustomAlert(msg, type) {
    let self = this;
    document.getElementById("infoPanel").style.display = "block";
    var txt;
    if (type === CONSTANTS.ALERTS.SUCCESS) {
      txt =
        '<img class="cursorPointer marginleft-5 copyToClipboard" title="Copy to Clipboard" alt="" width="15" src=' +
        self.options.service_path +
        "assets/img/tickSuccess.svg>";
    } else if (type === CONSTANTS.ALERTS.ERROR) {
      txt =
        '<img class="cursorPointer marginleft-5 copyToClipboard" title="Copy to Clipboard" alt="" width="15" src=' +
        self.options.service_path +
        "assets/img/tickError.svg>";
    } else {
      txt =
        '<img class="cursorPointer marginleft-5 copyToClipboard" title="Copy to Clipboard" alt="" width="15" src=' +
        self.options.service_path +
        "assets/img/tickWarning.svg>";
    }
    document.getElementById("infoPanel").innerHTML = msg + txt;
    setTimeout(function () {
      document.getElementById("infoPanel").innerHTML = "";
      document.getElementById("infoPanel").style.display = "none";
    }, 2000);
  }

  ///////////////////////////////

  refreshPointsInDataDisplay() {
    var self = this;
    this.grid.resetData(this.gridData.segment);
    this.grid.restore(this.gridData.segment);
    var seg = document.getElementById("grid").querySelectorAll(".datasegmentClass");
    seg.forEach(function (data, i) {
      data.addEventListener("click", function (evt) {
        var id = data.getAttribute("references");
        self.predeleteSegment(id);
      });
    });
  }
  getEditableAttributes(attr) {
    var data = [];
    for (var i = 0; i < attr.length; i++) {
      if (attr[i].visible && attr[i].editable) {
        data.push(attr[i]);
      }
    }
    return data;
  }
  getDataPopulation(polygons, field, i) {
    if (!i) {
      var subData = [];
      for (var a = 0; a < polygons.length; a++) {
        subData.push(polygons[a][field]);
      }
      var uniq = [...new Set(subData)];
      return uniq;
    } else {
    }
  }
  constructAttributeOptions() {
    var finalAttr = {};
    var polyid = "";
    if (this.initialData.output.prefilled_data.polygons && this.initialData.output.prefilled_data.polygons.length > 0) {
      var attr = this.getEditableAttributes(this.initialData.attributes);
      for (var j = 0; j < attr.length; j++) {
        var ele = document.createElement("select");
        ele.id = polyid;
        ele.references = polyid;
        ele.className = "polygonDataclass";
        var tabContent = "";
        var subData = this.getDataPopulation(this.initialData.output.prefilled_data.polygons, attr[j].field);
        for (var g = 0; g < subData.length; g++) {
          tabContent += '<option value="' + subData[g] + '" >' + subData[g] + "</option>";
        }
        ele.innerHTML = tabContent;
        finalAttr[attr[j].field] = ele;
      }
    } else {
      var attr = this.getEditableAttributes(this.initialData.attributes);
      var outputAttr = this.initialData.output.attributes;
      for (var i = 0; i < attr.length; i++) {
        var ele = document.createElement("select");
        ele.id = polyid;
        ele.references = polyid;
        ele.className = "polygonDataclass";
        var optnsHtml = "";
        for (var j = 0; j < outputAttr.length; j++) {
          optnsHtml += '<option value="' + outputAttr[j][attr[i].field] + '">' + outputAttr[j][attr[i].field] + "</option>";
        }
        ele.innerHTML = optnsHtml;
        finalAttr[attr[i].field] = ele;
      }
    }
    return finalAttr;
  }
  // refreshPolygonsInDataDisplay(isInit) {
  //     var self = this;
  //     var griddivpoly = document.getElementById('PolygonData');
  //     griddivpoly.innerHTML = "";
  //     var tabContentHead = '<table class="table table-head-fixed text-nowrap"> <thead><tr>';
  //     tabContentHead += '<th class="rowwidth"><input type="checkbox" id="allparcelchkbox"> </th><th>Name</th><th>Area</th><th>Action</th>';
  //     var attr = this.getEditableAttributes(this.initialData.attributes);
  //     var polygons = this.generatedData.polygons;
  //     var constructedOPts = this.constructAttributeOptions();
  //     for (var i = 0; i < attr.length; i++) {
  //         tabContentHead += '<th>' + attr[i].label + '</th>';
  //     }
  //     tabContentHead += '</tr></thead><tbody id="parceldataTab"></tbody></table>';

  //     griddivpoly.innerHTML = tabContentHead;
  //     if (this.initialData.config.attributes_display === "combo") {
  //         for (var i = 0; i < polygons.length; i++) {
  //             var tabContent = '';
  //             tabContent += '<td><input type="checkbox" class="parceldatacheckbox" id="' + polygons[i].id + '" curresponds="' + polygons[i].id + '"></td>';
  //             tabContent += '<td>' + polygons[i].name + '</td>';
  //             tabContent += '<td>' + polygons[i].area + '</td>';
  //             tabContent += '<td><img class="polygonDeletebtn cursorPointer" width="15" src=' + Gis.ServicePath + 'assets/img/trash.svg references="' + polygons[i].id + '" ></td>';

  //             for (var j = 0; j < attr.length; j++) {
  //                 var dom = constructedOPts[attr[j].field];
  //                 dom.setAttribute("id", polygons[i].id + attr[j].field);
  //                 dom.setAttribute("references", polygons[i].id);
  //                 if (isInit && this.initialData.output.prefilled_data.polygons && this.initialData.output.prefilled_data.polygons.length > 0 && this.initialData.output.prefilled_data.polygons[i][attr[j].field]) {//prefill data is available
  //                     for (var q = 0; q < dom.options.length; q++) {
  //                         if (dom.options[q].innerText === polygons[i][attr[j].field]) {
  //                             dom.options[q].setAttribute("selected", "selected");
  //                         }
  //                     }
  //                 }

  //                 tabContent += '<td>' + dom.outerHTML + '</td>';
  //             }
  //             var tabRow = document.createElement('tr');
  //             tabRow.innerHTML = tabContent;
  //             document.getElementById('parceldataTab').appendChild(tabRow);
  //         }
  //     } else if (this.initialData.config.attributes_display === "text") {
  //         for (var i = 0; i < polygons.length; i++) {
  //             var tabContent = '';
  //             tabContent += '<td><input type="checkbox" class="parceldatacheckbox" id="' + polygons[i].id + '" curresponds="' + polygons[i].id + '"></td>';
  //             tabContent += '<td>' + polygons[i].name + '</td>';
  //             tabContent += '<td>' + polygons[i].area + '</td>';
  //             tabContent += '<td><img class="polygonDeletebtn cursorPointer" width="15" src=' + Gis.ServicePath + 'assets/img/trash.svg references="' + polygons[i].id + '" ></td>';
  //             for (var j = 0; j < attr.length; j++) {
  //                 var name = "";
  //                 name = polygons[i][attr[j].field];
  //                 tabContent += '<td> <input class="polygonDataclass" type="text" value="' + name + '" references="' + polygons[i].id + '" id="' + polygons[i].id + attr[j].field + '" ></td>';
  //             }
  //             var tabRow = document.createElement('tr');
  //             tabRow.innerHTML = tabContent;
  //             document.getElementById('parceldataTab').appendChild(tabRow);
  //         }
  //     }
  //     var parcelData = document.getElementById('PolygonData');
  //     parcelData.querySelectorAll('.parceldatacheckbox').forEach(function (data, i) {
  //         data.addEventListener("change", function (evt) {
  //             var id = data.getAttribute("id");
  //             self.doHighlight(id, 'polygons', data.checked);
  //         });
  //     });
  //     parcelData.querySelectorAll('.polygonDataclass').forEach(function (data, i) {
  //         data.addEventListener("change", function (evt) {
  //             var id = data.getAttribute('references');
  //             var attr = self.initialData.attributes;
  //             var feat = self.GetFeature(Gis.LAYERTYPE.POLYGON, Gis.LAYERGETTYPE.ID, id);
  //             var oldname;
  //             for (var y = 0; y < self.generatedData.polygons.length; y++) {
  //                 if (self.generatedData.polygons[y].id === id) {
  //                     oldname = self.generatedData.polygons[y].name;
  //                 }
  //             }
  //             oldname += "( ";
  //             var first = 0;
  //             for (var t = 0; t < attr.length; t++) {
  //                 if (attr[t].visible && attr[t].editable) {
  //                     var getData = document.getElementById(id + attr[t].field).value;
  //                     for (var y = 0; y < self.generatedData.polygons.length; y++) {
  //                         if (self.generatedData.polygons[y].id === id) {
  //                             self.generatedData.polygons[y][attr[t].field] = getData;
  //                         }
  //                     }
  //                     if (first === 0) {
  //                         oldname += getData;
  //                         first = first + 1;
  //                     } else {
  //                         oldname += " / " + getData;
  //                     }
  //                 }
  //             }
  //             oldname += " )";
  //             feat[0].set("name", oldname);
  //         });
  //     });
  //     document.getElementById('allparcelchkbox').addEventListener("change", function (evt) {
  //         self.doHighlight(undefined, 'polygons', document.getElementById('allparcelchkbox').checked);
  //     });
  //     parcelData.querySelectorAll('.polygonDeletebtn').forEach(function (data, i) {
  //         data.addEventListener("click", function (evt) {
  //             var id = data.getAttribute("references");
  //             self.predeletePolygon(id, false, false, true);
  //         });
  //     });

  // }
  // refreshLinesInDataDisplay() {
  //     var self = this;
  //     this.linegrid.resetData(this.gridData.lines);
  //     this.linegrid.restore(this.gridData.lines);
  //     var lns = document.getElementById('linesData').querySelectorAll('.datalineClass');
  //     lns.forEach(function (data, i) {
  //         data.addEventListener("click", function (evt) {
  //             var id = data.getAttribute("references");
  //             self.preDeleteLine(id);
  //         });
  //     });
  // }
  openLayerpanel(evt) {
    var cityName = "";
    var getID;
    if (!document.getElementById(evt)) {
      getID = evt.currentTarget.id;
    } else {
      getID = document.getElementById(evt).id;
    }

    if (getID === "pointdatabutton") {
      document.getElementById("grid").style.display = "block";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "none";
    } else if (getID === "linedatabutton") {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "block";
      document.getElementById("PolygonData").style.display = "none";
    } else {
      document.getElementById("grid").style.display = "none";
      document.getElementById("linesData").style.display = "none";
      document.getElementById("PolygonData").style.display = "block";
    }
    var i, tabcontent, tablinks;
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    evt.currentTarget.className += " active";
  }

  getSelectedLayerCode() {
    let selectBox = document.getElementById("dropdownListType");
    let index = document.getElementById("dropdownListType").selectedIndex;
    let selectedLayerCode = selectBox.options[index].value;
    return selectedLayerCode;
  }

  generatefromData() {
    this.clearInteractions();
    this.isDrawnEnabled = true;
    let index = document.getElementById("dropdownListType").selectedIndex;
    let selectBox = document.getElementById("dropdownListType");
    let selectedIndexName = selectBox.options[index].getAttribute("name");
    if (selectedIndexName === "Points" || selectedIndexName === "Lines" || selectedIndexName === "Polygon") {
      this.initpickPanel(selectedIndexName === "polygons");
    } else {
      alert("Caught an error or mismatch type");
    }
  }

  clearSelectedMenu() {
    this.grid.resetData(this.generatedData.points);
    this.linegrid.resetData(this.generatedData.lines);
    this.polygrid.resetData(this.generatedData.polygons);
    this.clearInteractions();
  }

  toggleInputFormat(elem) {
    var self = this;
    var tabHtml =
      "<table class='table' id='inppttab'><tr><td ><input value='' type='text' class='form-control fullWidth' name='namer1' placeholder='Name'></td><td ><input value='' type='text' class='form-control fullWidth' name='p1r1' placeholder='P1'>";
    tabHtml +=
      "</td><td><input type='text' class='form-control fullWidth' value='' name='p2r1' placeholder='P2'></td><td id='removerowBtn' onclick='Gis.removeRow(this)'><img alt='' width='15' title='Delete' class='cursorPointer margintop-6' src='assets/img/trash.svg'></td></tr><tr><td colspan='3'  id='addrow'></td></tr>";
    tabHtml += "</table>";
    //console.log('Entered to toggle');
    let inpFormat;
    if (elem === undefined) {
      if (document.getElementById("datatypeswitch")) {
        inpFormat = document.getElementById("datatypeswitch").checked;
      } else {
        inpFormat = false;
      }
    } else {
      inpFormat = document.getElementById("datatypeswitch").checked;
    }
    if (document.getElementById("addRowbtn")) {
      if (inpFormat) {
        document.getElementById("addRowbtn").style.display = "none";
      } else {
        document.getElementById("addRowbtn").style.display = "inline-block";
      }
    }
    //document.getElementById('inppttab').value.length
    if (!inpFormat) {
      this.isControlInCSV = false;
      if (!document.getElementById("inppttab")) {
        if (document.getElementById("tab")) {
          document.getElementById("tab").innerHTML = tabHtml;
        }
      }
      if (document.getElementById("tab")) {
        document.getElementById("tab").classList.remove("DN");
      }
      if (document.getElementById("area")) {
        document.getElementById("area").classList.add("DN");
      }
    } else {
      this.isControlInCSV = true;
      if (!document.getElementById("inpptarea")) {
        var inpAreaHtml =
          '<h6>Upload a CSV formatted file or Directly enter the input.</h6><div class="flexDisplay fullWidth"><input type="file" class="form-control" id="fileUpload" data-browse-on-zone-click="true"/></div><br>';
        inpAreaHtml += '<div><textarea id="inpptarea" placeholder="name1,pt1,pt2,pt3&#13;&#10;name2,pt1,pt2,pt3"></textarea></div>';
        document.getElementById("area").innerHTML = inpAreaHtml;
      }
      document.getElementById("area").classList.remove("DN");
      document.getElementById("tab").classList.add("DN");
      document.getElementById("fileUpload").addEventListener("change", function (evt) {
        self.importData();
      });
    }
  }
  importData() {
    var fileUpload = document.getElementById("fileUpload");
    var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      if (typeof FileReader !== "undefined") {
        var reader = new FileReader();
        reader.onload = function (e) {
          document.getElementById("inpptarea").value = e.target.result;
        };
        reader.readAsText(fileUpload.files[0]);
      } else {
        alert("This browser does not support HTML5.");
      }
    } else {
      alert("Please upload a valid CSV file.");
    }
  }

  addETSImportedPoints() {
    var datapresent = false;

    if (this.isControlInCSV) {
      if (document.getElementById("inpptarea").value.length > 0) {
        var csv_val = document.getElementById("inpptarea").value.split("\n");
        let pointsData = [];
        for (var i = 0; i < csv_val.length; i++) {
          let inpData = csv_val[i].split("   ")[0].split(",");
          //console.log(inpData);
          //csv validation
          let csvDatLen = inpData.length;
          if (csvDatLen === 1) {
            continue; //empty line, no issues
          } else if (csvDatLen <= 2) {
            alert("Invalid entry present at row number - " + (i + 1));
          }
          //let pt = new Point([inpData[1] + " " + inpData[2]]);
          let wktPt = `POINT(${inpData[1]} ${inpData[2]})`; //this._wktFormat.writeGeometry(pt);

          let pointData = {
            id: this.digitizer.createID(),
            name: inpData[0],
            geom: wktPt,
          };
          pointsData.push(pointData);

          // let id = this.createPoint(inpData[0], wktPt, Gis.POINTTYPE.CREATED);
          // undoOp.push(id.id);
          // redoOp.push({ "name": inpData[0], "geom": wktPt, "type": Gis.POINTTYPE.CREATED, "id": id.id });
          // this.map.getView().fit(this.vectorPoints.getSource().getExtent(), this.map.getSize());
          datapresent = true;
        }
        if (datapresent) {
          this.digitizer.addPointObjectArray(pointsData, []);
        }
      } else {
        alert("Please choose any CSV file");
      }
    } else {
      document
        .getElementById("inppttab")
        .querySelectorAll("tr")
        .forEach(function (i, el) {
          if (el < document.getElementById("inppttab").querySelectorAll("tr").length - 1) {
            var tds = i.getElementsByTagName("td");
            var name = tds[0].querySelector("input[name='namer1']").value;
            var pt1 = tds[1].querySelector("input[name='p1r1']").value;
            var pt2 = tds[2].querySelector("input[name='p2r1']").value;
            //let pt = new Point([pt1 + " " + pt2]);
            let wktPt = String.raw`POINT(${pt1} ${pt2})`; //this._wktFormat.writeGeometry(pt);
            let pointData = {
              id: this.digitizer.createID(),
              name: name,
              geom: wktPt,
            };
            this.digitizer.addPointObject(pointData, []);
            // let id = this.createPoint(name, wktPt, Gis.POINTTYPE.CREATED);
            // undoOp.push(id.id);
            // redoOp.push({ "name": name, "geom": wktPt, "type": Gis.POINTTYPE.CREATED, "id": id.id });
          }
        });
      datapresent = true;
    }
    // if (datapresent) {
    //     this.preCreatePoint(false, undoOp, redoOp);
    //     this.map.getView().fit(this.vectorPoints.getSource().getExtent(), this.map.getSize());
    // }
  }

  arcPointUI() {
    var self = this;

    if (self.panelArcPoint) {
      self.panelArcPoint.close((id) => {
        self.panelArcPoint = null;
      });
    }
    self.panelArcPoint = jsPanel.create({
      headerTitle: "Arc Point",
      contentFetch: {
        resource: self.servicePathPanel + "_arcpoint.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("arcPanelClose").addEventListener("click", function (evt) {
            self.panelArcPoint.close();
            self.panelArcPoint = null;
          });

          var ptCombo = document.getElementById("arcPointPanel").querySelectorAll(".point-combo");
          ptCombo.forEach(function (data, i) {
            for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
              let nm = self.digitizer.generatedData.points[i]["name"];
              let idx = i;
              var pts = document.createElement("option");
              pts.value = idx;
              pts.innerHTML = nm;
              data.appendChild(pts);
            }
          });

          var acrPt = document.getElementById("arcPointPanel").querySelectorAll(".arcpoint_input");
          acrPt.forEach(function (data, i) {
            data.addEventListener("change", function (evt) {
              self.digitizer.showArcPoint("arcPointPanel", data.id);
            });
          });

          // $(".arcpoint_input").each(function () {
          //     $(this).change(function () {

          //         self.showArcPoint(this.id);
          //     });
          // });
          document.getElementById("add_arc_point").addEventListener("click", function () {
            let idx1 = document.getElementById("combo_first").value;
            let idx2 = document.getElementById("combo_second").value;
            if (idx1 !== idx2) {
              self.digitizer.addPointsFromTemp("A");
            }
            self.panelArcPoint.close();
            self.panelArcPoint = null;
          });
        },
      },
      theme: "dark",
      borderRadius: ".5rem",
      contentSize: "510 220",
      resizeit: false,
      container: "div.map",
      position: "left-bottom 5 -50",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.digitizer.clearTempGeom();
        self.panelArcPoint = null;
      },
    });
  }

  constructAndOpenEditPointPanel(dataArg) {
    var self = this;
    if (self.editPointPanel) {
      //not required
    } else {
      self.editPointPanel = jsPanel.create({
        closeOnEscape: true,
        headerTitle: "Edit Point",
        footerToolbar: '<button id="doneEditing" class="btn btn-primary cardAction-btn">OK</button>',
        contentFetch: {
          resource: self.servicePathPanel + "_edit_point.html?v=" + self.digitizer.version(),
          done: function (response, panel) {
            panel.contentRemove();
            panel.content.append(jsPanel.strToHtml(response));

            //on change of textbox
            document.getElementById("editingCanvasRow").addEventListener("change", function (evt) {
              if (evt.target.name === "editPointName") {
                let elm = document.getElementById("editPointIntersectionPanel");
                if (!elm.classList.contains("DN")) {
                  elm.classList.add("DN");
                }
                let x = document.getElementById("editPointX");
                let y = document.getElementById("editPointY");
                let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
                let retObj = {
                  id: id,
                  coord: [Number(x.value), Number(y.value)],
                  old_coord: [Number(x.getAttribute("old")), Number(y.getAttribute("old"))],
                };
                self.digitizer.changePointCoordinateTemp(retObj);
              }
            });

            document.getElementById("editPointFreehand").addEventListener("click", function (evt) {
              let elm = document.getElementById("editPointIntersectionPanel");
              if (!elm.classList.contains("DN")) {
                elm.classList.add("DN");
              }
              let x = document.getElementById("editPointX");
              let y = document.getElementById("editPointY");

              self.digitizer.interactionManager.enableInteraction(CONSTANTS.INTERACTIONS.EDIT_POINT_FREEHAND, {
                x: Number(x.value),
                y: Number(y.value),
                onModify: function (newX, newY) {
                  let xEl = document.getElementById("editPointX");
                  let yEl = document.getElementById("editPointY");
                  xEl.value = newX;
                  yEl.value = newY;
                  //   let retObj = { id: "idd", coord: [newX, newY], old_coord: [Number(x.getAttribute("old")), Number(y.getAttribute("old"))] };
                  self.digitizer.changePointCoordinateTemp([Number(x.getAttribute("old")), Number(y.getAttribute("old"))], [newX, newY]);
                },
              });
            });
            document.getElementById("editPointIntersection").addEventListener("click", function (evt) {
              let elm = document.getElementById("editPointIntersectionPanel");
              if (elm.classList.contains("DN")) {
                elm.classList.remove("DN");
              }

              ///////////////////////////////
              let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
              // let ftClone = self.getFeatureByGeomType(Gis.POINTTYPE, Gis.LAYERGETTYPE.ID, id)[0];
              // let ftOriginal = ftClone.clone();

              //  self.tempLayer.getSource().addFeature(ftClone);

              var ptCombo = document.getElementById("editPointIntersectionPanel").querySelectorAll(".point-combo");
              ptCombo.forEach(function (data, i) {
                for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
                  let nm = self.digitizer.generatedData.points[i]["name"];
                  let idx = i;
                  var pts = document.createElement("option");
                  pts.value = idx;
                  pts.innerHTML = nm;
                  data.appendChild(pts);
                }
              });

              var acrPt = document.getElementById("editPointIntersectionPanel").querySelectorAll(".arcpoint_input");
              acrPt.forEach(function (data, i) {
                data.addEventListener("change", function (evt) {
                  self.digitizer.showArcPoint("editPointPanel", data.id, function (coordinate) {
                    let x = document.getElementById("editPointX");
                    let y = document.getElementById("editPointY");
                    // let retObj = { id: ftOriginal.get("id"), coord: coordinate, old_coord: ftOriginal.getGeometry().getCoordinates() };
                    self.digitizer.changePointCoordinateTemp([Number(x.getAttribute("old")), Number(y.getAttribute("old"))], coordinate);
                    x.value = coordinate[0];
                    y.value = coordinate[1];
                  });
                });
              });

              // $(".arcpoint_input").each(function () {
              //     $(this).change(function () {

              //         self.showArcPoint(this.id);
              //     });
              // });
              // document.getElementById('editPointIntersectionPanel').querySelector('.add_arc_point').addEventListener("click", function () {

              //     let idx1 =  document.getElementById('editPointIntersectionPanel').querySelectorAll('point-combo')[0].value;
              //     let idx2 =  document.getElementById('editPointIntersectionPanel').querySelectorAll('point-combo')[1].value;
              //     if (idx1 !== idx2) {
              //         let features = self.tempLayer.getSource().getFeatures();
              //         for (var i = 0; i < features.length; i++) {
              //             if (features[i].get("category") === "A") {

              //                 let ptName = features[i].get("name");
              //                 let wktPt = self._wktFormat.writeGeometry(features[i].getGeometry());
              //                 let pointData = { "name": ptName, "geom": wktPt, "type": Gis.POINTTYPE.CREATED, "idx": i };
              //                 self.preCreatePoint(pointData);
              //                 self.clearTempGeom();
              //                 break;
              //             }
              //         }
              //     }
              //     self.panelArcPoint.close();
              //     self.panelArcPoint = null;
              // });
              ////////////////////////////////

              // let x = document.getElementById("editPointX");
              // let y = document.getElementById("editPointY");
              // let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
              // let retObj = { id: id, coord: [Number(x.value), Number(y.value)], old_coord: [Number(x.getAttribute("old")), Number(y.getAttribute("old"))] };
              // self.changePointCoordinateTemp(retObj);
            });

            //ok button action
            document.getElementById("doneEditing").addEventListener("click", function (evt) {
              let x = document.getElementById("editPointX");
              let y = document.getElementById("editPointY");
              let id = document.getElementById("editingCanvasRow").getAttribute("editPoint_id");
              let undoItemGroup = [];
              self.digitizer.changePointCoordinate(
                [Number(x.getAttribute("old")), Number(y.getAttribute("old"))],
                [Number(x.value), Number(y.value)],
                undoItemGroup
              );
              self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);
              self.editPointPanel.close();
            });

            let data = self.digitizer.getDataById("points", dataArg.id);

            document.getElementById("editPointName").value = data.data.name;
            let x = document.getElementById("editPointX");
            x.setAttribute("old", dataArg.coordinates[0]);
            x.value = dataArg.coordinates[0];
            let y = document.getElementById("editPointY");
            y.value = dataArg.coordinates[1];
            y.setAttribute("old", dataArg.coordinates[1]);
            document.getElementById("editingCanvasRow").setAttribute("editPoint_id", dataArg.id);
          },
        },

        theme: "dark ",
        container: "div.map",
        borderRadius: ".5rem",
        position: "center-top",
        contentSize: {
          width: () => window.innerWidth * 0.8,
          height: "50vh",
        },
        headerControls: { size: "xs" }, // shorthand
        onclosed: function (panel, closedByUser) {
          self.digitizer.interactionManager.removeCurrentInteraction();
          self.digitizer.clearTempGeom();
          self.editPointPanel = null;
          this.editPointPanel = null;
        },
      });
    }
  }

  lineOnPointUI(featureId, coordinates) {
    var self = this;
    if (self.panelPointOnLine && self.panelPointOnLine !== null) {
      self.panelPointOnLine.close((id) => {
        self.panelPointOnLine = null;
      });
    }
    let pointsData = self.digitizer.generatePointsData(coordinates);
    document.getElementById("infoPanel").style.display = "block";
    document.getElementById("infoPanel").innerHTML = "Select a line";
    // var selection = await self.pickDataFromMap(Gis.LAYERTYPE.LINES);
    // if (selection) {
    document.getElementById("infoPanel").innerHTML = "";
    document.getElementById("infoPanel").style.display = "none";
    self.panelPointOnLine = jsPanel.create({
      headerTitle: "Insert Point",
      contentFetch: {
        resource: self.servicePathPanel + "_pointonline.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          // for (var ty = 0; ty < self.generatedData.lines.length; ty++) {
          //     var polyOption = document.createElement('option');
          //     polyOption.value = self.generatedData.lines[ty].id;
          //     polyOption.innerHTML = self.generatedData.lines[ty].name;
          //     polyOption.name = self.generatedData.lines[ty].name;
          //     if (self.generatedData.lines[ty].name === selection.name) {
          //         polyOption.selected = true;
          //     }
          //     document.getElementById('combo_polygons').appendChild(polyOption);
          // }
          var pointCombos = document.getElementById("pointonLineDiv").querySelectorAll(".point-combo1");

          var interSections = document.getElementById("combo_start_pt");
          while (interSections.firstChild) {
            interSections.removeChild(interSections.firstChild);
          }

          for (var i = 0; i < pointsData.length; i++) {
            var nm = pointsData[i]["name"];
            var x = pointsData[i]["coordinate"][0];
            var y = pointsData[i]["coordinate"][0];
            var opt = document.createElement("option");
            opt.value = i;
            opt.setAttribute("x", x);
            opt.setAttribute("y", y);
            opt.innerHTML = nm;
            pointCombos[0].appendChild(opt);
          }
          // self.labelRadioTowards('label_rb_1', 'label_rb_2', data.value, index);

          // var li = 0;

          // document.getElementById('combo_polygons').addEventListener("change", function (event) {
          //     var selname = document.getElementById('combo_polygons').options[document.getElementById('combo_polygons').selectedIndex].text;
          //     self.generatPointsData(this.value, selname);
          // });
          document.getElementById("linePointClose").addEventListener("click", function (evt) {
            self.panelPointOnLine.close();
            self.panelPointOnLine = null;
          });
          document.getElementById("add_point").addEventListener("click", function (evt) {
            var index;
            var polyId = document.getElementById("combo_polygons").options[document.getElementById("combo_polygons").selectedIndex].value;
            var polyName = document.getElementById("combo_polygons").options[document.getElementById("combo_polygons").selectedIndex].text;
            for (var i = 0; i < self.generatedData.lines.length; i++) {
              if (self.generatedData.lines[i].id === polyId && self.generatedData.lines[i].name === polyName) {
                index = i;
              }
            }
            let li = 0;
            let idx = Number(document.getElementById("combo_start_pt").value);
            let forward = true;
            var tempCombos = document.getElementsByName("rb_start");
            var isforward = document.getElementById("comCheck");
            if (isforward.checked) {
              forward = false;
            }
            let d = 0;
            if (!isNaN(document.getElementById("number_length1").value)) {
              d = Number(document.getElementById("number_length1").value) * self.options.scaleFactor;
            }
            let cord = self.boundaryPointAtDistance(index, idx, d, forward, true);
            var desiredSegment;
            if (cord.availablePoints.length === 2) {
              for (var t = 0; t < self.generatedData.linesegments.length; t++) {
                if (
                  self.generatedData.linesegments[t].parentNodes.includes(cord.availablePoints[0].id) &&
                  self.generatedData.linesegments[t].parentNodes.includes(cord.availablePoints[1].id) &&
                  self.generatedData.linesegments[t].parentid === selection.id
                ) {
                  desiredSegment = self.generatedData.linesegments[t];
                }
              }
            }
            if (cord !== null) {
              if (confirm("Do you want to perform cut segment?")) {
                let ptName = document.getElementById("pt_name").value;
                self.cutSegmentwithPoint(desiredSegment, cord.availablePoints, ptName, cord.distance);
                self.clearTempGeom();
              } else {
                let ptName = document.getElementById("pt_name").value;
                let pt = new Point(cord.coordinate);
                let wktPt = self._wktFormat.writeGeometry(pt);

                // let snapped = self.snapPointToBoundary(self._jstsReader.read(wktPt));
                // wktPt = snapped.toText();

                let pointData = {
                  name: ptName,
                  geom: wktPt,
                  type: Gis.POINTTYPE.CREATED,
                  idx: 1,
                };
                self.preCreatePoint(pointData);
                self.clearTempGeom();
              }
            }
            li++;
            self.panelPointOnLine.close();
            self.panelPointOnLine = null;
          });
        },
      },
      position: "left-bottom 5 -50",
      contentSize: "280 175",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.map",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.panelPointOnLine = null;
        self.clearTempGeom();
      },
    });
    // }
  }

  cutSegment(startCoord, endCoord, midCoord) {
    var self = this;
    if (self.cutSegmentUI && self.cutSegmentUI !== null) {
      self.cutSegmentUI.close((id) => {
        self.cutSegmentUI = null;
      });
    }
    document.getElementById("infoPanel").style.display = "block";
    document.getElementById("infoPanel").innerHTML = "Select a Segment";
    // var selection = await self.pickDataFromMap(Gis.LAYERTYPE.SEGMENT);
    // if (selection) {
    document.getElementById("infoPanel").innerHTML = "";
    document.getElementById("infoPanel").style.display = "none";
    self.cutSegmentUI = jsPanel.create({
      headerTitle: "Cut Segment",
      contentFetch: {
        resource: self.servicePathPanel + "_cutSegment.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          let availablePoints = self.digitizer.generatePointsData([startCoord, endCoord]);
          // for (var i = 0; i < self.generatedData.points.length; i++) {
          //     for (var j = 0; j < selection.parentNodes.length; j++) {
          //         if (selection.parentNodes[j] === self.generatedData.points[i].id) {
          //             availablePoints.push(self.generatedData.points[i]);
          //         }
          //     }
          // }
          if (availablePoints.length === 2) {
            document.getElementById("label_rb_1").innerHTML = availablePoints[0].name;
            document.getElementById("rb_1").setAttribute("X", startCoord[0]);
            document.getElementById("rb_1").setAttribute("Y", startCoord[1]);

            document.getElementById("label_rb_2").innerHTML = availablePoints[1].name;
            document.getElementById("rb_2").setAttribute("X", endCoord[0]);
            document.getElementById("rb_2").setAttribute("Y", endCoord[1]);

            document.getElementById("cutpoint_distance").value = "" + coordinateDistance(startCoord, midCoord).toFixed(2);
          }

          document.getElementById("rb_1").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          document.getElementById("rb_2").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          document.getElementById("cutpt_name").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          //document.getElementById('choosedLineName').innerHTML = selection.name;
          document.getElementById("cutpoint_distance").addEventListener("change", function (evt) {
            self.showCutPoint();
          });

          document.getElementById("cutPointClose").addEventListener("click", function (evt) {
            self.cutSegmentUI.close();
            self.digitizer.clearTempGeom();
          });
          document.getElementById("add_cutpoint").addEventListener("click", function (evt) {
            var midCoord = self.showCutPoint();
            var undoItemGroup = [];
            self.digitizer.insertPointInSegment(startCoord, endCoord, midCoord, undoItemGroup);
            self.digitizer.undoRedoManager.pushToUndoStack(undoItemGroup);

            self.cutSegmentUI.close();
            self.digitizer.clearTempGeom();
          });
          self.showCutPoint();
        },
      },
      position: "left-bottom 5 -50",
      contentSize: "280 150",
      borderRadius: ".5rem",
      theme: "dark",
      container: "div.map",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.cutSegmentUI = null;
        self.digitizer.clearTempGeom();
      },
    });
    // }
  }

  createAnglePoint() {
    var self = this;
    if (self.anglePointUI && self.anglePointUI !== null) {
      self.anglePointUI.close();
      self.anglePointUI = null;
    }
    self.anglePointUI = jsPanel.create({
      headerTitle: "Create angle Point",
      closeOnEscape: true,
      contentFetch: {
        resource: self.servicePathPanel + "_angle_point.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));
          document.getElementById("angleUIClose").addEventListener("click", function (evt) {
            self.anglePointUI.close();
            self.anglePointUI = null;
            self.digitizer.clearTempGeom();
          });
          let combo1 = document.getElementById("angle_combo_start_pt");
          let combo2 = document.getElementById("angle_combo_end_pt");

          for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
            let nm = self.digitizer.generatedData.points[i]["name"];
            let id = self.digitizer.generatedData.points[i]["id"];
            var pOptions1 = document.createElement("option");
            pOptions1.value = id;
            pOptions1.name = nm;
            pOptions1.innerHTML = nm;
            combo1.appendChild(pOptions1);

            var pOptions2 = document.createElement("option");
            pOptions2.value = id;
            pOptions2.name = nm;
            pOptions2.innerHTML = nm;
            combo2.appendChild(pOptions2);
          }

          var virtualPoints = document.getElementById("anlePointUI").querySelectorAll(".point-combo");
          virtualPoints.forEach(function (data, i) {
            data.addEventListener("change", function (evt) {
              self.anglePointCreateVirtualLine();
            });
          });
          document.getElementById("pointDistance").addEventListener("change", function (evt) {
            self.genAnglePoint();
          });
          document.getElementById("pointAngle").addEventListener("change", function (evt) {
            self.genAnglePoint();
          });
          document.getElementById("angleUISave").addEventListener("click", function (evt) {
            self.genAnglePoint(true);
            self.digitizer.addPointsFromTemp("PT");
            self.digitizer.clearTempGeom();
            self.anglePointUI.close();
            self.anglePointUI = null;
          });
          document.getElementById("angptDistincrease").addEventListener("click", function (evt) {
            document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) + 0.5;
            self.genAnglePoint();
          });
          document.getElementById("angptDistdecrease").addEventListener("click", function (evt) {
            if (Number(document.getElementById("pointDistance").value) > 0) {
              document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) - 0.5;
              self.genAnglePoint();
            }
          });
          document.getElementById("angptAngleIncrease").addEventListener("click", function (evt) {
            if (Number(document.getElementById("pointAngle").value) < 360) {
              document.getElementById("pointAngle").value = Number(document.getElementById("pointAngle").value) + 0.5;
              self.genAnglePoint();
            }
          });
          document.getElementById("angptAngleDecrease").addEventListener("click", function (evt) {
            if (Number(document.getElementById("pointAngle").value) > 0) {
              document.getElementById("pointAngle").value = Number(document.getElementById("pointAngle").value) - 0.5;
              self.genAnglePoint();
            }
          });
        },
      },
      position: "left-bottom 5 -50",
      theme: "dark ",
      contentSize: "290 180",
      borderRadius: ".5rem",
      container: "div.map",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.anglePointUI = null;
        self.digitizer.clearTempGeom();
      },
    });
  }

  genAnglePoint(ptDistance, ptAngleDegree, pt1ID, pt2ID) {
    var ptDistance = Number(document.getElementById("pointDistance").value) * this.options.scaleFactor;
    var ptAngleDegree = Number(document.getElementById("pointAngle").value);

    var pt1ID = document.getElementById("angle_combo_start_pt").options[document.getElementById("angle_combo_start_pt").selectedIndex].value;
    var pt2ID = document.getElementById("angle_combo_end_pt").options[document.getElementById("angle_combo_end_pt").selectedIndex].value;

    this.anglePointCreateVirtualLine();

    this.digitizer.createAnglePoint(ptDistance, ptAngleDegree, pt1ID, pt2ID);
  }

  createOffsetPointUI() {
    var self = this;
    if (self.ladderPointUI && self.ladderPointUI !== null) {
      self.ladderPointUI.close();
      self.ladderPointUI = null;
    }
    self.ladderPointUI = jsPanel.create({
      headerTitle: "Create offset Point",
      closeOnEscape: true,
      contentFetch: {
        resource: self.servicePathPanel + "_ladder_table.html?v=" + self.digitizer.version(),
        done: function (response, panel) {
          panel.contentRemove();
          panel.content.append(jsPanel.strToHtml(response));

          let combo1 = document.getElementById("ladder_combo_start_pt");
          let combo2 = document.getElementById("ladder_combo_end_pt");

          for (var i = 0; i < self.digitizer.generatedData.points.length; i++) {
            let nm = self.digitizer.generatedData.points[i]["name"];
            let id = self.digitizer.generatedData.points[i]["id"];
            var pOptions1 = document.createElement("option");
            pOptions1.value = id;
            pOptions1.name = nm;
            pOptions1.innerHTML = nm;
            combo1.appendChild(pOptions1);

            var pOptions2 = document.createElement("option");
            pOptions2.value = id;
            pOptions2.name = nm;
            pOptions2.innerHTML = nm;
            combo2.appendChild(pOptions2);
          }

          document.getElementById("LadderClose").addEventListener("click", function () {
            self.digitizer.clearTempGeom();
            self.ladderPointUI.close();
            self.ladderPointUI = null;
          });
          document.getElementById("ladder_combo_start_pt").addEventListener("change", function (evt) {
            //self.offsetPointCreateVirtualLine();
            self.genOffsetTempPoint();
          });
          document.getElementById("ladder_combo_end_pt").addEventListener("change", function (evt) {
            //self.offsetPointCreateVirtualLine();
            self.genOffsetTempPoint();
          });
          document.getElementById("ladderDistance").addEventListener("change", function (evt) {
            self.genOffsetTempPoint();
          });
          document.getElementById("pointDistance").addEventListener("change", function (evt) {
            self.genOffsetTempPoint();
          });
          document.getElementById("offsetPointDirection").addEventListener("change", function (evt) {
            self.genOffsetTempPoint();
          });
          document.getElementById("LadderSave").addEventListener("click", function (evt) {
            self.genOffsetTempPoint();
            self.digitizer.addPointsFromTemp("PT");
            self.digitizer.clearTempGeom();
            self.ladderPointUI.close();
            self.ladderPointUI = null;
          });
          document.getElementById("ldDistincrease").addEventListener("click", function (evt) {
            document.getElementById("ladderDistance").value = Number(document.getElementById("ladderDistance").value) + 0.5;
            self.genOffsetTempPoint();
          });
          document.getElementById("ldDistdecrease").addEventListener("click", function (evt) {
            if (document.getElementById("ladderDistance").value > 0) {
              document.getElementById("ladderDistance").value = Number(document.getElementById("ladderDistance").value) - 0.5;
              self.genOffsetTempPoint();
            }
          });
          document.getElementById("ptDistanceDecrease").addEventListener("click", function (evt) {
            document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) + 0.5;
            self.genOffsetTempPoint();
          });
          document.getElementById("ptDistanceIncrease").addEventListener("click", function (evt) {
            if (document.getElementById("pointDistance").value > 0) {
              document.getElementById("pointDistance").value = Number(document.getElementById("pointDistance").value) - 0.5;
              self.genOffsetTempPoint();
            }
          });
        },
      },
      position: "left-bottom 5 -50",
      theme: "dark ",
      contentSize: "290 230",
      borderRadius: ".5rem",
      container: "div.map",
      headerControls: { size: "xs" }, // shorthand
      onclosed: function (panel, closedByUser) {
        self.ladderPointUI = null;
        self.digitizer.clearTempGeom();
      },
    });
  }

  anglePointCreateVirtualLine() {
    var self = this;
    var pt1ID = document.getElementById("angle_combo_start_pt").options[document.getElementById("angle_combo_start_pt").selectedIndex].value;
    var pt1Name = document.getElementById("angle_combo_start_pt").options[document.getElementById("angle_combo_start_pt").selectedIndex].text;
    var pt2ID = document.getElementById("angle_combo_end_pt").options[document.getElementById("angle_combo_end_pt").selectedIndex].value;
    var pt2Name = document.getElementById("angle_combo_end_pt").options[document.getElementById("angle_combo_end_pt").selectedIndex].text;
    self.digitizer.clearTempGeom();
    self.digitizer.createTempLine(pt1ID, pt2ID, "L");
  }

  offsetPointCreateVirtualLine() {
    var self = this;
    var pt1ID = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].value;
    var pt1Name = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].text;
    var pt2ID = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].value;
    var pt2Name = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].text;
    self.digitizer.clearTempGeom();
    self.digitizer.createTempLine(pt1ID, pt2ID, "L");
  }

  genOffsetTempPoint(generate) {
    var pt1ID = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].value;
    var pt1y = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].y;
    var pt1Name = document.getElementById("ladder_combo_start_pt").options[document.getElementById("ladder_combo_start_pt").selectedIndex].text;
    var pt2ID = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].value;
    var pt2y = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].y;
    var pt2Name = document.getElementById("ladder_combo_end_pt").options[document.getElementById("ladder_combo_end_pt").selectedIndex].text;

    var direction = document.getElementById("offsetPointDirection").options[document.getElementById("offsetPointDirection").selectedIndex].value;
    var ladderDistance = Number(document.getElementById("ladderDistance").value) * this.options.scaleFactor;
    var offsetDistance = Number(document.getElementById("pointDistance").value) * this.options.scaleFactor;
    let ptName = document.getElementById("offsetPointDistance").value;

    this.digitizer.clearTempGeom();
    this.offsetPointCreateVirtualLine();

    this.digitizer.createOffsetPoint(pt1ID, pt2ID, ladderDistance, offsetDistance, ptName, direction);
  }

  showCutPoint() {
    let self = this;
    var ptName = document.getElementById("cutpt_name").value;
    var ptDist = Number(document.getElementById("cutpoint_distance").value);
    var startCoord = [Number(document.getElementById("rb_1").getAttribute("X")), Number(document.getElementById("rb_1").getAttribute("Y"))];

    var endCoord = [Number(document.getElementById("rb_2").getAttribute("X")), Number(document.getElementById("rb_2").getAttribute("Y"))];
    var midCoord = self.digitizer.cutPointAtDistance(startCoord, endCoord, ptDist, document.getElementById("rb_1").checked);
    self.digitizer.showCutPoint(startCoord, endCoord, midCoord, ptName);
    return midCoord;
  }

  addActionButton(icon, text, style, clickFunction) {
    let template = document.getElementById("action_template");
    let bt = template.cloneNode(true);
    bt.removeAttribute("id");
    if (text) {
      bt.setAttribute("title", text);
    }
    if (icon) {
      if (icon.startsWith("data:")) {
        bt.firstElementChild.setAttribute("src", icon);
      } else {
        bt.firstElementChild.setAttribute("src", this.options.service_path + "assets/" + icon);
      }

      if (style) {
        //style will be allowed only for img element
        bt.firstElementChild.setAttribute("style", style);
      }
    }

    bt.classList.remove("DN");
    if (clickFunction) {
      bt.addEventListener("click", function (evt) {
        clickFunction();
      });
    }

    document.getElementById("controltoolbars").append(bt);
  }

  addControlButton(icon, text, style, clickFunction) {
    // let template = document.getElementById('action_template');
    const bt = document.createElement("button");
    const img = document.createElement("img");
    bt.appendChild(img);

    // bt.removeAttribute("id");
    if (text) {
      bt.setAttribute("title", text);
    }

    if (icon) {
      if (icon.startsWith("data:")) {
        bt.firstElementChild.setAttribute("src", icon);
      } else {
        bt.firstElementChild.setAttribute("src", this.options.service_path + "assets/" + icon);
      }

      if (style) {
        //style will be allowed only for img element
        bt.firstElementChild.setAttribute("style", style);
      }
    }
    bt.classList.add("icon");

    if (clickFunction) {
      bt.addEventListener("click", function (evt) {
        clickFunction();
      });
    }
    document.getElementById("_extra_control_buttons").append(bt);
  }

  showInfoPanel(header, content, options1 = {}) {
    var self = this;
    if (self.infoPanel && self.infoPanel !== null) {
      self.infoPanel.close();
    }

    let options = {
      headerTitle: "Map Info",
      content: '<div id="plot_info_content"></div>',
      position: "left-bottom 5 50",
      contentSize: "300 410",
      container: "div.map",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#ffff",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },

      onclosed: function (panel, closedByUser) {
        self.infoPanel = null;
      },
      // bgFooter:"#181c32",
      footerToolbar: "<span>&nbsp;</span>",
      headerControls: {
        maximize: "remove",
        size: "xs",
      },
      panelSize: {
        width: () => {
          if (window.screen.width < 500) {
            return "95%";
          } else if (window.screen.width < 800) {
            return "90%";
          } else {
            return 370;
          }
        },
        height: () => {
          if (window.screen.width < 500) {
            return "30vh";
          } else if (window.screen.width < 800) {
            return "35vh";
          } else {
            return 600;
          }
        },
      },
    };

    options = Object.assign(options, options1, {
      headerTitle: header,
      content: content,
    });
    console.log(options);
    self.infoPanel = jsPanel.create(options);
  }

  showPointsForExport() {
    var self = this;

    if (self.exportPointsPanel && self.exportPointsPanel !== null) {
      self.exportPointsPanel.close();
    }

    let data = [];
    for (let i = 0; i < self.digitizer.generatedData.points.length; i++) {
      let geom = self.digitizer._wktFormat.readGeometry(self.digitizer.generatedData.points[i].geom);

      data.push({
        name: self.digitizer.generatedData.points[i].name,
        x: geom.getCoordinates()[0],
        y: geom.getCoordinates()[1],
        z: 0,
      });
    }

    let options = {
      headerTitle: "Map Info",
      content: '<div id="_export_point_grid" class="_export_point_grid" style="height:400"></div>',
      position: "left-center 5 5",
      contentSize: "360 410",
      container: "div.map",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#ffff",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },

      onclosed: function (panel, closedByUser) {
        self.exportPointsPanel = null;
      },
      // bgFooter:"#181c32",
      footerToolbar: ['<span class="jsPanel-ftr-btn export" style="cursor:pointer" title="Export CSV"><i class="fg fg-multipoint fg-lg  fg-white"></i></span>'],
      headerControls: {
        maximize: "remove",
        size: "xs",
      },
      callback: (panel) => {
        const grid = new Grid({
          usageStatistics: false,
          el: panel.content.getElementsByClassName("_export_point_grid")[0],
          data: data,
          // rowHeaders: ['rowNum', 'checkbox'],
          scrollX: false,
          scrollY: false,
          columns: [
            {
              header: "Name",
              name: "name",
            },
            {
              header: "X",
              name: "x",
            },
            {
              header: "Y",
              name: "y",
            },
            {
              header: "Z",
              name: "z",
            },
          ],
        });

        // handler for the icons
        for (const btn of panel.footer.querySelectorAll("span")) {
          btn.addEventListener("click", (e) => {
            let cl = e.target.closest("span").classList;
            if (cl[cl.length - 1] === "export") {
              grid.export("csv");
            }
          });
        }
      },
      panelSize: {
        width: () => {
          if (window.screen.width < 500) {
            return "95%";
          } else if (window.screen.width < 800) {
            return "90%";
          } else {
            return 370;
          }
        },
        height: () => {
          if (window.screen.width < 500) {
            return "30vh";
          } else if (window.screen.width < 800) {
            return "35vh";
          } else {
            return 600;
          }
        },
      },
    };

    self.exportPointsPanel = jsPanel.create(options);
  }

  showFeatureProperties(layerId, data) {
    if (data.length < 1) {
      return;
    }

    const self = this;

    let layerName = self.digitizer.findLayerById(layerId).get("name");
    let headerTitle = "Map Attributes - " + layerName;

    if (self.featurePropertiesPanel && self.featurePropertiesPanel !== null) {
      self.featurePropertiesPanel.close();
    }

    let columns = [];
    let grid = null;
    Object.keys(data[0]).forEach((key) => {
      if (key !== "__id") {
        columns.push({
          header: titleCase(key),
          name: key,
          hidden: false,
        });
      } else {
        columns.push({
          header: titleCase(key),
          name: key,
          hidden: true,
        });
      }
    });

    let options = {
      headerTitle: headerTitle,
      content: '<div id="_feature_properties_panel" class="_feature_properties_panel" style="height:400"></div>',
      position: "left-center 5 5",
      contentSize: "360 670",
      container: "div.map",
      minimizeTo: "parent",
      syncMargins: true,
      dragit: { snap: true },
      // theme: "#181c32 filled",
      theme: {
        bgContent: "#ffff",
        bgFooter: "#000",
        bgPanel: "#000000",
        colorHeader: "#FFF",
      },

      onclosed: function (panel, closedByUser) {
        self.featurePropertiesPanel = null;
      },
      // bgFooter:"#181c32",
      footerToolbar: ['<span class="jsPanel-ftr-btn export" style="cursor:pointer" title="Export CSV"><i class="fg fg-multipoint fg-lg  fg-white"></i></span>'],
      headerControls: {
        maximize: "remove",
        size: "xs",
      },
      resizeit: {
        stop: () => {
          grid.refreshLayout();
          let parentDiv = document.getElementsByClassName("_feature_properties_panel")[0].parentNode.parentNode;
          let parentHeightInPixels = parentDiv.getBoundingClientRect().height - 50;
          grid.setBodyHeight(parentHeightInPixels);
        },
      },
      callback: (panel) => {
        grid = new Grid({
          usageStatistics: false,
          el: panel.content.getElementsByClassName("_feature_properties_panel")[0],
          data: data,
          rowHeaders: ["rowNum"],
          // pageOptions: {
          //     useClient: true,
          //     perPage: 5
          //  },
          // rowHeaders: ['rowNum', 'checkbox'],
          scrollX: true,
          scrollY: true,
          columns: columns,
        });

        let parentDiv = document.getElementsByClassName("_feature_properties_panel")[0].parentNode.parentNode;
        let parentHeightInPixels = parentDiv.getBoundingClientRect().height - 50;
        grid.setBodyHeight(parentHeightInPixels);

        // handler for the icons
        for (const btn of panel.footer.querySelectorAll("span")) {
          btn.addEventListener("click", (e) => {
            let cl = e.target.closest("span").classList;
            if (cl[cl.length - 1] === "export") {
              grid.export("csv");
            }
          });
        }
        //grid.hideColumn("geometry");
        grid.on("focusChange", (evt) => {
          let row = grid.getRow(evt.rowKey);
          self.digitizer.focusToLayerFeature(layerId, row.__id);
          // self.digitizer.getMap().getView().fit(row.geometry, {padding: [170, 50, 30, 150]})
        });
      },
      panelSize: {
        width: () => {
          if (window.screen.width < 500) {
            return "95%";
          } else if (window.screen.width < 800) {
            return "90%";
          } else {
            return 670;
          }
        },
        height: () => {
          if (window.screen.width < 500) {
            return "30vh";
          } else if (window.screen.width < 800) {
            return "35vh";
          } else {
            return 600;
          }
        },
      },
    };

    self.featurePropertiesPanel = jsPanel.create(options);
  }

  createJsPanel() {
    return jsPanel;
  }
  createGrid(options) {
    return new Grid(options);
  }
}

export { UIBuilder };
