import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import {Control, defaults as defaultControls} from 'ol/control';

class BhuNakshaLogo extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {className:'', handler: void(0)};

    const label1 = document.createElement('LABEL');
    label1.innerHTML = 'BHU';
    label1.className ="h5 logo-left logoText";
    const label2 = document.createElement('LABEL');
    label2.innerHTML = 'NAKSHA';
    label2.className = "h5 logo-right  logoText";
    const element = document.createElement('div');
    element.className = 'ol-unselectable ol-control bhunaksha-logo-center ' +options.className;
    element.appendChild(label1);
    element.appendChild(label2);

    super({
      element: element,
      target: options.target,
    });

  
  }

}

export {BhuNakshaLogo};