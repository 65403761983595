import WKB from "ol/format/WKB";
import { get as ol_proj_get } from "ol/proj";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";

class GPKGReader {
  constructor(digitizer) {
    this.digitizer = digitizer;
    this.options = digitizer.options;
  }

  /**
   * Process OGC GeoPackage (SQLite database) once loaded
   * @param {*} loadedGpkgFile - name of GeoPackage file (for diagnostics only)
   * @param {ArrayBuffer} gpkgArrayBuffer - ArrayBuffer containing Gpkg data read
   * @param {WebAssembly} sqlWasm - sql.js SQLITE database access library
   * @param {string} displayProjection - map display projection (e.g. EPSG:3857)
   * @returns {object[]} array of 2 objects: [<data tables>, <slds>]
   *   <data tables>: OpenLayers vector sources, indexed by table name
   *   <slds>: SLD XML strings, indexed by layer name
   */
  processGpkgData(gpkgArrayBuffer, sqlWasm) {
    var db;

    // Data and associated SLD styles loaded both from GPKG
    var dataFromGpkg = {};
    var sldsFromGpkg = {};

    // DEBUG: measure GPKG processing time
    //var startProcessing = Date.now();

    // Convert Array Buffer to Byte Array for SQLite
    var gpkgByteArray = new Uint8Array(gpkgArrayBuffer);

    try {
      db = new sqlWasm.Database(gpkgByteArray);

      // Extract all feature tables, SRS IDs and their geometry types
      // Note the following fields are not extracted:
      //   gpkg_contents.identifier - title (QGIS: same as table_name)
      //   gpkg_contents.description - human readable (QGIS: blank)
      //   gpkg_geometry_columns.geometry_type_name
      //     - e.g. LINESTRING (but info also embedded in each feature)
      var featureTableNames = [];
      var stmt;
      stmt = db.prepare(`
              SELECT gpkg_contents.table_name, gpkg_contents.srs_id,
                  gpkg_geometry_columns.column_name, gpkg_geometry_columns.geometry_type_name
              FROM gpkg_contents JOIN gpkg_geometry_columns
              WHERE gpkg_contents.data_type='features' AND
                  gpkg_contents.table_name=gpkg_geometry_columns.table_name;
          `);
      while (stmt.step()) {
        let row = stmt.get();
        featureTableNames.push({
          table_name: row[0],
          srs_id: row[1].toString(),
          geometry_column_name: row[2],
          geometry_type: row[3],
        });
      }
    } catch (err) {
      throw new Error('Unable to extract feature tables from OGC GeoPackage file "' + '":\n' + err);
    }

    // Extract SLD styles for each layer (if styles included in the gpkg)
    stmt = db.prepare(`
          SELECT gpkg_contents.table_name
          FROM gpkg_contents
          WHERE gpkg_contents.table_name='layer_styles'
      `);
    if (stmt.step()) {
      stmt = db.prepare("SELECT f_table_name,styleSLD FROM layer_styles");
      while (stmt.step()) {
        let row = stmt.get();
        sldsFromGpkg[row[0]] = row[1];
      }
    }

    // db.run("CREATE TABLE test ( \"fid\" INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL, geom BLOB, district TEXT(50))");
    // var stmt1 = db.prepare("INSERT INTO  test  (geom,district) VALUES (?,?)");

    // For each table, extract geometry and other properties
    // (Note: becomes OpenLayers-specific from here)
    var formatWKB = new WKB();
    for (let table of featureTableNames) {
      let features;
      let table_name = table.table_name;
      let tableDataProjection = "EPSG:" + table.srs_id;
      let layerMaster = {
        layer_name: table_name,
        layer_code: table_name,
        geom_type: table.geometry_type,
        sld_style: sldsFromGpkg[table_name],
        user_layer: true,
      };
      // Check if we have a definition for the data projection (SRS)
      if (!ol_proj_get(tableDataProjection)) {
        throw new Error("Missing data projection [" + tableDataProjection + '] for table "' + table_name + '" - can be added beforehand with ol/proj/proj4');
      }

      let attrs = [];
      let pk = null;
      stmt = db.prepare('pragma table_info("' + table_name + '")');
      while (stmt.step()) {
        let row = stmt.get();
        if (row[1] === table.geometry_column_name) continue;
        let attr = {
          key: row[1],
          name: row[1],
          name_en: row[1],
          mandatory: row[3] === 1 ? true : false,
          type: row[1] === table.geometry_column_name ? table.geometry_type : this.decodeType(row[2]),
        };
        attrs.push(attr);
        if (row[5] === 1) {
          pk = row[1];
        }
      }
      layerMaster.attrs = attrs;

      stmt = db.prepare("SELECT * FROM '" + table_name + "'");
      let vectorSource = new VectorSource();
      let geometry_column_name = table.geometry_column_name;
      let properties = {};
      while (stmt.step()) {
        // Extract properties & geometry for a single feature
        properties = stmt.getAsObject();
        let geomProp = properties[geometry_column_name];
        delete properties[geometry_column_name];
        // console.log(geomProp);
        let featureWkb = this.parseGpkgGeom(geomProp);

        //  console.log(this.toHexString(featureWkb));

        /*
              // DEBUG: show endianness of WKB data (can differ from header)
              if (!vectorSource.getFeatures().length) {
                  console.log('WKB Geometry: ' +
                      (featureWkb[0] ? 'NDR (Little' : 'XDR (Big') + ' Endian)');
              }
  */

        // Put the feature into the vector source for the current table
        features = formatWKB.readFeatures(featureWkb, {
          dataProjection: tableDataProjection,
          featureProjection: this.digitizer.options.output_proj,
        });

        // let extent = features[0].getGeometry().getExtent();
        // let gpkgKb = createGeoPkgHeader(4326, extent[0], extent[2], extent[1], extent[3]);
        // console.log(gpkgKb);

        // var mergedArray = new Uint8Array(gpkgKb.length + featureWkb.length);
        // mergedArray.set(gpkgKb);
        // mergedArray.set(featureWkb, gpkgKb.length);

        // stmt1.run([mergedArray,"123"]);

        // console.log(gpkgKb);
        // console.log("-------------");
        features[0].setProperties(properties);
        // if (pk) {
        //   features[0].setId("" + properties[pk]);
        // }
        features[0].setId(this.digitizer.createID());
        vectorSource.addFeatures(features);
      }

      this.digitizer.appendLayerMaster([layerMaster]);
      this.digitizer.addLayer({
        layer_id: layerMaster.layer_name,
        name: layerMaster.layer_name,
        map_type: "VECTOR_DATA",
        base_map: false,
        focus: true,
        source_parms: {
          vector_source: vectorSource,
        },
        sld_style: layerMaster.sld_style,
      });

      // For information only, save details of  original projection (SRS)
      vectorSource.setProperties({ origProjection: tableDataProjection, pk: pk });
      dataFromGpkg[table_name] = new GeoJSON().writeFeatures(vectorSource.getFeatures());
      dataFromGpkg[table_name + "_proj"] = new GeoJSON().writeFeatures(vectorSource.getFeatures());
    }
  }

  /**
   * Extract (SRS ID &) WKB from an OGC GeoPackage feature
   * (i.e. strip off the variable length header)
   * @param {object} gpkgBinGeom feature geometry property (includes header)
   * @returns feature geometry in WKB (Well Known Binary) format
   */
  parseGpkgGeom(gpkgBinGeom) {
    var flags = gpkgBinGeom[3];
    var eFlags = (flags >> 1) & 7;
    var envelopeSize;
    switch (eFlags) {
      case 0:
        envelopeSize = 0;
        break;
      case 1:
        envelopeSize = 32;
        break;
      case 2:
      case 3:
        envelopeSize = 48;
        break;
      case 4:
        envelopeSize = 64;
        break;
      default:
        throw new Error("Invalid geometry envelope size flag in GeoPackage");
    }
    /*
      // Extract SRS (EPSG code)
      // (not required as given for whole table in gpkg_contents table)
      var littleEndian = flags & 1;
      var srs = gpkgBinGeom.subarray(4,8);
      var srsId;
      if (littleEndian) {
          srsId = srs[0] + (srs[1]<<8) + (srs[2]<<16) + (srs[3]<<24);
      } else {
          srsId = srs[3] + (srs[2]<<8) + (srs[1]<<16) + (srs[0]<<24);
      }
  */
    /*
      // DEBUG: display other properties of the feature
      console.log('gpkgBinGeom Header: ' + (littleEndian ? 'Little' : 'Big')
          + ' Endian');
      console.log("gpkgBinGeom Magic: 0x${gpkgBinGeom[0].toString(16)}${gpkgBinGeom[1].toString(16)}");
      console.log("gpkgBinGeom Version:", gpkgBinGeom[2]);
      console.log("gpkgBinGeom Flags:", flags);
      console.log("gpkgBinGeom srs_id:", srsId);
      console.log("gpkgBinGeom envelope size (bytes):", envelopeSize);
  */

    // let dbl = new Uint8Array(8);
    // dbl[0] = gpkgBinGeom[8];
    // dbl[1] = gpkgBinGeom[9];
    // dbl[2] = gpkgBinGeom[10];
    // dbl[3] = gpkgBinGeom[11];
    // dbl[4] = gpkgBinGeom[12];
    // dbl[5] = gpkgBinGeom[13];
    // dbl[6] = gpkgBinGeom[14];
    // dbl[7] = gpkgBinGeom[15];
    // let view = new DataView(dbl.buffer);
    // console.log(view.getFloat64(0,true));

    // dbl = new Uint8Array(8);
    // dbl[0] = gpkgBinGeom[16];
    // dbl[1] = gpkgBinGeom[17];
    // dbl[2] = gpkgBinGeom[18];
    // dbl[3] = gpkgBinGeom[19];
    // dbl[4] = gpkgBinGeom[20];
    // dbl[5] = gpkgBinGeom[21];
    // dbl[6] = gpkgBinGeom[22];
    // dbl[7] = gpkgBinGeom[23];
    //  view = new DataView(dbl.buffer);
    // console.log(view.getFloat64(0,true));

    // dbl = new Uint8Array(8);
    // dbl[0] = gpkgBinGeom[24];
    // dbl[1] = gpkgBinGeom[25];
    // dbl[2] = gpkgBinGeom[26];
    // dbl[3] = gpkgBinGeom[27];
    // dbl[4] = gpkgBinGeom[28];
    // dbl[5] = gpkgBinGeom[29];
    // dbl[6] = gpkgBinGeom[30];
    // dbl[7] = gpkgBinGeom[31];
    //  view = new DataView(dbl.buffer);
    // console.log(view.getFloat64(0,true));

    // dbl = new Uint8Array(8);
    // dbl[0] = gpkgBinGeom[32];
    // dbl[1] = gpkgBinGeom[33];
    // dbl[2] = gpkgBinGeom[34];
    // dbl[3] = gpkgBinGeom[35];
    // dbl[4] = gpkgBinGeom[36];
    // dbl[5] = gpkgBinGeom[37];
    // dbl[6] = gpkgBinGeom[38];
    // dbl[7] = gpkgBinGeom[39];
    //  view = new DataView(dbl.buffer);
    // console.log(view.getFloat64(0,true));

    // Extract WKB which starts after variable-size "envelope" field
    var wkbOffset = envelopeSize + 8;
    // console.log(gpkgBinGeom.subarray(0, wkbOffset));
    return gpkgBinGeom.subarray(wkbOffset);
  }

  decodeType(type) {
    type = type.toLowerCase();
    if (type.startsWith("text")) {
      return "String";
    } else if (type.startsWith("integer")) {
      return "Int";
    } else {
      return "String";
    }
  }
  toHexString(byteArray) {
    return Array.prototype.map
      .call(byteArray, function (byte) {
        return ("0" + (byte & 0xff).toString(16)).slice(-2);
      })
      .join("");
  }
}

export { GPKGReader };
